import React, { useEffect } from 'react';
import { Calendar, Users, Cigarette, Beer } from 'lucide-react';
import { useAppContexts } from '../../AppContext';
import OverPage from '../../Components/OverPage';
import ProfileDetailsId from './../ProfileDetailsId';

const tutorialSteps = [
	{
		selector: ".tabs-filter",
		title: "Usuarios que te gustan",
		description: "Selecciona que quieres ver.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: false, scrollTo: false, color: null },
		],
	},
	{
		findItem: 1,
		selector: ".liked-list",
		title: "Usuarios que te gustan",
		description: "Aquí puedes ver las personas que les diste a like.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
		],
	},
	{
		findItem: 1,
		selector: ".liked-list",
		title: "Usuarios que te gustan",
		description: "Nombre del usuario.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
			{ type: "highlight", x: 100, y: 100, w: 50, h: 30, selector: ".name", color: "rgba(255, 0, 0, 0.5)" }
		],
	},
	{
		findItem: 1,
		selector: ".liked-list",
		title: "Usuarios que te gustan",
		description: "Edad del usuario.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
			{ type: "highlight", x: 100, y: 100, w: 50, h: 30, selector: ".age", color: "rgba(255, 0, 0, 0.5)" }
		],
	},
	{
		findItem: 1,
		selector: ".liked-list",
		title: "Usuarios que te gustan",
		description: "Características del usuario.\n1. Tiene hijos.\n2. Fuma.\n3. Bebe.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
			{ type: "highlight", x: 100, y: 100, w: 50, h: 30, selector: ".user-info", color: "rgba(255, 0, 0, 0.5)" }
		],
	},
];

const ProfileCard = ({ profile, onClick }) => {
	const children = profile.has_children;
	const smoking = profile.smoking && profile.smoking !== "No fumador";
	const drinking = profile.drinking && profile.drinking !== "No bebe";

	return (
		<div onClick={onClick} className="bg-white bg-opacity-20 backdrop-blur-lg rounded-xl shadow-lg p-4 flex flex-col">
			<div className="relative mb-4">
				<img src={profile.image} alt={profile.name} loading="lazy" className="w-full h-48 object-cover rounded-lg" />
				<div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-2">
					<h2 className="text-xl font-bold text-white">
						<span className="name">{profile.name}</span>, <span className="age">{profile.age}</span>
					</h2>
				</div>
			</div>

			<div className="user-info flex justify-between items-center mt-auto">
				<div className="flex items-center text-gray-200">
					<Calendar className="w-4 h-4 mr-2" />
					{profile.age}
				</div>
				<div className="flex space-x-2">
					<Users className={`w-4 h-4 ${children ? 'text-green-400' : 'text-gray-400'}`} />
					<Cigarette className={`w-4 h-4 ${smoking ? 'text-green-400' : 'text-gray-400'}`} />
					<Beer className={`w-4 h-4 ${drinking ? 'text-green-400' : 'text-gray-400'}`} />
				</div>
			</div>
		</div>
	);
};

const LikesListPage = ({liked, profiles, activeTab, setActiveTab, showData, setShowData, showProfile, setShowProfile, showFilter, setShowFilter}) => {
	const { trans, setTutorial } = useAppContexts();

	useEffect(() => {
		setTutorial(tutorialSteps);
		return () => setTutorial(null)
	}, [setTutorial]);

	const tabs = [
		{ id: 'me-gusta', label: 'Me gusta' },
		{ id: 'le-gusto', label: 'Le gusto', /* hasNotification: true */ },
		{ id: 'match', label: 'Match' }
	];

	useEffect(() => {
		const rows = (liked || []);
		const row = (rows[0] || {});
		const my_likes = row.likes || [];
		const its_likes = row.liked || [];
		if (my_likes === undefined || its_likes === undefined) return;

		if (activeTab === 'me-gusta') setShowData(profiles.filter(x => my_likes.includes(x.id)));
		if (activeTab === 'le-gusto') setShowData(profiles.filter(x => its_likes.includes(x.id)));
		if (activeTab === 'match') setShowData(profiles.filter(x => my_likes.includes(x.id) && its_likes.includes(x.id)));
	}, [activeTab, liked, profiles]);

	const handleCloseProfile = () => {
		setShowProfile(null);
	};

	return (
		<div className="min-h-screen pt-16 bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 text-white p-4">
			<header className="mb-6">
				<h1 className="text-3xl font-bold mb-4">{trans("liked.title", "Perfiles que te gustan")}</h1>
				<div className="tabs-filter flex justify-around mb-4 bg-white bg-opacity-20 backdrop-blur-lg rounded-full p-1">
					{tabs.map((tab) => (
						<button
							key={tab.id}
							onClick={() => setActiveTab(tab.id)}
							className={`flex items-center relative px-4 py-2 rounded-full ${activeTab === tab.id ? 'bg-white text-purple-600' : 'text-white hover:bg-white hover:bg-opacity-10'
								} focus:outline-none transition-colors duration-300`}
						>
							{tab.label}
							{tab.hasNotification && (
								<span className="absolute -top-1 -right-1 flex h-3 w-3">
									<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
									<span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
								</span>
							)}
						</button>
					))}
				</div>
			</header>

			<div className="liked-list grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
				{showData.map((profile) => (
					<ProfileCard key={profile.id} profile={profile} onClick={() => setShowProfile(profile)} />
				))}
			</div>

			<OverPage show={showProfile?.id} setClose={handleCloseProfile}>
				<ProfileDetailsId item={showProfile} />
			</OverPage>
		</div>
	);
};

export default LikesListPage;