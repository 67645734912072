import React, { useState, useEffect } from 'react';
import { Heart, X, /* MapPin, Edit, Filter, */ Beer, Calendar, Briefcase, GraduationCap, Ruler, Scale, PawPrint, Wine, Cigarette, Users, Star, Brain, Flag, ChevronLeft, ChevronRight } from 'lucide-react';
import { useAppContexts } from '../../AppContext';

const IconWrapper = ({ children, color }) => (
	<div className={`w-10 h-10 rounded-full ${color} flex items-center justify-center`}>
		{children}
	</div>
);

const ProfileInfo = ({ Icon, label, value, color }) => (
	<div className="flex items-center mb-4">
		<IconWrapper color={color}>
			<Icon className="w-5 h-5 text-white" />
		</IconWrapper>
		<div className="ml-3">
			<p className="text-sm text-gray-100">{label}</p>
			<p className="text-base font-medium">{value}</p>
		</div>
	</div>
);

const FlagItem = ({ flag, isGreen }) => (
	<div className={`flex items-center mb-2 ${isGreen ? 'text-green-400' : 'text-red-400'}`}>
		<Flag className="w-5 h-5 mr-2" />
		<span>{flag}</span>
	</div>
);

const LazyImageCarousel = ({ images, alt, children }) => {
	const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
	const [loadedImages, setLoadedImages] = useState([]);

	useEffect(() => {
		// Precargar la imagen actual y las dos siguientes
		const imagesToLoad = [
			images[currentPhotoIndex],
			images[(currentPhotoIndex + 1) % images.length],
			images[(currentPhotoIndex + 2) % images.length]
		];

		imagesToLoad.forEach((src) => {
			if (!loadedImages.includes(src)) {
				const img = new Image();
				img.src = src;
				// img.loading = 'lazy'; // No funciona en carrusel
				img.onload = () => {
					setLoadedImages(prev => [...prev, src]);
				}
			}
		});
	}, [currentPhotoIndex, images, loadedImages]);

	const nextPhoto = () => {
		setCurrentPhotoIndex((prev) => (prev + 1) % images.length);
	};

	const prevPhoto = () => {
		setCurrentPhotoIndex((prev) => (prev - 1 + images.length) % images.length);
	};

	return (
		<div className="relative w-full h-96">
			{loadedImages.includes(images[currentPhotoIndex]) ? (
				<img
					src={images[currentPhotoIndex]}
					alt={`${alt} ${currentPhotoIndex + 1}`}
					loading="lazy"
					className="w-full h-full object-cover rounded-xl"
				/>
			) : (
				<div className="w-full h-full flex items-center justify-center bg-gray-200 rounded-xl">
					Cargando...
				</div>
			)}
			<button onClick={prevPhoto} className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 rounded-full p-2">
				<ChevronLeft className="w-6 h-6 text-white" />
			</button>
			<button onClick={nextPhoto} className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 rounded-full p-2">
				<ChevronRight className="w-6 h-6 text-white" />
			</button>
			<div className="absolute bottom-0 w-full p-3 pt-8" style={{background: "linear-gradient(0deg, rgba(0, 0, 0, 0.733) 46%, rgba(0, 0, 0, 0) 90%)"}}>
				<div className="absolute bottom-12 left-1/2 transform -translate-x-1/2 flex space-x-2">
					{images.map((_, idx) => (
						<div key={idx} className={`w-2 h-2 rounded-full ${idx === currentPhotoIndex ? 'bg-white' : 'bg-gray-400'}`}></div>
					))}
				</div>
				<h3 class="flex items-center text-3x1 font-semibold mt-2">{children}</h3>
			</div>
			
		</div>
	);
};

const ProfileUser = ({ profile, profile1, liked, showEdit, showFilter, onLike, onDislike }) => {
	const { trans } = useAppContexts();

	if (!profile || !profile.id) return <div className="w-full h-full flex justify-center items-center text-2xl font-bold text-gray-500">No hay más perfiles para mostrar</div>

	const children = profile.has_children;
	const smoking = profile.smoking && profile.smoking !== "No fumador";
	const drinking = profile.drinking && profile.drinking !== "No bebe";

	return (
		<div key={profile.id} className="min-h-screen pt-16 bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 text-white p-4">
			<LazyImageCarousel images={profile.imagesURL} alt={profile.name} >
				<div className="flex items-center mr-6">
					<Calendar size={28} className={`mr-2 text-green-500`} />{profile.age}
				</div>
				<div className="flex items-center mr-2">
					<Users size={28} className={`mr-1 ${children ? 'text-green-500' : 'text-gray-400'}`} />
				</div>
				<div className="flex items-center mr-2">
					<Cigarette size={28} className={`mr-1 ${smoking ? 'text-green-500' : 'text-gray-400'}`} />
				</div>
				<div className="flex items-center">
					<Beer size={28} className={`mr-1 ${drinking ? 'text-green-500' : 'text-gray-400'}`} />
				</div>
			</LazyImageCarousel>

			<div className="bg-white bg-opacity-20 backdrop-blur-lg rounded-xl p-6 mb-6">
				<h2 className="text-2xl font-semibold mb-4">{trans("profile.about", "Sobre mí")}</h2>
				<p className="text-gray-200 mb-4">{profile.bio}</p>

				<ProfileInfo Icon={Briefcase} label={trans("profile.profession", "Profesión")} value={profile.profession} color="bg-blue-500" />
				<ProfileInfo Icon={GraduationCap} label={trans("profile.education", "Educación")} value={profile.education} color="bg-green-500" />
				<ProfileInfo Icon={Ruler} label={trans("profile.height", "Altura")} value={profile.height} color="bg-yellow-500" />
				{/* <ProfileInfo Icon={Scale} label={trans("profile.weight", "Peso")} value={profile.weight} color="bg-red-500" /> */}
				<ProfileInfo Icon={PawPrint} label={trans("profile.pets", "Mascotas")} value={profile.pets} color="bg-purple-500" />
				{/* <ProfileInfo Icon={Wine} label={trans("profile.alcohol", "Alcohol")} value={profile.alcohol} color="bg-pink-500" /> */}
				{/* <ProfileInfo Icon={Cigarette} label={trans("profile.smoker", "Fumador")} value={profile.smoker} color="bg-orange-500" /> */}
				{/* <ProfileInfo Icon={Users} label={trans("profile.orientation", "Orientación")} value={profile.orientation} color="bg-indigo-500" /> */}
				<ProfileInfo Icon={Star} label={trans("profile.zodiac", "Signo zodiacal")} value={profile.zodiacSign} color="bg-teal-500" />
				<ProfileInfo Icon={Brain} label={trans("profile.personality", "Personalidad")} value={profile.personality} color="bg-cyan-500" />
			</div>

			<div className="bg-white bg-opacity-20 backdrop-blur-lg rounded-xl p-6 mb-6">
				<h2 className="text-2xl font-semibold mb-4">{trans("profile.characteristics", "Características")}</h2>
				<div className="grid grid-cols-2 gap-4">
					{profile.characteristics.map((char, index) => (
						<div key={index} className="bg-white bg-opacity-10 rounded-lg p-3">
							<p className="font-medium">{char.category}</p>
							<div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
								<div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${char.text}%` }}></div>
							</div>
						</div>
					))}
				</div>
			</div>

			<div className="bg-white bg-opacity-20 backdrop-blur-lg rounded-xl p-6 mb-6">
				<h2 className="text-2xl font-semibold mb-4">{trans("profile.flags", "Green & Red Flags")}</h2>
				<div className="grid grid-cols-2 gap-4">
					<div>
						<h3 className="text-xl font-medium mb-2 text-green-400">Green Flags</h3>
						{profile.greenFlags.map((flag, index) => (
							<FlagItem key={index} flag={flag} isGreen={true} />
						))}
					</div>
					<div>
						<h3 className="text-xl font-medium mb-2 text-red-400">Red Flags</h3>
						{profile.redFlags.map((flag, index) => (
							<FlagItem key={index} flag={flag} isGreen={false} />
						))}
					</div>
				</div>
			</div>

			<div className="bg-white bg-opacity-20 backdrop-blur-lg rounded-xl p-6 mb-6">
				<h2 className="text-2xl font-semibold mb-4">{trans("profile.interests", "Intereses")}</h2>
				<div className="flex flex-wrap gap-2">
					{profile.interests?.map((interest, index) => (
						<span key={index} className="px-3 py-1 bg-white bg-opacity-30 rounded-full text-sm">
							{interest}
						</span>
					))}
				</div>
			</div>

			<div className="bg-white bg-opacity-20 backdrop-blur-lg rounded-xl p-6 mb-20">
				<h2 className="text-2xl font-semibold mb-4">{trans("profile.questions", "Preguntas Aleatorias")}</h2>
				{profile.randomQuestions?.map((q, index) => (
					<div key={index} className="mb-4">
						<p className="font-medium mb-1">{q.question}</p>
						<p className="text-gray-200">{q.answer}</p>
					</div>
				))}
			</div>

			<div className="fixed bottom-16 left-0 right-0 flex justify-around p-8 pb-4" style={{background: "linear-gradient(0deg, #000B 30%, #0000 100%)"}}>
				<button
					onClick={onDislike}
					className="flex-1 mr-2 py-3 bg-red-500 text-white rounded-full flex items-center justify-center"
				>
					<X className="mr-2" /> No me interesa
				</button>
				<button
					onClick={onLike}
					className="flex-1 ml-2 py-3 bg-green-500 text-white rounded-full flex items-center justify-center"
				>
					<Heart className="mr-2" /> Me gusta
				</button>
			</div>
		</div>
	);
};

export default ProfileUser;