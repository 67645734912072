import React, { useState } from 'react';
// import { Mail, Lock, Eye, EyeOff } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAppContexts } from '../../AppContext';
import Google from './Google';
import testData from '../../test/testData';

import ApiUrl from '../../Helper/ApiUrl';

const LoginPage = () => {
	const [data, setData] = useState(testData.login || {});
	const { /* isLogin, */ setLogin } = useAppContexts();

	const onChange = (e) => {
		const { name, value } = e.target;
		setData({ ...data, [name]: value });
	};

	const onSubmit = (e) => {
		e.preventDefault();

		fetch(ApiUrl + '/api/login', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
			},
		})
		.then(res => {
			return res.json();
		})
		.then((res) => {
			setLogin(res.user.api_token);
		})
		.catch(err => {
			console.error('Login failed', err);
		});
	};

	return (
		<div className="flex flex-col justify-center items-center p-8 w-full h-full bg-gray-100">
			<div className="bg-blue-200 w-full py-4 px-8 rounded-t-lg">
				<h1 className="text-2xl font-bold text-center"> Inicia sesión en tu cuenta</h1>
			</div>
			<div className="bg-white p-8 rounded-b-lg shadow-md w-full h-full overflow-auto flex flex-col justify-between">
				<form onSubmit={onSubmit} className="flex flex-col h-full overflow-auto ">
					<div className="mb-6">
						{/* <h2 className="text-2xl font-bold mb-2">Crea tu cuenta</h2> */}
						<p className="text-gray-600">Ingresa tu correo y contraseña</p>
					</div>
					<div className="space-y-4">
						<input name="email" type="email" placeholder="Correo electrónico" value={data.email} onChange={onChange} className="w-full p-2 border rounded" />
						<input name="password" type="password" placeholder="Contraseña" value={data.password} onChange={onChange} className="w-full p-2 border rounded" />
						<button onClick={onSubmit} className="bg-blue-500 w-full text-white mb-2 px-4 py-2 rounded hover:bg-blue-600">Login</button>
					</div>

					<div className="relative my-6">
						<div className="absolute inset-0 flex items-center">
							<div className="w-full border-t border-gray-300"></div>
						</div>
						<div className="relative flex justify-center text-sm">
							<span className="px-2 bg-white text-gray-500">O Conectate Via</span>
						</div>
					</div>

					<div className="mb-6 flex flex-col ">
						<Google className="bg-blue-500 text-white text-center mb-2 px-4 py-2 rounded hover:bg-blue-600">Google</Google>
						{/* <button className="bg-blue-500 text-white text-center mb-2 px-4 py-2 rounded hover:bg-blue-600">Facebook</button> */}

						<div className="mt-6">
							<Link to="/register" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
								Regístrate
							</Link>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default LoginPage;