import React, { useEffect } from 'react';
import { /* Heart, User, */ Calendar, Users, Cigarette, Beer } from 'lucide-react';
// import { NavLink } from 'react-router-dom';
import { useAppContexts } from '../../AppContext';
import OverPage from '../../Components/OverPage';
import ProfileDetailsId from './../ProfileDetailsId';

const tutorialSteps = [
	// {
	// 	// findItem: 1,
	// 	selector: ".filter-btn",
	// 	title: "Eventos de Citas",
	// 	description: "Clicka aqui para filtrar usuarios",
	// 	masks: [
	// 		{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: false, scrollTo: false, color: null },
	// 	],
	// },
	{
		// findItem: 1,
		selector: ".tabs-filter",
		title: "Usuarios que te gustan",
		description: "Selecciona que quieres ver.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: false, scrollTo: false, color: null },
		],
	},
	{
		findItem: 1,
		selector: ".liked-list",
		title: "Usuarios que te gustan",
		description: "Aqui puedes ver las personas que les diste a like.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
		],
	},
	{
		findItem: 1,
		selector: ".liked-list",
		title: "Usuarios que te gustan",
		description: "Nombre del usuario.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
			{ type: "highlight", x: 100, y: 100, w: 50, h: 30, selector: ".name", color: "rgba(255, 0, 0, 0.5)" }
		],
	},
	{
		findItem: 1,
		selector: ".liked-list",
		title: "Usuarios que te gustan",
		description: "edad del usuario.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
			{ type: "highlight", x: 100, y: 100, w: 50, h: 30, selector: ".age", color: "rgba(255, 0, 0, 0.5)" }
		],
	},
	{
		findItem: 1,
		selector: ".liked-list",
		title: "Usuarios que te gustan",
		description: "Caracteristicas del usuario.\n1. Tiene hijos.\n2. Fuma.\n3. Bebe.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
			{ type: "highlight", x: 100, y: 100, w: 50, h: 30, selector: ".user-info", color: "rgba(255, 0, 0, 0.5)" }
		],
	},
];

const BoxStyle = {
	position: "relative",
	display: "flex",
	borderRadius: "8px",
	backgroundPosition: "center",
	overflow: "hidden",
	height: "180px",
	flexDirection: "column",
	justifyContent: "flex-end",
};

const BoxImgStyle = {
	position: "absolute",
	borderRadius: "8px",
	width: "100%",
	height: "100%",
	inset: "0",
	objectFit: "cover"
};

const gradientOverlayStyle = {
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	background: "linear-gradient(to top, #0009 40px, transparent 80px)",
};

const LikeImage = ({ profile, onClick }) => {
	const children = profile.has_children;
	const smoking = profile.smoking && profile.smoking !== "No fumador";
	const drinking = profile.drinking && profile.drinking !== "No bebe";
	return (
		<div onClick={onClick} style={BoxStyle} className="bg-white shadow-md rounded-lg p-4 flex">
			<img style={BoxImgStyle} src={profile.image} alt={profile.name} loading="lazy" className="w-24 h-24 rounded-full mr-4" />
			<div style={gradientOverlayStyle}></div>

			<div style={{ zIndex: 2, color: "#fff" }}>
				<h2 className="text-xl font-bold">
					<span className="name">{profile.name}</span>
				</h2>

				<div className="user-info flex">

					<div className="flex items-center mr-5">
						<Calendar className={`w-4 h-4 mr-2 text-green-500`} />{profile.age}
					</div>
					<div className="flex items-center mr-2">
						<Users className={`w-4 h-4 mr-1 ${children ? 'text-green-500' : 'text-gray-400'}`} />
					</div>
					<div className="flex items-center mr-2">
						<Cigarette className={`w-4 h-4 mr-1 ${smoking ? 'text-green-500' : 'text-gray-400'}`} />
					</div>
					<div className="flex items-center">
						<Beer className={`w-4 h-4 mr-1 ${drinking ? 'text-green-500' : 'text-gray-400'}`} />
					</div>
				</div>

			</div>
		</div>
	)
};

const LikesListPage = ({liked, profiles, activeTab, setActiveTab, showData, setShowData, showProfile, setShowProfile, showFilter, setShowFilter}) => {
	const { trans, setTutorial } = useAppContexts();

	useEffect(() => { setTutorial(tutorialSteps); return () => setTutorial(null) }, [setTutorial]);

	const tabs = [
		{ id: 'me-gusta', label: 'Me gusta' },
		{ id: 'le-gusto', label: 'Le gusto', /* hasNotification: true */ },
		{ id: 'match', label: 'Match' }
	];

	useEffect(() => {
		const my_likes = liked.filter(x=>x.like).map(x=>x.id);
		const its_likes = liked.filter(x=>x.him).map(x=>x.id);

		if (activeTab === 'me-gusta') setShowData(profiles.filter(x => my_likes.includes(x.id)));
		if (activeTab === 'le-gusto') setShowData(profiles.filter(x => its_likes.includes(x.id)));
		if (activeTab === 'match') setShowData(profiles.filter(x => my_likes.includes(x.id) && its_likes.includes(x.id)));
	}, [activeTab, liked, profiles]);

	const handleCloseProfile = () => {
		setShowProfile(null);
	};

	return (
		<div className="container mx-auto p-4">
			<div className="">
				<div className="flex justify-between items-center mb-6">
					<h1 className="text-2xl font-bold">{trans("liked.title", "Chicas que me gustan")}</h1>
					{/* <button className="filter-btn p-3 rounded-full transition-colors" aria-label="Configuración" >
						<Filter className="w-6 h-6" />
					</button> */}
				</div>
				<div className="tabs-filter flex justify-around mb-4">
					{
						tabs.map(
							(tab) => {
								const active = (activeTab === tab.id) ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500 hover:text-gray-700';
								return (
									<button key={tab.id} onClick={() => setActiveTab(tab.id)} className={`flex items-center relative px-4 py-2 ${active} focus:outline-none transition-colors duration-300`} >
										{tab.label}
										{tab.hasNotification && (
											<div className="flex justify-center items-center w-6 h-6 ml-2 bg-red-500 text-white rounded-full">2</div>
										)}
									</button>
								)
							}
						)
					}
				</div>
			</div>
			<div className="liked-list grid grid-cols-1 grid-cols-2 gap-4">
				{showData.map((girl, index) => <LikeImage key={index} onClick={() => setShowProfile(girl)} profile={girl} />)}
			</div>

			<OverPage show={showProfile?.id} setClose={handleCloseProfile} > <ProfileDetailsId item={showProfile} /> </OverPage>
		</div>
	);
};

export default LikesListPage;