import React, { useState, useEffect, useRef } from 'react';
import testData from '../../test/testData';
import OverPage from '../../Components/OverPage';
import { usePost } from '../../Helper/useFetch';
import { useAppContexts } from '../../AppContext';
import { Link, useLocation } from 'react-router-dom';
import { ImagePlus as Camera, Trash2, PencilRuler, X, Save, Check } from 'lucide-react';

const myImages = [
	{ name: 'Foto 1', image: '/server/x (2)/1.png' },
	{ name: 'Foto 2', image: '/server/x (5)/1.png' },
	{ name: 'Foto 3', image: '/server/x (21)/1.png' },
	{ name: 'Foto 4', image: '/server/x (32)/1.png' },
];

const BoxStyle = {
	position: "relative",
	display: "flex",
	borderRadius: "8px",
	backgroundPosition: "center",
	overflow: "hidden",
	height: "180px",
	flexDirection: "column",
	justifyContent: "flex-end",
};

const BoxImgStyle = {
	position: "absolute",
	borderRadius: "8px",
	width: "100%",
	height: "100%",
	inset: "0",
	objectFit: "cover"
};

const gradientOverlayStyle = {
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	background: "linear-gradient(to top, #0009 40px, transparent 80px)",
};

// const InputField = React.memo(({ name, value, onChange, ...props }) => {
// 	const [localValue, setLocalValue] = useState(value);

// 	const handleChange = (e) => {
// 		//   setLocalValue(e.target.value);
// 		onChange(e);
// 	};

// 	return <input name={name} value={value} onChange={handleChange} {...props} />;
// });

// const InputField = ({ name, value, onChange, ...props }) => {
// 	const [localValue, setLocalValue] = useState(value);

// 	const handleChange = (e) => {
// 	//   setLocalValue(e.target.value);
// 	  onChange(e);
// 	};

// 	return <input name={name} value={value} onChange={handleChange} {...props} />;
//   };

// const fnNumber = (x) => !isNaN(x);
// const fnDate = (x) => !isNaN(Date.parse(x));
// const fnNotNull = (x) => String(x ?? "") !== '';
// const fnArray = (x, max) => Array.isArray(x) && x.length > 0 && x.length <= max;
// const checkSubmut = {
// 	// PageAccount
// 	email: fnNotNull,
// 	password: fnNotNull,

// 	// PagePersonInfo
// 	nombre: fnNotNull,
// 	bday: fnDate,
// 	native_gender: fnNumber,
// 	gender: fnNumber,
// 	orientation: fnNumber,
// 	has_children: fnNumber,

// 	// PageInfoGenero
// 	name: fnNotNull,
// 	looking_for: fnNumber,
// 	height: fnNumber,
// 	weight: fnNumber,
// 	drinking: fnNumber,
// 	smocking: fnNumber,
// 	animal: fnNumber,

// 	// PageHobbies
// 	hobbies: (x) => fnArray(x, 3),

// 	// PagePreferencias
// 	pref_food: fnNotNull,
// 	pref_drink: fnNotNull,
// 	pref_music: fnNotNull,
// 	pref_movie: fnNotNull,
// 	pref_book: fnNotNull,
// 	paises_visitados: fnNotNull,
// 	paises_para_visitar: fnNotNull,

// 	// PageExtra
// 	education: fnNotNull,
// 	employment: fnNotNull,
// 	wants_children: fnNumber,
// 	freq_viajes: fnNumber,
// 	freq_sport: fnNumber,

// 	// PageFotos

// 	// PageVerification

// };

const PageAccount = ({ data, onChange }) => (
	<>
		<div className="mb-6">
			<h2 className="text-2xl font-bold mb-2">Crea tu cuenta</h2>
			<p className="text-gray-600">Ingresa tu correo para crear la cuenta</p>
		</div>
		<div className="space-y-4">
			<input required name="email" type="email" placeholder="Correo electrónico" value={data.email || ''} onChange={onChange} className="w-full p-2 border rounded" />
			<input required minLength="8" name="password" type="password" placeholder="Contraseña" value={data.password || ''} onChange={onChange} className="w-full p-2 border rounded" />
		</div>

		<div className="relative my-6">
			<div className="absolute inset-0 flex items-center">
				<div className="w-full border-t border-gray-300"></div>
			</div>
			<div className="relative flex justify-center text-sm">
				<span className="px-2 bg-white text-gray-500">O Conectate Via</span>
			</div>
		</div>

		<div className="mb-6 flex flex-col ">

			<button className="bg-red-500 text-white mb-2 px-4 py-2 rounded hover:bg-red-600">Google</button>
			<button className="bg-blue-500 text-white mb-2 px-4 py-2 rounded hover:bg-blue-600">Facebook</button>
			<div className="mt-6">
				<Link to="/" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
					Login
				</Link>
			</div>
		</div>

	</>
);

const PagePersonInfo = ({ data, onChange }) => (
	<>
		<div className="mb-6">
			<h2 className="text-2xl font-bold mb-2">Información Personal</h2>
			<p className="text-gray-600">Ingresa tus datos personales básicos</p>
		</div>
		<div className="space-y-4">
			<input required name="full_name" placeholder="Nombre completo" value={data.full_name || ''} onChange={onChange} className="w-full p-2 border rounded" />
			<input required name="bday" type="date" placeholder="Fecha de Nacimiento" value={data.bday || ''} onChange={onChange} className="w-full p-2 border rounded" />
			<select required name="native_gender" value={data.native_gender || ''} onChange={onChange} className="w-full p-2 border rounded">
				<option value="">Genero de Nacimiento</option>
				<option value="0">femenino</option>
				<option value="1">masculino</option>
				<option value="2">no binario</option>
			</select>
			<select required name="gender" value={data.gender || ''} onChange={onChange} className="w-full p-2 border rounded">
				<option value="">Genero Actual</option>
				<option value="0">Soy Mujer</option>
				<option value="1">Soy Hombre</option>
				<option value="2">Soy No binario</option>
			</select>
			<select required name="orientation" value={data.orientation || ''} onChange={onChange} className="w-full p-2 border rounded">
				<option value="">Orientacion</option>
				<option value="0">Heterosexual</option>
				<option value="1">Homosexual</option>
				<option value="2">Bisexual</option>
			</select>
			<select required name="has_children" value={data.has_children || ''} onChange={onChange} className="w-full p-2 border rounded">
				<option value="">Tienes Hijos?</option>
				<option value="0">No Tengo Hijos</option>
				<option value="1">Si, Tengo Hijos</option>
			</select>
		</div>
	</>
);

const PageInfoGenero = ({ data, onChange }) => (
	<>
		<div className="mb-6">
			<h2 className="text-2xl font-bold mb-2">General</h2>
			<p className="text-gray-600">Informacion General</p>
		</div>
		<div className="space-y-4">
			<input required name="name" placeholder="Nick Name" value={data.name || ''} onChange={onChange} className="w-full p-2 border rounded" />
			<select required name="looking_for" value={data.looking_for || ''} onChange={onChange} className="w-full p-2 border rounded">
				<option value="">Que Buscas</option>
				<option value="0">Busco Mujeres</option>
				<option value="1">Busco Hombres</option>
				<option value="2">Busco Ambos</option>
			</select>
			<input name="height" type="number" placeholder="Altura (cm)" value={data.height || ''} onChange={onChange} className="w-full p-2 border rounded" />
			<input name="weight" type="number" placeholder="Peso (Kg)" value={data.weight || ''} onChange={onChange} className="w-full p-2 border rounded" />
			<select name="drinking" value={data.drinking || ''} onChange={onChange} className="w-full p-2 border rounded">
				<option value="">Bebes Alcohol ?</option>
				<option value="0">No Bebo</option>
				<option value="1">Bebo Ocasional</option>
				<option value="2">Bebo Regular</option>
			</select>
			<select name="smoking" value={data.smoking || ''} onChange={onChange} className="w-full p-2 border rounded">
				<option value="">Fumas ?</option>
				<option value="0">No Fumo</option>
				<option value="1">Fumo Ocasional</option>
				<option value="2">Fumo Regular</option>
			</select>
			<select name="animal" value={data.animal || ''} onChange={onChange} className="w-full p-2 border rounded">
				<option value="">Tienes Mascotas ?</option>
				<option value="0">No tengo Mascota</option>
				<option value="1">Tengo un perro</option>
				<option value="2">Tengo un Gato</option>
			</select>
		</div>
	</>
);

const PageHobbies = ({ data, onChange }) => {
	data.hobbies = data.hobbies || [];
	const [checked, setChecked] = useState(true);

	useEffect(() => {
		setChecked(data.hobbies.length == 0 || data.hobbies.length > 3);
	}, [data.hobbies]);

	return (
		<>
			<div className="mb-6">
				<h2 className="text-2xl font-bold mb-2">Hobbies</h2>
				<p className="text-gray-600">Selecciona tus Hobbies ({data.hobbies.length}/3)</p>
				{checked ? "Checked" : "Not Checked"}
			</div>
			<div className="space-y-4">
				<label className="flex items-center space-x-4">
					<input type="checkbox" name="hobbies" checked={data.hobbies.indexOf("cenas") > -1} value="cenas" onChange={onChange} />
					<div>Cenas</div>
				</label>
				<label className="flex items-center space-x-4">
					<input type="checkbox" name="hobbies" checked={data.hobbies.indexOf("karaoke") > -1} value="karaoke" onChange={onChange} />
					<div>Karaoke</div>
				</label>
				<label className="flex items-center space-x-4">
					<input type="checkbox" name="hobbies" checked={data.hobbies.indexOf("ciclismo") > -1} value="ciclismo" onChange={onChange} />
					<div>Ciclismo</div>
				</label>
				<label className="flex items-center space-x-4">
					<input type="checkbox" name="hobbies" checked={data.hobbies.indexOf("senderismo") > -1} value="senderismo" onChange={onChange} />
					<div>Senderismo</div>
				</label>
				<input required={checked} className="px-4 py-2" type="readonly" placeholder={checked ? "select at less one hobbie" : ""} />
			</div>
		</>
	)
};

const PagePreferencias = ({ data, onChange }) => (
	<>
		<div className="mb-6">
			<h2 className="text-2xl font-bold mb-2">Intereses</h2>
			<p className="text-gray-600">Selecciona tus intereses</p>
		</div>
		<div className="space-y-4">

			<input list="browsers" name="pref_food" placeholder="Comida Preferida" value={data.pref_food || ''} onChange={onChange} className="w-full p-2 border rounded" />
			<input list="browsers" name="pref_drink" placeholder="Bebida Preferida" value={data.pref_drink || ''} onChange={onChange} className="w-full p-2 border rounded" />
			<input list="browsers" name="pref_music" placeholder="Genero Musical" value={data.pref_music || ''} onChange={onChange} className="w-full p-2 border rounded" />
			<input list="browsers" name="pref_movie" placeholder="Genero de Pelicula" value={data.pref_movie || ''} onChange={onChange} className="w-full p-2 border rounded" />
			<input list="browsers" name="pref_book" placeholder="Genero de Libro" value={data.pref_book || ''} onChange={onChange} className="w-full p-2 border rounded" />
			<input list="browsers" name="paises_visitados" placeholder="Paises Visitados" value={data.paises_visitados || ''} onChange={onChange} className="w-full p-2 border rounded" />
			<input list="browsers" name="paises_para_visitar" placeholder="Paises para visitar" value={data.paises_para_visitar || ''} onChange={onChange} className="w-full p-2 border rounded" />
		</div>

		<datalist id="browsers">
			<option value="Internet Explorer" />
			<option value="Firefox" />
			<option value="Google Chrome" />
			<option value="Opera" />
			<option value="Safari" />
		</datalist>
	</>
);

const PageExtra = ({ data, onChange }) => (
	<>
		<div className="mb-6">
			<h2 className="text-2xl font-bold mb-2">Intereses</h2>
			<p className="text-gray-600">Selecciona tus intereses</p>
		</div>
		<div className="space-y-4">
			<input name="education" placeholder="Educacion" value={data.education || ''} onChange={onChange} className="w-full p-2 border rounded" />
			<input name="employment" placeholder="Trabajo" value={data.employment || ''} onChange={onChange} className="w-full p-2 border rounded" />
			<select name="wants_children" value={data.wants_children || ''} onChange={onChange} className="w-full p-2 border rounded">
				<option value="">Te gustaria tener hijos ?</option>
				<option value="0">No quiero hijos</option>
				<option value="1">Si, quiero hijos</option>
				<option value="2">Algun dia</option>
			</select>
			<select name="freq_viajes" value={data.freq_viajes || ''} onChange={onChange} className="w-full p-2 border rounded">
				<option value=""> Frecuencua de viajes </option>
				<option value="0">No suelo viajar</option>
				<option value="1">viajo una vez al año</option>
				<option value="2">viajo frecuentemente</option>
			</select>
			<select name="freq_sport" value={data.freq_sport || ''} onChange={onChange} className="w-full p-2 border rounded">
				<option value=""> Frecuencua de deporte </option>
				<option value="0">No suelo hacer deporte</option>
				<option value="1">de vez en cuando</option>
				<option value="2">frecuentemente</option>
			</select>
		</div>
	</>
);

const PageRedFlags = ({ data, onUpdata }) => {
	const [redflags, setRedflags] = useState(data.redflags || []);

	const handleOnchange = (e) => {
		const { name, value } = e.target;

		setRedflags((prev) => {
			const newRedflags = [...prev];
			newRedflags[name] = value;
			onUpdata({ ...data, redflags: newRedflags });
			return newRedflags;
		});
		
	};

	return (
		<>
			<div className="mb-6">
				<h2 className="flex justify-start items-center text-2xl font-bold mb-2">
					<X className='text-red-500 mr-1'  style={{ strokeWidth: "7px" }}/>
					Red Flags
				</h2>
				<p className="text-gray-600">Introduce una lista de cosas que no te gustan de la otra persona, puedes añadir maximo 5</p>
			</div>
			<div className="space-y-4">
				<input name="0" list="red_flags_list" value={redflags[0] || ''} onChange={handleOnchange} className="w-full p-2 border rounded" placeholder="drogadictos" />
				<input name="1" list="red_flags_list" value={redflags[1] || ''} onChange={handleOnchange} className="w-full p-2 border rounded" placeholder="Fumadores" />
				<input name="2" list="red_flags_list" value={redflags[2] || ''} onChange={handleOnchange} className="w-full p-2 border rounded" placeholder="Mentirosos" />
				<input name="3" list="red_flags_list" value={redflags[3] || ''} onChange={handleOnchange} className="w-full p-2 border rounded" placeholder="Perros" />
				<input name="4" list="red_flags_list" value={redflags[4] || ''} onChange={handleOnchange} className="w-full p-2 border rounded" placeholder="Gatos" />
			</div>
			<datalist id="red_flags_list">
				<option value="drogadictos" />
				<option value="Fumadores" />
				<option value="Mentirosos" />
				<option value="Perros" />
				<option value="Gatos" />
			</datalist>
		</>
	)
};

const PageGreenFlags = ({ data, onUpdata }) => {
	const [greenflags, setGreenflags] = useState(data.greenflags || []);

	const handleOnchange = (e) => {
		const { name, value } = e.target;

		setGreenflags((prev) => {
			const newRedflags = [...prev];
			newRedflags[name] = value;
			onUpdata({ ...data, greenflags: newRedflags });
			return newRedflags;
		});
		
	};

	return (
		<>
			<div className="mb-6">
				<h2 className="flex justify-start items-center text-2xl font-bold mb-2">
					<Check className='text-green-500 mr-1'  style={{ strokeWidth: "7px" }}/>
					Green Flags
				</h2>
				<p className="text-gray-600">Introduce una lista de cosas que te gustan de la otra persona, puedes añadir maximo 5</p>
			</div>
			<div className="space-y-4">
				<input name="0" list="green_flags_list" value={greenflags[0] || ''} onChange={handleOnchange} className="w-full p-2 border rounded" placeholder="drogadictos" />
				<input name="1" list="green_flags_list" value={greenflags[1] || ''} onChange={handleOnchange} className="w-full p-2 border rounded" placeholder="Fumadores" />
				<input name="2" list="green_flags_list" value={greenflags[2] || ''} onChange={handleOnchange} className="w-full p-2 border rounded" placeholder="Mentirosos" />
				<input name="3" list="green_flags_list" value={greenflags[3] || ''} onChange={handleOnchange} className="w-full p-2 border rounded" placeholder="Perros" />
				<input name="4" list="green_flags_list" value={greenflags[4] || ''} onChange={handleOnchange} className="w-full p-2 border rounded" placeholder="Gatos" />
			</div>
			<datalist id="green_flags_list">
				<option value="drogadictos" />
				<option value="Fumadores" />
				<option value="Mentirosos" />
				<option value="Perros" />
				<option value="Gatos" />
			</datalist>
		</>
	)
};
const PageFotos = ({ data, onUpdata }) => {
	const [previewUrls, setPreviewUrls] = useState([]);
	const [uploadStatus, setUploadStatus] = useState('');

	const [showImageEdit, setShowImageEdit] = useState(null);
	const closeImageEdit = () => setShowImageEdit(null);

	const convertToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

	const handleFileChange = async (event) => {
		const files = Array.from(event.target.files);

		try {
			const base64s = await Promise.all(files.map(convertToBase64));
			const images = base64s.map((base64, index) => ({ name: `Image ${index + 1}`, image: base64, ratio: 1, posx: 0, posy: 0, scale: 1 }));
			setPreviewUrls(x => [...x, ...images]);
			event.target.value = null;
		} catch (error) {
			console.error('Error converting to base64:', error);
			setUploadStatus('Error al procesar las imágenes.');
		}
	};

	const handleUpload = async () => {
		if (previewUrls.length === 0) {
			setUploadStatus('Por favor, selecciona al menos una imagen.');
			return;
		}

		try {
			setUploadStatus('Subiendo imágenes...');
			const response = await fetch('https://tu-servidor.com/upload', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ images: previewUrls }),
			});

			if (response.ok) {
				setUploadStatus('Imágenes subidas con éxito!');
				setPreviewUrls([]);
			} else {
				setUploadStatus('Error al subir las imágenes. Por favor, intenta de nuevo.');
			}
		} catch (error) {
			console.error('Error:', error);
			setUploadStatus('Error de red. Por favor, verifica tu conexión.');
		}
	};

	const handleAddImage = (ev) => {
		ev.preventDefault();
		const input = document.querySelector('input[type="file"]');
		if (input) input.click();
	}

	const handleChangeImage = (newImage) => {
		const newImages = previewUrls.map(img => img === showImageEdit ? newImage : img);
		onUpdata({ ...data, images: newImages });
		setPreviewUrls(newImages);
	};

	return (
		<>
			<div className="mb-6">
				<h2 className="text-2xl font-bold mb-2">Sube Tus Fotos</h2>
				<p className="text-gray-600">Selecciona tus fotos, te puede ayudar a tener mas matches</p>
			</div>
			<div className="space-y-4">

				<input type="file" name="image" accept="image/*" multiple onChange={handleFileChange} className="hidden" />
				<button className='w-full flex items-center justify-center bg-blue-500 text-white rounded-lg p-4' onClick={handleAddImage}> Add Another Photo <Camera className='ml-4' /></button>
				{/* <div className="mb-4 flex flex-wrap gap-2">
					{previewUrls.map((img, index) => ( <img key={index} src={img.image} alt={`Preview ${index}`} loading="lazy" className="w-20 h-20 object-cover rounded" /> ))}
				</div> */}

				{previewUrls.map((img, index) => <MyImage onEdit={() => setShowImageEdit(img)} key={index} profile={img} />)}
			</div>

			<OverPage show={showImageEdit} setClose={closeImageEdit} >
				<ImageEdit onClose={closeImageEdit} profile={showImageEdit} onChange={handleChangeImage} />
			</OverPage>
		</>
	)
};

const ratioArray = [
	{ name: '1:1', value: 1 },
	{ name: '4:3', value: 4 / 3 },
	{ name: '16:9', value: 16 / 9 },
	{ name: '3:4', value: 3 / 4 },
	{ name: '9:16', value: 9 / 16 },
];

const MyImage = ({ profile, onDelete, onEdit }) => {
	const ratio = profile?.ratio || 1;

	const imageStyle = {
		backgroundImage: `url(${profile?.image})`,
		backgroundSize: `${profile?.scale*100}%`,
		backgroundPositionX: `${profile?.posx}px`,
		backgroundPositionY: `${profile?.posy}px`,
	};

	return (
		<div className="w-full relative rounded-lg overflow-hidden" style={{ paddingBottom: `${ratio * 100}%` }}>
			<div className="w-full h-full absolute inset-0 bg-cover bg-center bg-no-repeat" style={imageStyle} >
				<div className="z-20 text-white p-2">
					<h2 className="text-xl font-bold">{profile.name}</h2>

					<div className="absolute py-3 top-0 bottom-0 right-3 h-full flex flex-col justify-between">
						<div onClick={onDelete} className="p-3 rounded-full items-center bg-gray-100">
							<Trash2 className="w-6 h-6 text-red-500" />
						</div>
						<div onClick={onEdit} className="p-3 rounded-full items-center bg-gray-100">
							<PencilRuler className="w-6 h-6 text-green-500" />
						</div>
					</div>
				</div>
			</div>
		</div>

	);
};

// const PageInfoDireccion = ({ data, onChange }) => (
// 	<>
// 		<div className="mb-6">
// 			<h2 className="text-2xl font-bold mb-2">Dirección</h2>
// 			<p className="text-gray-600">Ingresa tu información de contacto</p>
// 		</div>
// 		<div className="space-y-4">
// 			<input name="direccion" placeholder="Dirección" value={data.direccion || ''} onChange={onChange} className="w-full p-2 border rounded" />
// 			<input name="ciudad" placeholder="Ciudad" value={data.ciudad || ''} onChange={onChange} className="w-full p-2 border rounded" />
// 			<input name="pais" placeholder="País" value={data.pais || ''} onChange={onChange} className="w-full p-2 border rounded" />
// 		</div>
// 	</>
// );

// const PageVerification = ({ data, onChange }) => (
// 	<>
// 		<div className="mb-6">
// 			<h2 className="text-2xl font-bold mb-2">Verificacion de Perfil</h2>
// 			<p className="text-gray-600">Pon tu cara, que cubra todo el espacio</p>
// 		</div>
// 		<div className="bg-blue-0 w-full h-full space-y-4">
// 			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 120">
// 				<path d="M50 10 C 20 10, 10 40, 10 60 C 10 80, 20 110, 50 110 C 80 110, 90 80, 90 60 C 90 40, 80 10, 50 10" fill="none" stroke="black" stroke-width="2" />
// 				<path d="M30 45 C 35 40, 40 40, 45 45 C 40 50, 35 50, 30 45" fill="none" stroke="black" stroke-width="1.5" />
// 				<path d="M55 45 C 60 40, 65 40, 70 45 C 65 50, 60 50, 55 45" fill="none" stroke="black" stroke-width="1.5" />
// 				<path d="M50 50 C 45 65, 55 65, 50 75" fill="none" stroke="black" stroke-width="1.5" />
// 				<path d="M35 85 C 45 95, 55 95, 65 85" fill="none" stroke="black" stroke-width="1.5" />
// 				<path d="M25 35 C 30 30, 40 30, 45 35" fill="none" stroke="black" stroke-width="1.5" />
// 				<path d="M55 35 C 60 30, 70 30, 75 35" fill="none" stroke="black" stroke-width="1.5" />
// 				<path d="M20 30 C 30 10, 70 10, 80 30" fill="none" stroke="black" stroke-width="2" />
// 			</svg>
// 		</div>
// 	</>
// );

const ImageEdit = ({ profile={}, onClose, onChange }) => {
	const [scale, setScale] = useState(profile?.scale || 1);
	const [ratio, setRatio] = useState(profile?.ratio.value || 1);
	const [position, setPosition] = useState({ x: profile?.posx || 0, y: profile?.posy || 0 });
	const [dragging, setDragging] = useState(false);
	const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

	const containerRef = useRef(null);

	const MAX_SCALE = 8;
	const MIN_SCALE = 1;
	const STEP_SCALE = 0.001;

	const handleWheel = (e) => {
		e.preventDefault();
		const delta = e.deltaY * -STEP_SCALE;
		const newScale = Math.min(MAX_SCALE, Math.max(MIN_SCALE, scale + delta));
		setScale(newScale);
	};

	const handleTouchStart = (e) => {
		const touch = e.touches[0];
		setDragging(true);
		setStartPosition({
			x: touch.clientX - position.x,
			y: touch.clientY - position.y
		});
	};

	const handleMouseDown = (e) => {
		setDragging(true);
		setStartPosition({
			x: e.clientX - position.x,
			y: e.clientY - position.y
		});
	};

	const handleTouchMove = (e) => {
		if (!dragging) return;
		const touch = e.touches[0];
		updatePosition(touch.clientX, touch.clientY);
	};

	const handleMouseMove = (e) => {
		if (!dragging) return;
		updatePosition(e.clientX, e.clientY);
	};

	const handleTouchEnd = () => {
		setDragging(false);
	};

	const handleMouseUp = () => {
		setDragging(false);
	};

	const updatePosition = (clientX, clientY) => {
		const containerRect = containerRef.current.getBoundingClientRect();
		const imageSize = 585 * scale;
		const containerSize = 96; // 24rem = 96px

		const maxX = 0;
		const minX = containerSize - imageSize;
		const maxY = 0;
		const minY = containerSize - imageSize;

		const newX = Math.min(maxX, Math.max(minX, clientX - startPosition.x));
		const newY = Math.min(maxY, Math.max(minY, clientY - startPosition.y));

		setPosition({ x: newX, y: newY });
	};

	useEffect(() => {
		const container = containerRef.current;
		container.addEventListener('wheel', handleWheel, { passive: false });
		return () => {
			container.removeEventListener('wheel', handleWheel);
		};
	}, [scale]);

	const imageStyle = {
		overflow: 'hidden',
		backgroundSize: `${scale * 100}%`,
		backgroundImage: `url(${profile?.image})`,
		backgroundPositionX: `${position.x}px`,
		backgroundPositionY: `${position.y}px`,
	};

	const holderStyle = {
		paddingBottom: `${parseInt(ratio * 100)}%`,
	};

	const onSave = () => {
		onChange({ ...(profile || {}), scale, posx: position.x, posy: position.y, ratio: ratio });
		onClose();
	};

	return (
		<div className="page w-full h-full overflow-hidden bg-black rounded-lg p-4 flex items-center">
			<div className="w-full relative" style={holderStyle}>
				<div
					ref={containerRef}
					style={imageStyle}
					className="klm absolute inset-0 w-full h-full bg-no-repeat"
					onTouchStart={handleTouchStart}
					onTouchMove={handleTouchMove}
					onTouchEnd={handleTouchEnd}
					onMouseDown={handleMouseDown}
					onMouseMove={handleMouseMove}
					onMouseUp={handleMouseUp}
					onMouseLeave={handleMouseUp}
				>&nbsp;</div>
			</div>
			<div className="bottons absolute py-3 top-0 left-0 w-full flex justify-between">
				<div className="flex mx-4 justify-start overflow-x-auto">
					{ratioArray.map((r, index) => (
						<div onClick={() => setRatio(r.value)} className="p-3 mr-5 rounded-full items-center bg-gray-100">
							{r.name}
						</div>
					))}
				</div>
				<div className="flex justify-end">
					<div onClick={onClose} className="p-3 mr-5 rounded-full items-center bg-gray-100">
						<X className="w-6 h-6 text-red-500" />
					</div>
					<div onClick={onSave} className="p-3 mr-5 rounded-full items-center bg-gray-100">
						<Save className="w-6 h-6 text-green-500" />
					</div>
				</div>
			</div>
		</div>
	);
};

const RegistroWizard = () => {
	// const args = useParams();
	const [step, setStep] = useState(1);
	const [errors, setErrors] = useState([]);
	const [formData, setFormData] = useState(testData.register || {});
	const location = useLocation();
	const formRef = useRef(null);
	const fetchData = usePost('/api/register');
	const { /* isLogin, */ setLogin } = useAppContexts();

	useEffect(() => {
		const query = location.search;
		const querys = new URLSearchParams(query);
		const name = querys.get('name') || '';
		const email = querys.get('email') || '';

		const newData = {};
		if (name) newData.name = name;
		if (email) newData.email = email;
		setFormData({ ...formData, ...newData });
	}, []);

	const handleChange = (e) => {
		if (e.target.name === 'hobbies') {
			if (formData.hobbies.length >= 3 && !formData.hobbies.includes(e.target.value)) {
				alert('Solo puedes seleccionar hasta 3 hobbies');
				return
			}

			const hobbies = e.target.checked
				? [...formData.hobbies, e.target.value]
				: formData.hobbies.filter((h) => h !== e.target.value);
			return setFormData(x => ({ ...x, hobbies }));
		}

		if (e.target.name === 'intereses') {
			const intereses = e.target.checked
				? [...formData.intereses, e.target.value]
				: formData.intereses.filter((h) => h !== e.target.value);
			return setFormData(x => ({ ...x, intereses }));
		}

		setFormData(x => ({ ...x, [e.target.name]: e.target.value }));
	};

	const nextStep = () => {
		if (formRef.current) {
			const is_ok = formRef.current.reportValidity()
			if (!is_ok) return;

			// const inputs = formRef.current.querySelectorAll('input, select');
			// const err = Array.from(inputs).filter((input) => !checkSubmut[input.name](input.value));
			// if (err.length > 0) {
			// 	console.error('Form Error:', err);
			// 	setErrors(err.map((input) => input.name));
			// 	setStep(1);
			// 	return;
			// }
		}

		setStep(step + 1);
	};

	const prevStep = () => setStep(step - 1);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch('/api/registro', {
				method: 'POST',
				headers: {
					"Accept": "application/json",
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData)
			});
			if (response.ok) {
				alert('Registro exitoso!');
			} else {
				throw new Error('Error en el registro');
			}
		} catch (error) {
			console.error('Error:', error);
			alert('Hubo un error en el registro');
		}
	};

	const submit = () => {
		// const err = Object.entries(checkSubmut).filter(([k, v]) => !v(formData[k]));
		// if (err.length > 0) {
		// 	console.error('Form Error:', err);
		// 	setErrors(err.map(([k, v]) => k));
		// 	setStep(1);
		// }
		fetchData.call(formData, data=>{
			if (!data.code && data.code !== 0) {
				alert('Problemas en el registro!');
			} else {
				alert('Registro exitoso!');
				setLogin(data.user.api_token);
			}
		}, false);
		console.log('Form Data:', formData);
	};

	const renderStep = [
		PageAccount,
		PagePersonInfo,
		PageInfoGenero,
		PageRedFlags,
		PageGreenFlags,
		PageHobbies,
		PagePreferencias,
		PageExtra,
		PageFotos,
		// PageVerification,
	]

	const Page = renderStep[step - 1];
	return (
		<div className="flex flex-col justify-center items-center p-8 w-full h-full bg-gray-100">
			<div className="bg-blue-200 w-full py-4 px-8 rounded-t-lg">
				<h1 className="text-2xl font-bold text-center">Register ({step}/{renderStep.length})</h1>
			</div>
			<div className="bg-white p-8 rounded-b-lg shadow-md w-full h-full overflow-auto flex flex-col justify-between">
				<form onSubmit={handleSubmit} ref={formRef} className="flex flex-col h-full overflow-auto ">
					<Page checkError={errors} data={formData} onChange={handleChange} onUpdata={setFormData} />
				</form>
				<div className="flex justify-between mt-6">
					{step > 1 && (
						<button
							type="button"
							onClick={prevStep}
							className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
						>
							Anterior
						</button>
					)}
					<div className="flex-grow"></div>
					{step < renderStep.length ? (
						<button
							type="button"
							onClick={nextStep}
							className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
						>
							Siguiente
						</button>
					) : (
						<button
							type="submit"
							onClick={submit}
							className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
						>
							Enviar
						</button>
					)}
				</div>
			</div>

		</div>
	);
};

export default RegistroWizard;