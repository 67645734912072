import React, { useState, useEffect, useCallback } from 'react';
import { HashRouter as Router, Route, Routes, Link, Navigate, useLocation, useBlocker } from 'react-router-dom';
import { Heart, HelpCircle, Bell, Settings as Settings1, Calendar, Zap, ThumbsUp, Vote, User } from 'lucide-react';

import {
	AppBar,
	Toolbar,
	Typography,
	IconButton,
	Box
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useAppContexts } from './AppContext';
import { useTableContexts } from './Context/TableContext';
import useFetch from './Helper/useFetch';
import { ToggleSwitch } from './Pages/Settings';


// Footer Icons
import EventIcon from '@mui/icons-material/Event';
import WhatshotIcon from '@mui/icons-material/Whatshot';
// import FavoriteIcon from '@mui/icons-material/Favorite';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// Pages Register
// import RegisterPage from './Pages/Register/First';

// Pages
import Profile from './Profile';
// import Filter from './Filter';
// import Encuentro from './Encuentro';
// import Edit from './Edit';

// import Events from './Pages/Events1';
// import Foryou from './Pages/Foryou1';
// import Liked from './Pages/Liked1';
// import Votos from './Pages/Votos1';
// import ProfileMe from './Pages/ProfileMe';

import Events from './Pages/Events/Events';
import Foryou from './Pages/Foryou/Foryou';
import Liked from './Pages/Liked/Liked';
import Votos from './Pages/Votes/Votes';
import ProfileMe from './Pages/ProfileMe';

import Help from './Pages/Help';
import EventDetails from './Pages/Events/EventDetails';
import Notify from './Pages/Notify';
import Settings from './Pages/Settings';
import Tutorial from './Pages/Tutorial';
import Login from './Pages/Register/Login';
import Wizard from './Pages/Register/Wizard';
import SplashScreen from './Pages/Register/Splash';
import Components from './Pages/Components';
import ProfileDetailsId from './Pages/ProfileDetailsId';
import Home from './Home';
import Home1 from './Home1';
import Home2 from './Home2';

// Style
import './MyStyle.css';
import OverPage from './Components/OverPage';


const Header = (props) => {
	const { StyleId } = useAppContexts();

	switch (StyleId) {
		case 1:
			return <Header1 {...props} />;
		case 2:
			return <Header2 {...props} />;
		default:
			return <Header1 {...props} />;
	}

};

const FooterMenu = (props) => {
	const { StyleId } = useAppContexts();

	switch (StyleId) {
		case 1:
			return <FooterMenu1 {...props} />;
		case 2:
			return <FooterMenu2 {...props} />;
		default:
			return <FooterMenu1 {...props} />;
	}

};

const Header1 = () => {
	const { toggleHelp, toggleNotify, toggleSetting, tutorial, StyleId, changeStyleId } = useAppContexts();

	const hasTutorial = tutorial != null;
	const handleStyle = () => {
		changeStyleId(StyleId === 1 ? 2 : 1);
	}

	return (
		<AppBar position="static" className='z-40'>
			<Toolbar>
				<Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}> <FavoriteIcon sx={{ mr: 1 }} /> </Box>
				<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}> App de Citas </Typography>
				<ToggleSwitch onChange={handleStyle} isChecked={StyleId == 2} > <HelpIcon /> </ToggleSwitch>
				{hasTutorial && <IconButton color="inherit" aria-label="help" onClick={toggleHelp}> <HelpIcon /> </IconButton>}
				<IconButton color="inherit" aria-label="notifications" onClick={toggleNotify}> <NotificationsIcon /> </IconButton>
				<IconButton color="inherit" aria-label="settings" onClick={toggleSetting}> <SettingsIcon /> </IconButton>
			</Toolbar>
		</AppBar>
	)
};

const MenuButton1 = ({ label, to, icon, page, onClick }) => {
	const active = page === to;

	return (
		<Link onClick={() => onClick(to)} aria-current="page" className={"mt-2 flex flex-col w-full items-center " + (active ? "text-blue-500" : "text-gray-500")} to={to}>
			{icon}
			<span className="text-xs my-1" style={{ overflow: "hidden", textWrap: "nowrap" }}>{label}</span>
			<span className=""></span>
		</Link>
	);
};

const FooterMenu1 = () => {
	const location = useLocation();
	const link = location.pathname;
	const page = link.split("?")[0];

	const { trans } = useAppContexts();
	const [selected, setSelected] = useState(page);
	useEffect(() => {
		setSelected(page);
	}
		, [page]);

	return (
		<div className="fixed flex bottom-0 left-0 right-0 z-40 bg-white" elevation={3}>
			<MenuButton1 page={selected} onClick={setSelected} label={trans("event.menu", "Events")} icon={<EventIcon />} to="/" />
			<MenuButton1 page={selected} onClick={setSelected} label={trans("foryou.menu", "For You")} icon={<WhatshotIcon />} to="/foryou" />
			<MenuButton1 page={selected} onClick={setSelected} label={trans("liked.menu", "Liked")} icon={<FavoriteIcon />} to="/liked" />
			<MenuButton1 page={selected} onClick={setSelected} label={trans("vote.menu", "Votos")} icon={<HowToVoteIcon />} to="/votos" />
			<MenuButton1 page={selected} onClick={setSelected} label={trans("profile.menu", "Profile")} icon={<AccountCircleIcon />} to="/profile" />
		</div>
	);
};


const Header2 = () => {
	const { toggleHelp, toggleNotify, toggleSetting, tutorial, StyleId, changeStyleId } = useAppContexts();

	const hasTutorial = tutorial != null;
	const handleStyle = () => {
		changeStyleId(StyleId === 1 ? 2 : 1);
	}

	return (
		<header className="fixed top-0 left-0 right-0 backdrop-blur-lg z-40 px-4 py-2" style={{backgroundColor: "#7861f4"}}>
			<div className="flex items-center justify-between">
				<div className="flex items-center">
					<Heart className="w-6 h-6 text-pink-500 mr-2" />
					<h1 className="text-xl font-bold text-white">App de Citas</h1>
				</div>
				<div className="flex items-center space-x-2">
					<ToggleSwitch onChange={handleStyle} isChecked={StyleId == 2} > <HelpIcon /> </ToggleSwitch>
					{hasTutorial && (
						<button onClick={toggleHelp} className="p-2 hover:bg-white hover:bg-opacity-20 rounded-full transition-colors">
							<HelpCircle className="w-6 h-6 text-white" />
						</button>
					)}
					<button onClick={toggleNotify} className="p-2 hover:bg-white hover:bg-opacity-20 rounded-full transition-colors">
						<Bell className="w-6 h-6 text-white" />
					</button>
					<button onClick={toggleSetting} className="p-2 hover:bg-white hover:bg-opacity-20 rounded-full transition-colors">
						<Settings1 className="w-6 h-6 text-white" />
					</button>
				</div>
			</div>
		</header>
	);
};

const MenuButton2 = ({ label, to, icon: Icon, page, onClick }) => {
	const active = page === to;

	return (
		<Link
			onClick={() => onClick(to)}
			aria-current="page"
			className={`flex flex-col items-center w-full py-1 ${active ? "text-pink-500" : "text-gray-400"}`}
			to={to}
		>
			<Icon className="w-6 h-6" />
			<span className="text-xs mt-1">{label}</span>
		</Link>
	);
};

const FooterMenu2 = () => {
	const location = useLocation();
	const page = location.pathname.split("?")[0];

	const { trans } = useAppContexts();
	const [selected, setSelected] = React.useState(page);

	React.useEffect(() => {
		setSelected(page);
	}, [page]);

	return (
		<nav className="fixed bottom-0 left-0 right-0 bg-white bg-opacity-10 backdrop-blur-lg z-40 px-2 py-1">
			<div className="flex justify-around">
				<MenuButton2 page={selected} onClick={setSelected} label={trans("event.menu", "Events")} icon={Calendar} to="/" />
				<MenuButton2 page={selected} onClick={setSelected} label={trans("foryou.menu", "For You")} icon={Zap} to="/foryou" />
				<MenuButton2 page={selected} onClick={setSelected} label={trans("liked.menu", "Liked")} icon={ThumbsUp} to="/liked" />
				<MenuButton2 page={selected} onClick={setSelected} label={trans("vote.menu", "Votos")} icon={Vote} to="/votos" />
				<MenuButton2 page={selected} onClick={setSelected} label={trans("profile.menu", "Profile")} icon={User} to="/profile" />
			</div>
		</nav>
	);
};

window.history.pushState(null, null, "#")
window.history.pushState(null, null, "#")
window.history.pushState(null, null, "#")

const App = () => {
	const { notify, help, settings, isLogin, /* setLogin */ } = useAppContexts();
	const { tables, useTable, useCreates } = useTableContexts();
	const ev = useTable("ev");
	useCreates(["me", "profiles", "votes", "liked", "events", "foryou", "hobbies"]);


	useEffect(() => {
		ev.load({ id: 1, name: "Evento 1" });
		ev.loads([
			{ id: 2, name: "Evento 2" },
			{ id: 3, name: "Evento 3" },
			{ id: 4, name: "Evento 4" },
		]);
	}, []);

	if (ev?.rows?.length > 0) {
		// console.log(ev.rows);
		// console.log("tables:", tables);
	}

	const [isLoading, setIsLoading] = useState(true);
	const [fetchData, setFetchData] = useState({
		inits: { loading: false, error: null, data: {} },
	});

	const { overPageCount, setOverPageCount } = useAppContexts();

	let shouldBlock = useCallback(
		({ currentLocation, nextLocation }) => {
			const nextOverPageCount = nextLocation.state?.overPage || false;
			// const currOverPageCount = currentLocation.state?.overPage || false;

			if (nextOverPageCount < overPageCount) {
				// goto back, close overpage
				setOverPageCount(x => x - 1);
				return false;
			}

			return false
		},
		[overPageCount]);

	let blocker = useBlocker(shouldBlock);

	useFetch('inits', setFetchData, '/api/mobile/inits');

	useEffect(() => {
		// Simula una carga de recursos
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 500); // Cambia esto por tu lógica real de carga
	}, [isLogin]);

	// if (isLoading) return <SplashScreen />

	if (!isLogin) {
		return (
			<Box sx={{ flexGrow: 1, height: 'calc(100vh - 62px)', display: 'flex', flexDirection: 'column' }}>
				<Box className="base-scroll relative" sx={{ flexGrow: 1, overflow: 'auto' }}>
					<Routes>
						<Route path="/" element={<Login />} />
						<Route path="/register" element={<Wizard />} />
						<Route path="/home" element={<Home />} />
						<Route path="/home1" element={<Home1 />} />
						<Route path="/home2" element={<Home2 />} />
						<Route path="*" element={<Navigate to="/" replace />} />
					</Routes>
				</Box>
			</Box>
		);
	}

	return (
		<Box sx={{ flexGrow: 1, height: 'calc(100vh - 62px)', display: 'flex', flexDirection: 'column' }}>
			<Header />

			<Box className="base-scroll relative" sx={{ flexGrow: 1, overflow: 'auto' }}>
				<Routes>
					{/* <Route path="/" element={<OverPage events={events} hobbies={hobbies} />} /> */}
					<Route path="/" element={<Events />} />
					<Route path="/home" element={<Home />} />
					<Route path="/help" element={<Help />} />
					<Route path="/foryou1" element={<Profile />} />
					<Route path="/foryou" element={<Foryou showFilter={true} />} />
					<Route path="/liked" element={<Liked />} />
					<Route path="/votos" element={<Votos />} />
					<Route path="/profile" element={<ProfileMe showEdit={true} />} />
					<Route path="/register" element={<Wizard />} />
					<Route path="/event/:id" element={<EventDetails />} />
					<Route path="/profile/:id" element={<ProfileDetailsId />} />

					<Route path="/cc" element={<Components />} />
				</Routes>
			</Box>

			<FooterMenu />

			<Notify show={notify} />
			<Settings show={settings} />
			<Tutorial show={help} />
		</Box>
	);
};

export default App;