import React from 'react';
import { Bell, Image } from 'lucide-react';

const notificaciones = [
	{
		id: 1,
		leida: false,
		icono: 'imagen',
		titulo: 'Nueva actualización disponible',
		descripcion: 'Se ha lanzado la versión 2.0 de la aplicación. ¡Actualiza ahora!',
		fecha: '2024-08-24T10:30:00'
	},
	{
		id: 2,
		leida: true,
		icono: 'campana',
		titulo: 'Recordatorio de evento',
		descripcion: 'Tu evento "Reunión de equipo" comienza en 1 hora.',
		fecha: '2024-08-24T09:00:00'
	},
	{
		id: 3,
		leida: false,
		icono: 'imagen',
		titulo: 'Nuevo mensaje',
		descripcion: 'Has recibido un nuevo mensaje de Juan Pérez.',
		fecha: '2024-08-23T18:45:00'
	},
	// Añade más notificaciones según sea necesario
];

const formatearFecha = (fecha) => {
	return new Date(fecha).toLocaleString('es-ES', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit'
	});
};

const NotificacionItem = ({ notificacion }) => {
	return (
		<div className={`flex items-start p-4 border-b ${notificacion.leida ? 'bg-white' : 'bg-blue-50'}`}>
			<div className="flex-shrink-0 mr-4">
				{notificacion.icono === 'imagen' ? (
					<Image className="w-10 h-10 text-blue-500" />
				) : (
					<Bell className="w-10 h-10 text-blue-500" />
				)}
			</div>
			<div className="flex-grow">
				<h3 className="text-lg font-semibold">{notificacion.titulo}</h3>
				<p className="text-gray-600">{notificacion.descripcion}</p>
				<p className="text-sm text-gray-500 mt-1">{formatearFecha(notificacion.fecha)}</p>
			</div>
		</div>
	);
};

const NotifyStyle = {
	position: "absolute",
	height: "calc(100% - 60px)",
	background: "#fff",
	inset: 0,
	top: "60px",
	overflow: "hidden",
	// zIndex: 2,
	startingStyle: {
		transform: "ScaleY(0)",
	},
}
const PaginaNotificaciones = ({ show }) => {
	return (
		<div style={NotifyStyle} className={"max-w-2xl mx-auto scale-y z-30" + (show ? " show" : "")}>
			<h1 className="bg-blue-200 text-3xl font-bold px-8 py-4 mb-1 border-b">Notificaciones</h1>
			<div className="bg-white shadow-md rounded-lg overflow-auto">
				{notificaciones.map((notificacion) => (
					<NotificacionItem key={notificacion.id} notificacion={notificacion} />
				))}
			</div>
		</div>
	);
};

export default PaginaNotificaciones;