import React, { useEffect, useState } from 'react';
import { Users, Briefcase, Ruler, GraduationCap, /* Coffee, */ Wine, Calendar, Cigarette, Beer, /* Dog, Cat,  */ThumbsUp, ThumbsDown, Flag } from 'lucide-react';
import ApiUrl from '../Helper/ApiUrl';
import useFetch from '../Helper/useFetch';
import { useTableContexts } from '../Context/TableContext';

const PhotoWithReactions = ({ className = "", children, big, photo, ...props }) => {
	const [likes, setLikes] = useState(0);

	if (photo == null) return null;

	const habdleLike = () => {
		setLikes(x => x === 1 ? 0 : 1);
	};

	const handleDislike = () => {
		setLikes(x => x === -1 ? 0 : -1);
	};

	const likedClass = (likes === 1) ? " text-blue-500" : "";
	const dislikedClass = (likes === -1) ? " text-red-500" : "";
	const imgURL = (photo?.image + "").startsWith("/server/x") ? photo.image : `${ApiUrl}/${photo.image}`;
	return (
		<div className={className + " relative" + (big ? " -mx-7 relative" : "")} {...props}>
			<img src={imgURL} alt={photo.alt || photo.title} loading="lazy" className="w-full max-h-96 object-cover rounded-lg" />
			<div className="absolute flex items-end justify-between p-3 top-0 left-0 text-white w-full h-full" style={{ background: "linear-gradient(0deg, #000000b3 20%, transparent 40%)" }}>
				<h3 className="flex items-center text-3x1 font-semibold mt-2">
					{(!children && photo.title) && photo.title}
					{children}
				</h3>
				<div className="flex justify-end mt-2">
					<button onClick={habdleLike} className={"flex items-center mr-4" + likedClass}>
						<ThumbsUp className="mr-1" size={20} />
					</button>
					<button onClick={handleDislike} className={"flex items-center" + dislikedClass}>
						<ThumbsDown className="mr-1" size={20} />
					</button>
				</div>
			</div>
		</div>
	);
};

const FlagSection = ({ flags, type }) => {
	return (
		<div className={`mb-4 p-3 rounded-lg ${type === 'red' ? 'bg-red-100' : 'bg-green-100'}`}>
			<h3 className={`text-lg font-semibold mb-2 flex items-center ${type === 'red' ? 'text-red-600' : 'text-green-600'}`}>
				<Flag className="mr-2" size={20} />
				{type === 'red' ? 'Red Flags' : 'Green Flags (check for +points)'}
			</h3>
			<ul className="list-disc pl-5">
				{flags.map((flag, index) => (
					<li key={index} className={type === 'red' ? 'text-red-700' : 'text-green-700'}>
						<label >{type === "green" ? <input type="checkbox" /> : null}{" " + flag}</label>
					</li>
				))}
			</ul>
		</div>
	);
};

const ProfileOnly = ({ profile, className, imgCounts = 3 }) => {
	const { useData, useTable } = useTableContexts();
	const [reactions, setReactions] = useState({});
	const profiles = useData('profiles');
	const table = useTable('profiles');
	const profile1 = profiles.find(x => x.id === profile.id) || {};
	const [fetchData, setFetchData] = React.useState({ profile: { loading: false, error: null, data: profile1 } });
	const profile_data = fetchData.profile.data;

	useEffect(() => {
		if (profile1.full) return;
		if (!fetchData.profile.data) return;
		if (fetchData.profile.loading) return;
		table.load(fetchData.profile.data);
	}, [profile1, fetchData]);

	useFetch('profile', setFetchData, '/api/mobile/profile/' + profile.id, undefined, {}, !profile1.full);

	const handleReaction = (id, isPositive) => {
		setReactions(prev => ({
			...prev,
			[id]: isPositive ? 'positive' : 'negative'
		}));
	};

	const otherImages = [];
	const images = Array.isArray(profile_data.images) ? profile_data.images : [];
	if (images.length === 0) {
		images.push({ image: profile_data.image, title: 'Placeholder' });
	}

	if (images.length > imgCounts) otherImages.push(...images.slice(imgCounts));

	if (images.length < imgCounts) {
		images.push(null);
		images.push(null);
		images.push(null);
	}

	if (!Array.isArray(profile_data.redFlags)) profile_data.redFlags = [];
	if (!Array.isArray(profile_data.greenFlags)) profile_data.greenFlags = [];
	if (!Array.isArray(profile_data.characteristics)) profile_data.characteristics = [];
	if (!Array.isArray(profile_data.randomQuestions)) profile_data.randomQuestions = [];

	const children = profile_data.has_children;
	const smoking = profile_data.smoking && profile_data.smoking !== "No fumador";
	const drinking = profile_data.drinking && profile_data.drinking !== "No bebe";

	return (
		<div className={"p-7 " + className}>
			<PhotoWithReactions className="user-info flex text-3x1" big={1} photo={images[0]} >
				<div className="flex items-center mr-6">
					<Calendar size={28} className={`mr-2 text-green-500`} />{profile_data.age}
				</div>
				<div className="flex items-center mr-2">
					<Users size={28} className={`mr-1 ${children ? 'text-green-500' : 'text-gray-400'}`} />
				</div>
				<div className="flex items-center mr-2">
					<Cigarette size={28} className={`mr-1 ${smoking ? 'text-green-500' : 'text-gray-400'}`} />
				</div>
				<div className="flex items-center">
					<Beer size={28} className={`mr-1 ${drinking ? 'text-green-500' : 'text-gray-400'}`} />
				</div>
			</PhotoWithReactions>

			<div className="p-4">
				{/* <PhotoWithReactions big={1} photo={images[0]} /> */}
				<section className="mb-6">
					<h2 className="text-xl font-semibold mb-2">Sobre mí</h2>
					<p>{profile_data.bio}</p>
				</section>

				{/* <PhotoWithReactions photo={images[1]} /> */}
				<section className="mb-6">
					<h2 className="text-xl font-semibold mb-2">Preferencias y Desagrados</h2>
					<FlagSection flags={profile_data.redFlags} type="red" />
					<FlagSection flags={profile_data.greenFlags} type="green" />
				</section>
				<section className="mb-6">
					<h2 className="text-xl font-semibold mb-2">Detalles</h2>
					<div className="grid grid-cols-2 gap-4">

						<div className="flex items-center">
							<Ruler className="mr-2 text-gray-500" size={20} />
							<span>{profile_data.height} cm</span>
						</div>

						<div className="flex items-center">
							<Briefcase className="mr-2 text-gray-500" size={20} />
							<span>{profile_data.employment}</span>
						</div>

						<div className="flex items-center">
							<GraduationCap className="mr-2 text-gray-500" size={20} />
							<span>{profile_data.education}</span>
						</div>

						{/* <div className="flex items-center">
							<Dog className="mr-2 text-gray-500" size={20} />
							<span>{profile_data.details.pets.dogs ? 'Tiene perro' : 'No tiene perro'}</span>
						</div>

						<div className="flex items-center">
							<Cat className="mr-2 text-gray-500" size={20} />
							<span>{profile_data.details.pets.cats ? 'Tiene gato' : 'No tiene gato'}</span>
						</div> */}

						{/* <div className="flex items-center">
							<Coffee className="mr-2 text-gray-500" size={20} />
							<span>{profile_data.details.coffee ? 'Toma café' : 'No toma café'}</span>
						</div> */}

					</div>
				</section>

				<section className="mb-6">
					<h2 className="text-xl font-semibold mb-2">Características</h2>
					<div className="space-y-2">
						{profile_data.characteristics.map(char => (
							<div key={char.id} className="flex items-center justify-between bg-gray-100 p-2 rounded">
								<span>{char.text}</span>
								<div>
									<button
										onClick={() => handleReaction(char.id, true)}
										className={`mr-2 p-1 rounded ${reactions[char.id] === 'positive' ? 'bg-green-500 text-white' : 'bg-gray-200'}`}
									>
										👍
									</button>
									<button
										onClick={() => handleReaction(char.id, false)}
										className={`p-1 rounded ${reactions[char.id] === 'negative' ? 'bg-red-500 text-white' : 'bg-gray-200'}`}
									>
										👎
									</button>
								</div>
							</div>
						))}
					</div>
				</section>

				<PhotoWithReactions photo={images[2]} />

				<section className="mb-6">
					<h2 className="text-xl font-semibold mb-2">Preguntas Random</h2>
					<div className="space-y-4">
						{profile_data.randomQuestions.map((q, index) => (
							<div key={index}>
								<p className="font-semibold">{q.question}</p>
								<p className="text-gray-600">{q.answer}</p>
							</div>
						))}
					</div>
				</section>

				{otherImages.map((photo, index) => (
					<PhotoWithReactions key={index} photo={photo} />
				))}
			</div>

		</div>
	);
};

export default ProfileOnly;