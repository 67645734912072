import React from 'react';
import { MapPin, Edit, Filter } from 'lucide-react';
import ProfileOnly from './ProfileOnly';
import { useTableContexts } from '../Context/TableContext';

const ProfileMe = ({showEdit, showFilter}) => {
	const { useData } = useTableContexts();
	const me = useData('me')[0] || {};
	const profiles = useData('profiles');
	const profile = profiles.find(profile => profile.id === me.id) || {};

	return (
		<div className="my-8">
			<ProfileOnly profile={profile} />

			<div className="fixed top-14 left-0 right-0 flex justify-between items-center px-4 py-2 bg-white border-t border-gray-200" style={{ boxShadow: "0px -10px 20px 0px #000" }}>
				<h1 className="text-3xl font-bold">{profile.name}</h1>
				<p className="flex items-center text-gray-600">
					<MapPin className="mr-2" size={16} />{profile.location}

					{showEdit && <Edit className="text-blue-500 ml-4 mr-2" size={24} />}
					{showFilter && <Filter className="text-blue-500 ml-4 mr-2" size={24} />}
				</p>
			</div>
		</div>
	);
};

export default ProfileMe;