import React, { useState } from 'react';
import { Shield, Flag, ThumbsUp, ThumbsDown } from 'lucide-react';
import Header from '../../Components/Header';
import { useAppContexts } from '../../AppContext';
import { useTableContexts } from '../../Context/TableContext';

const CharacteristicVote = ({ characteristic, onVote }) => {
	const [vote, setVote] = useState(null);

	const handleVote = (value) => {
		setVote(value);
		onVote(characteristic, value);
	};

	return (
		<div className="mb-4 bg-white bg-opacity-20 backdrop-blur-lg rounded-lg p-4">
			<h3 className="text-lg font-semibold mb-2">{characteristic}</h3>
			<div className="flex justify-between">
				<button
					onClick={() => handleVote('positive')}
					className={`flex items-center px-4 py-2 rounded-full ${vote === 'positive' ? 'bg-green-500 text-white' : 'bg-white bg-opacity-30 text-white'
						}`}
				>
					<ThumbsUp className="w-4 h-4 mr-2" />
					Positivo
				</button>
				<button
					onClick={() => handleVote('negative')}
					className={`flex items-center px-4 py-2 rounded-full ${vote === 'negative' ? 'bg-red-500 text-white' : 'bg-white bg-opacity-30 text-white'
						}`}
				>
					<ThumbsDown className="w-4 h-4 mr-2" />
					Negativo
				</button>
			</div>
		</div>
	);
};

const ProfileVote = ({ profile = {}, closeVote }) => {
	const { trans } = useAppContexts();
	const [votes, setVotes] = useState({});
	const [opinion, setOpinion] = useState('');

	const { useData } = useTableContexts();
	const profiles = useData('votes');

	const characteristics = [
		"Puntualidad",
		"Comunicación",
		"Amabilidad",
		"Honestidad",
		"Sentido del humor"
	];

	const handleCharacteristicVote = (characteristic, value) => {
		setVotes(prevVotes => ({
			...prevVotes,
			[characteristic]: value
		}));
	};

	const handleSubmit = () => {
		console.log("Votación enviada:", { votes, opinion });
		// Aquí iría la lógica para enviar la votación al backend
		closeVote();
	};

	const handleBlock = () => {
		console.log("Usuario bloqueado");
		// Aquí iría la lógica para bloquear al usuario
		closeVote();
	};

	const handleReport = () => {
		console.log("Usuario reportado");
		// Aquí iría la lógica para reportar al usuario
		closeVote();
	};

	return (
		<div className="min-h-screen pt-16 bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 text-white p-4">
			<Header back={closeVote} title={trans("vote.title", `Vota a ${profile.name}`)} />

			<div className="mb-6 bg-white bg-opacity-20 backdrop-blur-lg rounded-lg p-6">
				<div className="flex items-center mb-4">
					<img
						src={profile.image}
						alt={profile.name}
						loading="lazy"
						className="w-16 h-16 rounded-full mr-4 object-cover"
						onError={(e) => {
							e.target.onerror = null;
							e.target.src = 'https://via.placeholder.com/150';
						}}
					/>
					<div>
						<h2 className="text-xl font-semibold">{profile.name}</h2>
						<p className="text-gray-200">{profile.age} años</p>
					</div>
				</div>

				<p className="text-sm text-gray-200 mb-4">
					Evalúa a {profile.name} basándote en tu experiencia durante el evento. Tus votos son anónimos y ayudan a mejorar la comunidad.
				</p>

				{characteristics.map(characteristic => (
					<CharacteristicVote
						key={characteristic}
						characteristic={characteristic}
						onVote={handleCharacteristicVote}
					/>
				))}

				<div className="mb-4">
					<label className="block text-sm font-medium mb-2">
						Describe a {profile.name} en tres palabras (opcional):
					</label>
					<input
						value={opinion}
						onChange={(e) => setOpinion(e.target.value)}
						placeholder="Ej: Amable, divertido, interesante"
						className="w-full p-2 bg-white bg-opacity-30 rounded-md text-white placeholder-gray-300"
					/>
				</div>

				<button
					onClick={handleSubmit}
					className="w-full bg-green-500 text-white py-2 rounded-md hover:bg-green-600 transition-colors mb-4"
				>
					Enviar Votación
				</button>
			</div>

			<div className="bg-white bg-opacity-20 backdrop-blur-lg rounded-lg p-4 mb-6">
				<p className="text-sm text-gray-200 mb-2">
					Si este usuario te hace sentir incómodo/a, puedes bloquearlo o reportarlo.
				</p>
			</div>

			<div className="flex justify-between">
				<button
					onClick={handleBlock}
					className="flex items-center px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors"
				>
					<Shield className="w-4 h-4 mr-2" />
					Bloquear
				</button>
				<button
					onClick={handleReport}
					className="flex items-center px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
				>
					<Flag className="w-4 h-4 mr-2" />
					Reportar
				</button>
			</div>
		</div>
	);
};

export default ProfileVote;