import { useAppContexts } from '../../AppContext';
import ProfileVote1 from './ProfileVote1';
import ProfileVote2 from './ProfileVote2';

const ProfileVote = (props) => {
	const { StyleId } = useAppContexts();
	switch (StyleId) {
		case 1:
			return <ProfileVote1 {...props} />;
		case 2:
			return <ProfileVote2 {...props} />;
		default:
			return <ProfileVote1 {...props} />;
	}
};

export default ProfileVote;