import React, { useState, useEffect } from 'react';
import { useAppContexts } from '../../AppContext';
import { useTableContexts } from '../../Context/TableContext';
import Liked1 from './Liked1';
import Liked2 from './Liked2';

const Liked = (props) => {
	const data = {...props};
	const { StyleId } = useAppContexts();
	const EventDOM = [Liked1, Liked2][StyleId - 1] || Liked1;

	const { useData } = useTableContexts();
	const liked = useData('liked');
	const profiles = useData('profiles');

	const [showFilter, setShowFilter] = useState();
	const [showData, setShowData] = useState([]);
	const [showProfile, setShowProfile] = useState(0);
	const [activeTab, setActiveTab] = useState('me-gusta');

	useEffect(() => {
		const my_likes = liked.filter(x=>x.like).map(x=>x.id);
		const its_likes = liked.filter(x=>x.him).map(x=>x.id);

		if (activeTab === 'me-gusta') setShowData(profiles.filter(x => my_likes.includes(x.id)));
		if (activeTab === 'le-gusto') setShowData(profiles.filter(x => its_likes.includes(x.id)));
		if (activeTab === 'match') setShowData(profiles.filter(x => my_likes.includes(x.id) && its_likes.includes(x.id)));
	}, [activeTab, liked, profiles]);

	// useStates
	data.showFilter = showFilter;
	data.activeTab = activeTab;
	data.setShowFilter = setShowFilter;
	data.setActiveTab = setActiveTab;
	data.showData = showData;
	data.setShowData = setShowData;
	data.showProfile = showProfile;
	data.setShowProfile = setShowProfile;

	// Component Data
	data.liked = liked;
	data.profiles = profiles;

	data.data = activeTab==="attend" ? liked : profiles;

	return <EventDOM key={activeTab} {...data} />;

};

export default Liked;