import React, { useState } from "react";
import {
	Heart,
	X,
	MapPin,
	Users,
	Briefcase,
	Ruler,
	GraduationCap,
	Coffee,
	Wine,
	Cigarette,
	Dog,
	Cat,
	ThumbsUp,
	ThumbsDown,
	Flag,
	Filter,
	ChevronDown,
	ChevronUp,
	// ChevronRight,
	Shield,
} from "lucide-react";
// import { Filter, Users } from 'lucide-react';
import { NavLink } from "react-router-dom";
// import { useAppContexts } from "../AppContext"; // Asegúrate de que esta importación sea correcta

// Componente PhotoCarousel
const PhotoCarousel = ({ photos }) => {
	const [currentPhoto, setCurrentPhoto] = useState(0);

	const nextPhoto = () => {
		setCurrentPhoto((prev) => (prev + 1) % photos.length);
	};

	const prevPhoto = () => {
		setCurrentPhoto((prev) => (prev - 1 + photos.length) % photos.length);
	};

	return (
		<div className="relative h-96">
			<img
				src={photos[currentPhoto]}
				alt={`Foto ${currentPhoto + 1}`}
				className="w-full h-full object-cover"
			/>
			<button
				onClick={prevPhoto}
				className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
			>
				&lt;
			</button>
			<button
				onClick={nextPhoto}
				className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
			>
				&gt;
			</button>
			<div className="absolute bottom-4 left-0 right-0 flex justify-center">
				{photos.map((_, index) => (
					<div
						key={index}
						className={`h-2 w-2 rounded-full mx-1 ${index === currentPhoto ? "bg-white" : "bg-gray-300"
							}`}
					/>
				))}
			</div>
		</div>
	);
};

// Componente PhotoWithReactions
const PhotoWithReactions = ({ photo, title }) => {
	const [likes, setLikes] = useState(0);
	const [dislikes, setDislikes] = useState(0);

	return (
		<div className="mb-6">
			<img
				src={photo.url}
				alt={photo.alt || title}
				className="w-full h-64 object-cover rounded-lg"
			/>
			{title && <h3 className="text-lg font-semibold mt-2">{title}</h3>}
			<div className="flex justify-end mt-2">
				<button
					onClick={() => setLikes(likes + 1)}
					className="flex items-center mr-4"
				>
					<ThumbsUp className="mr-1" size={20} />
					<span>{likes}</span>
				</button>
				<button
					onClick={() => setDislikes(dislikes + 1)}
					className="flex items-center"
				>
					<ThumbsDown className="mr-1" size={20} />
					<span>{dislikes}</span>
				</button>
			</div>
		</div>
	);
};

// Componente FlagSection
const FlagSection = ({ flags, type }) => {
	return (
		<div
			className={`mb-4 p-3 rounded-lg ${type === "red" ? "bg-red-100" : "bg-green-100"
				}`}
		>
			<h3
				className={`text-lg font-semibold mb-2 flex items-center ${type === "red" ? "text-red-600" : "text-green-600"
					}`}
			>
				<Flag className="mr-2" size={20} />
				{type === "red" ? "Red Flags" : "Green Flags"}
			</h3>
			<ul className="list-disc pl-5">
				{flags.map((flag, index) => (
					<li
						key={index}
						className={type === "red" ? "text-red-700" : "text-green-700"}
					>
						{flag}
					</li>
				))}
			</ul>
		</div>
	);
};

// Componente UserDetailsCard
const UserDetailsCard = ({ details }) => {
	return (
		<div className="grid grid-cols-2 gap-4">
			<div className="flex items-center">
				<Ruler className="mr-2 text-gray-500" size={20} />
				<span>{details.height} cm</span>
			</div>
			<div className="flex items-center">
				<Briefcase className="mr-2 text-gray-500" size={20} />
				<span>{details.job}</span>
			</div>
			<div className="flex items-center">
				<GraduationCap className="mr-2 text-gray-500" size={20} />
				<span>{details.education}</span>
			</div>
			<div className="flex items-center">
				<Users className="mr-2 text-gray-500" size={20} />
				<span>{details.children ? "Tiene hijos" : "No tiene hijos"}</span>
			</div>
			<div className="flex items-center">
				<Dog className="mr-2 text-gray-500" size={20} />
				<span>{details.pets.dogs ? "Tiene perro" : "No tiene perro"}</span>
			</div>
			<div className="flex items-center">
				<Cat className="mr-2 text-gray-500" size={20} />
				<span>{details.pets.cats ? "Tiene gato" : "No tiene gato"}</span>
			</div>
			<div className="flex items-center">
				<Cigarette className="mr-2 text-gray-500" size={20} />
				<span>{details.smoke ? "Fuma" : "No fuma"}</span>
			</div>
			<div className="flex items-center">
				<Wine className="mr-2 text-gray-500" size={20} />
				<span>{details.drink}</span>
			</div>
			<div className="flex items-center">
				<Coffee className="mr-2 text-gray-500" size={20} />
				<span>{details.coffee ? "Toma café" : "No toma café"}</span>
			</div>
		</div>
	);
};

// Componente CharacteristicsList
const CharacteristicsList = ({
	characteristics,
	reactions,
	handleReaction,
}) => {
	return (
		<div className="space-y-2">
			{characteristics.map((char) => (
				<div
					key={char.id}
					className="flex items-center justify-between bg-gray-100 p-2 rounded"
				>
					<span>{char.text}</span>
					<div>
						<button
							onClick={() => handleReaction(char.id, true)}
							className={`mr-2 p-1 rounded ${reactions[char.id] === "positive"
								? "bg-green-500 text-white"
								: "bg-gray-200"
								}`}
						>
							👍
						</button>
						<button
							onClick={() => handleReaction(char.id, false)}
							className={`p-1 rounded ${reactions[char.id] === "negative"
								? "bg-red-500 text-white"
								: "bg-gray-200"
								}`}
						>
							👎
						</button>
					</div>
				</div>
			))}
		</div>
	);
};

// Componente RandomQuestions
const RandomQuestions = ({ questions }) => {
	return (
		<div className="space-y-4">
			{questions.map((q, index) => (
				<div key={index}>
					<p className="font-semibold">{q.question}</p>
					<p className="text-gray-600">{q.answer}</p>
				</div>
			))}
		</div>
	);
};

// Componente ActionButtons
const ActionButtons = ({ onLike, onDislike }) => {
	return (
		<div className="fixed bottom-0 left-0 right-0 flex justify-around p-4 bg-white border-t border-gray-200">
			<button
				onClick={onDislike}
				className="flex-1 mr-2 py-2 bg-gray-500 text-white rounded-full flex items-center justify-center"
			>
				<X className="mr-2" /> No me interesa
			</button>
			<button
				onClick={onLike}
				className="flex-1 ml-2 py-2 bg-red-500 text-white rounded-full flex items-center justify-center"
			>
				<Heart className="mr-2" /> Me gusta
			</button>
		</div>
	);
};

// Componente FilterOverlay
const FilterOverlay = ({ isOpen, onClose }) => {
	const [filterState, setFilterState] = useState({
		timeRange: "this-week",
		startDate: "",
		endDate: "",
		minPeople: "",
		maxPeople: "",
		eventType: [],
	});

	// const eventTypes = [
	// 	"Ciclismo",
	// 	"Karaoke",
	// 	"Senderismo",
	// 	"Cenas",
	// 	"Deportes",
	// 	"Arte",
	// 	"Música",
	// 	"Cocina",
	// 	"Viajes",
	// 	"Lectura",
	// 	"Cine",
	// 	"Teatro",
	// 	"Danza",
	// 	"Yoga",
	// 	"Meditación",
	// ];

	// const handleInputChange = (e) => {
	// 	const { name, value } = e.target;
	// 	setFilterState((prev) => ({ ...prev, [name]: value }));
	// };

	// const handleEventTypeToggle = (type) => {
	// 	setFilterState((prev) => ({
	// 		...prev,
	// 		eventType: prev.eventType.includes(type)
	// 			? prev.eventType.filter((t) => t !== type)
	// 			: [...prev.eventType, type],
	// 	}));
	// };

	const handleApply = () => {
		console.log("Aplicando filtros:", filterState);
		onClose();
	};

	const handleClear = () => {
		setFilterState({
			timeRange: "this-week",
			startDate: "",
			endDate: "",
			minPeople: "",
			maxPeople: "",
			eventType: [],
		});
	};

	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 bg-white z-30 overflow-y-auto">
			<div className="container mx-auto p-4">
				<div className="flex justify-between items-center mb-6">
					<h2 className="text-2xl font-bold">Filtrar Eventos</h2>
					<button
						onClick={onClose}
						className="p-2 rounded-full hover:bg-gray-200"
					>
						<X className="w-6 h-6" />
					</button>
				</div>

				{/* Contenido del filtro */}
				{/* ... (Aquí iría el contenido del formulario de filtro) ... */}

				<div className="mt-8 flex justify-end space-x-4">
					<button
						onClick={handleClear}
						className="px-6 py-2 bg-gray-200 rounded-lg hover:bg-gray-300 transition-colors"
					>
						Limpiar
					</button>
					<button
						onClick={handleApply}
						className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
					>
						Aplicar
					</button>
				</div>
			</div>
		</div>
	);
};

// Componente EventCard
const EventCard = ({ event }) => {
	return (
		<div className="bg-white shadow-md rounded-lg p-4 mb-4">
			<img
				src={event.image}
				alt={event.name}
				className="w-full h-48 object-cover rounded-lg mb-2"
			/>
			<h3 className="text-xl font-semibold">{event.name}</h3>
			<p className="text-gray-600">
				{event.user}/{event.total} participantes
			</p>
			<p className="text-gray-600">
				{event.dia}-{event.mes} {event.hora}
			</p>
		</div>
	);
};

// Componente UserProfile (Página principal de perfil de usuario)
const UserProfile = ({ user }) => {
	const [reactions, setReactions] = useState({});

	const handleReaction = (id, isPositive) => {
		setReactions((prev) => ({
			...prev,
			[id]: isPositive ? "positive" : "negative",
		}));
	};

	const handleLike = () => {
		console.log("Like");
		// Aquí iría la lógica para dar like
	};

	const handleDislike = () => {
		console.log("No me interesa");
		// Aquí iría la lógica para no mostrar interés
	};

	return (
		<div className="pb-20">
			<PhotoWithReactions photo={user.photos[0]} title={user.photos[0].title} />

			<div className="p-4">
				<div className="mb-4">
					<h1 className="text-3xl font-bold">
						{user.name}, {user.age}
					</h1>
					<p className="flex items-center text-gray-600">
						<MapPin className="mr-2" size={16} />
						{user.location}
					</p>
				</div>

				<section className="mb-6">
					<h2 className="text-xl font-semibold mb-2">Sobre mí</h2>
					<p>{user.bio}</p>
				</section>

				<PhotoWithReactions
					photo={user.photos[1]}
					title={user.photos[1].title}
				/>

				<section className="mb-6">
					<h2 className="text-xl font-semibold mb-2">Detalles</h2>
					<UserDetailsCard details={user.details} />
				</section>

				<section className="mb-6">
					<h2 className="text-xl font-semibold mb-2">Características</h2>
					<CharacteristicsList
						characteristics={user.characteristics}
						reactions={reactions}
						handleReaction={handleReaction}
					/>
				</section>

				<PhotoWithReactions
					photo={user.photos[2]}
					title={user.photos[2].title}
				/>

				<section className="mb-6">
					<h2 className="text-xl font-semibold mb-2">Preguntas Random</h2>
					<RandomQuestions questions={user.randomQuestions} />
				</section>

				<section className="mb-6">
					<h2 className="text-xl font-semibold mb-2">
						Preferencias y Desagrados
					</h2>
					<FlagSection flags={user.redFlags} type="red" />
					<FlagSection flags={user.greenFlags} type="green" />
				</section>

				<PhotoWithReactions
					photo={user.photos[3]}
					title={user.photos[3].title}
				/>
			</div>

			<ActionButtons onLike={handleLike} onDislike={handleDislike} />
		</div>
	);
};

// const tutorialSteps = {};
const EventListPage = ({ events }) => {
	// const { trans, setTutorial } = useAppContexts();
	const [showFilter, setShowFilter] = useState(false);

	// useEffect(() => {
	// 	setTutorial(tutorialSteps);
	// 	return () => setTutorial(null);
	// }, [setTutorial]);

	return (
		<div className="container mx-auto p-4">
			<div className="flex justify-between items-center mb-6">
				<h1 className="text-2xl font-bold">
					{/* {trans("event.this-week", "Eventos esta semana")} */}
					"Eventos esta semana"
				</h1>
				<button
					className="filter-btn p-3 rounded-full transition-colors hover:bg-gray-200"
					aria-label="Filtrar"
					onClick={() => setShowFilter(true)}
				>
					<Filter className="w-6 h-6" />
				</button>
			</div>
			<div className="event-list grid grid-cols-1 sm:grid-cols-2 gap-4">
				{events.map((event, index) => (
					<EventCard key={index} event={event} />
				))}
			</div>
			<FilterOverlay isOpen={showFilter} onClose={() => setShowFilter(false)} />
		</div>
	);
};

// Componente EventCard (versión mejorada)
const EventCard1 = ({ event }) => {
	return (
		<NavLink
			to={`/event/${event.id}`}
			className="bg-white shadow-md rounded-lg overflow-hidden"
		>
			<div className="relative h-48">
				<img
					src={event.image}
					alt={event.name}
					className="w-full h-full object-cover"
				/>
				<div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
					<h3 className="text-xl font-semibold text-white">{event.name}</h3>
				</div>
			</div>
			<div className="p-4">
				<div className="flex justify-between items-center">
					<p className="text-gray-600 flex items-center">
						<Users className="mr-2" size={16} />
						{event.user}/{event.total}
					</p>
					<p className="text-gray-600">
						{event.dia} {event.mes}, {event.hora}
					</p>
				</div>
			</div>
		</NavLink>
	);
};

// Componente VotacionUsuario
const VotacionUsuario = () => {
	const [opinion, setOpinion] = useState("");

	const usuario = {
		nombre: "Laura",
		edad: 28,
		foto: "/ruta/a/la/foto.jpg", // Asegúrate de tener una ruta válida para la foto
	};

	const handleVotar = () => {
		console.log("Votación enviada:", opinion);
		// Aquí iría la lógica para enviar la votación
	};

	const handleBlock = () => {
		console.log("Usuario bloqueado");
		// Aquí iría la lógica para bloquear al usuario
	};

	const handleReport = () => {
		console.log("Usuario reportado");
		// Aquí iría la lógica para reportar al usuario
	};

	return (
		<div className="container mx-auto p-4 max-w-lg">
			<h1 className="text-2xl font-bold mb-6 text-center">
				Vota a este usuario
			</h1>

			<div className="bg-white shadow-md rounded-lg p-6 mb-6">
				<div className="flex items-center mb-4">
					<img
						src={usuario.foto}
						alt={usuario.nombre}
						className="w-16 h-16 rounded-full mr-4 object-cover"
						onError={(e) => {
							e.target.onerror = null;
							e.target.src = "https://via.placeholder.com/150";
						}}
					/>
					<div>
						<h2 className="text-xl font-semibold">{usuario.nombre}</h2>
						<p className="text-gray-600">{usuario.edad} años</p>
					</div>
				</div>

				<div className="mb-6">
					<p className="text-sm text-gray-700 mb-2">
						Describe a {usuario.nombre} en tres palabras. Por ejemplo:
					</p>
					<p className="text-sm font-semibold text-blue-600">
						"Muy maja, amable, habla mucho"
					</p>
				</div>

				<textarea
					value={opinion}
					onChange={(e) => setOpinion(e.target.value)}
					placeholder="Escribe tu opinión aquí..."
					className="w-full p-2 border rounded-md mb-4"
					rows="3"
				/>

				<button
					onClick={handleVotar}
					className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors"
				>
					Votar
				</button>
			</div>

			<div className="bg-gray-100 rounded-lg p-4 mb-6">
				<p className="text-sm text-gray-700 mb-2">
					Si este usuario te hace sentir incómodo/a, puedes bloquearlo o
					reportarlo.
				</p>
			</div>

			<div className="flex justify-between">
				<button
					onClick={handleBlock}
					className="flex items-center px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors"
				>
					<Shield className="w-4 h-4 mr-2" />
					Bloquear
				</button>
				<button
					onClick={handleReport}
					className="flex items-center px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
				>
					<Flag className="w-4 h-4 mr-2" />
					Reportar
				</button>
			</div>
		</div>
	);
};

// Componente HelpPage
const HelpPage = () => {
	return (
		<div className="container mx-auto px-4 py-8 max-w-3xl">
			<h1 className="text-3xl font-bold mb-6 text-center">
				Guía de Uso de la App
			</h1>

			<GuideSection title="1. Completa tu Perfil">
				<p>
					Es esencial que rellenes tu perfil con información precisa. La app
					utiliza estos datos para buscar el mejor "match" basado en tus
					intereses y características.
				</p>
			</GuideSection>

			<GuideSection title="2. Likes y Matches">
				<ul className="list-disc pl-5 space-y-2">
					<li>
						<strong>Dar Likes:</strong> Si alguien te interesa, da un like a su
						perfil.
					</li>
					<li>
						<strong>Importante:</strong> No des likes a usuarios que no te
						interesen. Esto puede penalizar tu credibilidad y clasificarte como
						indeciso, bajándote a la categoría de principiantes.
					</li>
					<li>
						<strong>Match:</strong> Cuando alguien con gustos similares te da un
						like, se crea un "match".
					</li>
				</ul>
			</GuideSection>

			{/* Añade más secciones de la guía aquí */}
		</div>
	);
};

// Componente GuideSection (utilizado en HelpPage)
const GuideSection = ({ title, children }) => {
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<div className="border-b border-gray-200 py-4">
			<button
				className="flex justify-between items-center w-full text-left font-semibold text-lg"
				onClick={() => setIsOpen(!isOpen)}
			>
				{title}
				{isOpen ? (
					<ChevronUp className="w-5 h-5" />
				) : (
					<ChevronDown className="w-5 h-5" />
				)}
			</button>
			{isOpen && <div className="mt-2 text-gray-600">{children}</div>}
		</div>
	);
};

// export default ({ }) => {
// 	return (
// 		<div>
// 			<PhotoCarousel />
// 			<PhotoWithReactions />
// 			<FlagSection />
// 			<UserDetailsCard />
// 			<CharacteristicsList />
// 			<RandomQuestions />
// 			<ActionButtons />
// 			<FilterOverlay />
// 			<EventCard />
// 			<EventListPage />
// 			<EventCard1 />
// 			<VotacionUsuario />
// 			<HelpPage />
// 			<GuideSection />
// 		</div>
// 	);
// };


// Constantes globales para los componentes
const GLOBAL_PROPS = {
	PhotoCarousel: {
		photos: ['/server/x (21)/1.png', '/server/x (21)/2.png', '/server/x (21)/3.png'],
	},
	PhotoWithReactions: {
		photo: { url: '/server/x (21)/3.png', alt: 'Sample Photo' },
		title: 'Mi foto favorita',
	},
	FlagSection: {
		flags: ['Flag 1', 'Flag 2', 'Flag 3'],
		type: 'green',
	},
	UserDetailsCard: {
		details: {
			height: 175,
			job: 'Desarrollador',
			education: 'Universidad',
			children: false,
			pets: { dogs: true, cats: false },
			smoke: false,
			drink: 'Ocasionalmente',
			coffee: true,
		},
	},
	CharacteristicsList: {
		characteristics: [
			{ id: 1, text: 'Amigable' },
			{ id: 2, text: 'Creativo' },
			{ id: 3, text: 'Aventurero' },
		],
		reactions: {},
		handleReaction: () => { },
	},
	RandomQuestions: {
		questions: [
			{ question: '¿Cuál es tu comida favorita?', answer: 'Pizza' },
			{ question: '¿Lugar ideal para vacacionar?', answer: 'Playa' },
		],
	},
	ActionButtons: {
		onLike: () => console.log('Like'),
		onDislike: () => console.log('Dislike'),
	},
	FilterOverlay: {
		isOpen: false,
		onClose: () => { },
	},
	EventCard: {
		event: {
			id: 1,
			name: 'Evento de Ejemplo',
			image: '/server/x (21)/3.png',
			user: 10,
			total: 50,
			dia: '15',
			mes: 'Ago',
			hora: '18:00',
		},
	},
	EventListPage: {
		events: [
			// Array de eventos de ejemplo
		],
	},
	VotacionUsuario: {
		// No necesita props específicos
	},
	HelpPage: {
		// No necesita props específicos
	},
	GuideSection: {
		title: 'Sección de Guía',
		children: <p>Contenido de la guía</p>,
	},
};

// Componente principal que renderiza todos los componentes
const App = () => {
	return (
		<div>
			<PhotoCarousel {...GLOBAL_PROPS.PhotoCarousel} />
			<PhotoWithReactions {...GLOBAL_PROPS.PhotoWithReactions} />
			<FlagSection {...GLOBAL_PROPS.FlagSection} />
			<UserDetailsCard {...GLOBAL_PROPS.UserDetailsCard} />
			<CharacteristicsList {...GLOBAL_PROPS.CharacteristicsList} />
			<RandomQuestions {...GLOBAL_PROPS.RandomQuestions} />
			<ActionButtons {...GLOBAL_PROPS.ActionButtons} />
			<FilterOverlay {...GLOBAL_PROPS.FilterOverlay} />
			<EventCard {...GLOBAL_PROPS.EventCard} />
			<EventListPage {...GLOBAL_PROPS.EventListPage} />
			<EventCard1 {...GLOBAL_PROPS.EventCard} />
			<VotacionUsuario />
			<HelpPage />
			<GuideSection {...GLOBAL_PROPS.GuideSection} />

			{/* Componentes adicionales que se crearon pero no se estaban usando */}
			<UserProfile user={{
				name: 'Usuario Ejemplo',
				age: 30,
				location: 'Ciudad Ejemplo',
				photos: [
					{ url: '/server/x (21)/3.png', title: 'Foto 1' },
					{ url: '/server/x (21)/3.png', title: 'Foto 1' },
					{ url: '/server/x (21)/3.png', title: 'Foto 1' },
					{ url: '/server/x (21)/3.png', title: 'Foto 1' },
					{ url: '/server/x (21)/3.png', title: 'Foto 1' },
					{ url: '/server/x (21)/3.png', title: 'Foto 1' }
				],
				bio: 'Biografía de ejemplo',
				characteristics: GLOBAL_PROPS.CharacteristicsList.characteristics,
				details: GLOBAL_PROPS.UserDetailsCard.details,
				randomQuestions: GLOBAL_PROPS.RandomQuestions.questions,
				redFlags: ['Red Flag 1', 'Red Flag 2'],
				greenFlags: ['Green Flag 1', 'Green Flag 2'],
			}} />
		</div>
	);
};

export default App;