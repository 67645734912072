import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChevronLeft, MoreVertical, /* Filter */ } from 'lucide-react';

const Header = ({back=null, menu=null, title="", }) => {
	const navigate = useNavigate();
	const [showMenu, setShowMenu] = useState(false);

	const handleBack = () => {
		if (typeof(back) === "function") return back();
		navigate(-1);
	};

	const handleMenu = () => {
		setShowMenu(false);
	};

	useEffect(() => {
		navigate(1);
	}, []);

	return (
		<div className="relative h-16">
			<div className="w-full bg-white fixed flex justify-between items-center top-14 z-30 py-3 mb-6">
				<div className="flex items-center">
					{(back != null) && <BackButton onClick={handleBack} />}
					<h1 className="text-3xl ml-4 font-bold">{title}</h1>
				</div>
				{(menu != null) && <MenuList menu={menu} showMenu={showMenu} setShowMenu={setShowMenu} handleMenu={handleMenu} />}
				{/* <YourComponent /> */}
			</div>
		</div>
	)
}

const BackButton = ({onClick}) => {
	return (
		<button onClick={onClick} className="p-3 bg-gray-300 hover:bg-gray-200 rounded-full transition-colors" aria-label="Cerrar" >
			<ChevronLeft className="w-6 h-6" />
		</button>
	);
}

function YourComponent() {
	const navigate = useNavigate();
	const location = useLocation();

	const handlePushState = (newState, title, url) => {
		// Simular push state
		navigate(url, {
			state: newState,
			replace: false
		});
	};

	const handlePopState = () => {
		console.log('Current state:', location.state);
	};

	return (
		<div>
			<button onClick={() => handlePushState({ page: 'newPage' }, '', '#')}>
				Push State
			</button>
			<button onClick={handlePopState}>
				Log Current State
			</button>
		</div>
	);
}


const MenuList = ({menu, showMenu, setShowMenu, handleMenu}) => {
	return (
		<div className="p-3 relative rounded-full transition-colors" aria-label="Configuración" >
			<MoreVertical className="w-6 h-6" onClick={x=>setShowMenu(!showMenu)}/>
			<div className={"absolute flex w-25 flex-col py-3 shadow-2xl rounded-lg mx-2 my-8 r-0 -b1-full bg-white text-lx " + (showMenu?"":"hidden")} style={{right: 0, width:"120px"}}>
				<div className="w-full px-4 py-2 hover:bg-blue-300" onClick={x=>handleMenu(false, "un")}> Unfriend </div>
				<div className="w-full px-4 py-2 hover:bg-blue-300" onClick={x=>handleMenu(false, "bk")}> Block </div>
				<div className="w-full px-4 py-2 hover:bg-blue-300" onClick={x=>handleMenu(false, "rp")}> Report </div>
			</div>
		</div>
	)
}

export default Header;