import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
import { Users, Check, MapPin } from 'lucide-react';
import { useAppContexts } from '../../AppContext';
import { useTableContexts } from '../../Context/TableContext';
import { usePost } from '../../Helper/useFetch';
import Header from '../../Components/Header';
import ProfileDetailsId from './../ProfileDetailsId';
import OverPage from '../../Components/OverPage';

const tutorialSteps = [
	{
		// findItem: 1,
		scrollTo: true,
		selector: ".event-name",
		title: "Detalles del evento",
		description: "Nombre del evento",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
		],
	},
	{
		// findItem: 1,
		// scrollTo: true,
		selector: ".event-close",
		title: "Detalles del evento",
		description: "Cerrar la ventana de detalles del evento",
		masks: [
			{ type: "rect", x: 0, y: 0, r: 100, findItem: true, scrollTo: false, color: null },
		],
	},
	{
		// findItem: 1,
		// scrollTo: true,
		selector: ".event-image",
		title: "Detalles del evento",
		description: "Imagen del evento",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
		],
	},
	{
		// findItem: 1,
		scrollTo: true,
		selector: ".event-desc",
		title: "Detalles del evento",
		description: "Descripción del evento",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
		],
	},
	{
		// findItem: 1,
		// scrollTo: true,
		selector: ".event-list",
		title: "Detalles del evento",
		description: "Lista de participantes del evento",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
		],
	},
	{
		findItem: 1,
		// scrollTo: true,
		selector: ".event-list",
		title: "Detalles del evento",
		description: "el numero de likes que tu le has dado a cada participante.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
			{ type: "highlight", x: 100, y: 100, w: 50, h: 30, selector: ".your-likes", color: "rgba(255, 0, 0, 0.5)" }
		],
	},
];

const HeartWithLikeCount = ({ width, className, count = 0, fill }) => {
	const w = width ?? "24";
	fill = count > 0 ? "currentColor" : "none";
	return (
		<div className={"relative inline-block m-0 " + (className ?? "")}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={w}
				height={w}
				viewBox={"0 0 24 24"}
				fill={fill ?? "none"}
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				className="h-auto text-red-500"
			>
				<path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
			</svg>

			{count > 0 && (
				<span className="absolute -top-1 left-0 text-white text-xs font-bold h-full w-full flex items-center justify-center">
					+{count}
				</span>
			)}
		</div>
	);
};

const ParticipanteItem = ({ item }) => {
	return (
		<div className="flex justify-between items-center space-x-3 px-4 bg-gray-100 rounded-lg w-full">
			<div className="flex items-center space-x-3 p-2">
				<img className="w-10 h-10 text-gray-500" src={item.image} loading="lazy" alt={item.name} />
				<div className="">
					<p className="font-semibold">{item.name}</p>
					{/* <p className="text-sm text-gray-600">{item.rol}</p> */}
				</div>
			</div>
			<HeartWithLikeCount className="your-likes w-[26px] h-auto mr-3 text-red-500" width="40" count={item.likes} />
		</div>
	)
};

const PaginaDetalleEvento = ({ item = {} }) => {
	const [event, setItem] = useState(item);
	const [showProfile, setShowProfile] = useState(0);
	// const args = useParams();

	const { useData } = useTableContexts();
	const profiles = useData('profiles');

	// const id = xid || parseInt(args.id ?? 0) || null;
	// useEffect(() => {
	// 	if (id != null && items.find) setItem(items.find((x) => x.id === id) || {});
	// }, [id, items]);

	const { trans, setTutorial } = useAppContexts();
	const attend_api = usePost('/api/mobile/assist');

	useEffect(() => { setTutorial(tutorialSteps); return () => setTutorial(null) }, [setTutorial]);
	// {trans("settings.helps", "Ayuda")}

	const attendants = event.assistents || [];

	const handleAttend = () => {
		attend_api.call({ event_id: item.id, assist: true }, (result) => {
			if (result) {
				setItem(x => ({ ...x, assist: true }));
			}
		});
	};

	const handleCancel = () => {
		attend_api.call({ event_id: item.id, assist: false }, (result) => {
			if (result) {
				setItem(x => ({ ...x, assist: false }));
			}
		});
	};

	const handleCloseProfile = () => {
		setShowProfile(null)
	};

	// assist,category_id,city_id,country_id,desc,fecha,id,image,location,name,price,state_id,total,assistents"[]
	return (
		<div className="max-w-4xl mx-auto mt-8 px-4">
			<Header back={true} title={event.name} />
			<img src={event.image} alt={event.name} loading="lazy" className="event-image w-full h-64 object-cover rounded-lg mb-6" />

			<div className="flex flex-col items-center justify-between w-full mb-6">
				<div className=" w-full text-gray-700 text-2xl">{event.fecha}</div>
				<div className="flex justify-between w-full items-center text-gray-700 text-2xl font-bold">
					<span className='flex items-center'><Users className="mr-2" />{attendants.length}/{event.total} </span>
					<div className="text-gray-700 text-2xl font-bold">Price: {event.price}€</div>
				</div>
				<div className="flex justify-between w-full items-center text-gray-700 text-2xl font-bold">
					<span className='flex items-center'><MapPin className="mr-2" /> {event.location} </span>
					{/* <div className="text-gray-700 text-2xl font-bold">Price: {event.price}€</div> */}
				</div>
			</div>

			<div className="fixed left-0 bottom-14 flex items-center justify-end w-full p-6 bg-white z-20">
				<button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold w-full py-2 px-4 rounded-lg"> Attend </button>
			</div>

			<section className="event-desc mb-8">
				<h2 className="text-2xl font-semibold mb-3">{trans("event-details.desc", "Descripción")}</h2>
				<p className="text-gray-700 leading-relaxed">{event.desc}</p>
			</section>

			<section>
				<h2 className="text-2xl font-semibold mb-4">{trans("event-details.participants", "Participantes")}</h2>
				<div className="event-list grid grid-cols-1 md:grid-cols-2 gap-4">
					{attendants.map(attend => {
						const profile = profiles.find(x => x.id === attend) || {};
						return (
							<div onClick={() => setShowProfile(profile)} key={profile.id} className="w-full">
								<ParticipanteItem item={profile} />
							</div>
						)
					})}
				</div>
			</section>

			<div className="mb-14 flex items-center justify-end w-full p-6 bg-white z-20"></div>

			<div className="fixed left-0 bottom-14 flex items-center justify-end w-full p-6 bg-white z-20">
				{event.assist ?
					<button onClick={handleCancel} className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white font-semibold w-full py-2 px-4 rounded-lg">
						<Check className="mr-2" style={{ strokeWidth: "7px" }} />
						Cancel
					</button>
					:
					<button onClick={handleAttend} className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white font-semibold w-full py-2 px-4 rounded-lg">
						Attend
					</button>
				}
			</div>

			<OverPage show={showProfile?.id} setClose={handleCloseProfile}> <ProfileDetailsId item={showProfile} /> </OverPage>
		</div>
	);
};

export default PaginaDetalleEvento;