import React, { createContext, useState, useContext, useEffect } from 'react';

// Creamos el contexto
const TableContexts = createContext({});

export const useTableContexts = () => useContext(TableContexts);

export const TableProvider = ({ children }) => {
	const [tables, setTables] = useState([]);

	const createTable = (name) => {
		const table = {
			name: name,
			rows: [],
			changes: [],
			find: id => {
				const row = table.rows.find(row => row.id === id)
				return row;
			},
			update(rows, row) {
				const rowIdx = rows.findIndex(r => r.id === row.id);

				if (rowIdx === -1) {
					rows.push(row);
				} else {
					rows[rowIdx] = {...rows[rowIdx], ...row};
				}

				return rows;
			},
			load: row => setTables(old => {
				const xold = {...old};
				const table = xold[name] ?? {};
				const rowIdx = table.rows.findIndex(r => r.id === row.id);
				if (rowIdx === -1) {
					table.rows.push(row);
				} else {
					table.rows[rowIdx] = {...table.rows[rowIdx], ...row};
				}
				xold[name] = table;
				return xold;
			}),
			loads: rows => setTables(old => {
				const xold = {...old};
				const table = xold[name] ?? {};
				rows.forEach(row => {
					const rowIdx = table.rows.findIndex(r => r.id === row.id);
					if (rowIdx === -1) {
						table.rows.push(row);
					} else {
						table.rows[rowIdx] = {...table.rows[rowIdx], ...row};
					}
				});
				xold[name] = table;
				return xold;
			}),
		};

		return table;
	}

	const useTable = (name) => {
		useEffect(() => {
			if (tables[name]) return;
			setTables(old => ({ ...old, [name]: table}));
		}, []);
	
		if (tables[name]) return tables[name];

		const table = createTable(name);
		return table;
	}

	const useCreates = (names) => {
		useEffect(() => {
			setTables(olds => {
				const newTables = {...olds};
				names.forEach(name => {
					newTables[name] = createTable(name);
				});

				return newTables;
			});
		}, []);
	}

	const useData = (name) => {
		if (tables[name]) {
			return tables[name].rows;
		}

		return [];
	}

	const contextValue = {
		useCreates,
		useTable,
		useData,
		tables,
		setTables,		// should be removed, and remplaced by useTableUpdate
	};

	return (
		<TableContexts.Provider value={contextValue}>

			{children}
		</TableContexts.Provider>
	);
};

export default TableProvider;