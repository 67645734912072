import React, { useState } from 'react';
import { useAppContexts } from '../../AppContext';
import { useTableContexts } from '../../Context/TableContext';
import Events1 from './Events1';
import Events2 from './Events2';

const Events = (props) => {
	const data = {...props};
	const { StyleId } = useAppContexts();
	const EventDOM = [Events1, Events2][StyleId - 1] || Events1;

	const { useData } = useTableContexts();
	const events = useData('events');
	const hobbies = useData('hobbies');

	const [showFilter, setShowFilter] = useState();
	const [activeTab, setActiveTab] = useState('attend');
	const [showDetails, setShowDetails] = useState(0);
	const [showHobbies, setShowHobbies] = useState(0);

	// useStates
	data.showFilter = showFilter;
	data.activeTab = activeTab;
	data.showDetails = showDetails;
	data.showHobbies = showHobbies;
	data.setShowFilter = setShowFilter;
	data.setActiveTab = setActiveTab;
	data.setShowDetails = setShowDetails;
	data.setShowHobbies = setShowHobbies;

	data.data = activeTab=="attend" ? events : hobbies;

	return <EventDOM key={activeTab} {...data} />;
};

export default Events;