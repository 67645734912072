


// TODO: se usa este import para que no se incluya el archivo en el Build
export default await (async () => {
	if (process.env.REACT_APP_USE_TEST_DATA === 'true' && process.env.NODE_ENV !== 'production') {

		const login = await import('./loginData.json');
		const register = await import('./registerData.json');

		return {login, register};
	}

	return {};
})();