import React, { useEffect, useState } from 'react';
import { usePost } from '../../Helper/useFetch';
import { useAppContexts } from '../../AppContext';
import { useTableContexts } from '../../Context/TableContext';
import Foryou1 from './Foryou1';
import Foryou2 from './Foryou2';

const Foryou = (props) => {
	const data = {...props};
	const { StyleId } = useAppContexts();
	const ForyouDOM = [Foryou1, Foryou2][StyleId - 1] || Foryou1;

	const [index, setIndex] = useState(0);
	const [liked, setLiked] = useState("");

	const { useData } = useTableContexts();
	const foryou = useData('foryou');
	const profiles = useData('profiles');
	const profileId = foryou[index] || {};
	const profileId1 = foryou[index + 1] || {};
	const likes_api = usePost('/api/mobile/likes');

	data.liked = liked;
	data.profile = {...profiles.find(x => x.id === profileId.id) };
	data.profile1 = {...profiles.find(x => x.id === profileId1.id) };

	data.profile.redFlags = data.profile.redFlags || [];
	data.profile.interests = data.profile.interests || [];
	data.profile.greenFlags = data.profile.greenFlags || [];
	data.profile.characteristics = data.profile.characteristics || [];
	data.profile.randomQuestions = data.profile.randomQuestions || [];
	data.profile.images = data.profile.images || [{ image: data.profile.image, title: data.profile.name }];
	data.profile.imagesURL = data.profile.images.map(x => x.image);

	useEffect(() => {
		if (data.profile1.image) {
			const img = new Image();
			img.src = data.profile1.image;
		}
	}, [data.profile1.image]);

	data.onLike = () => {
		if (liked !== "") return;

		setLiked("easy liked");
		likes_api.call({ profile_id: data.profile.id, like: 1 }, (result) => {
			if (!result.error) {
				setLiked("");
				setIndex(x => Math.min(x + 1, foryou.length));
			} else {
				setLiked("easy");
				alert("Error al enviar el like");
			}
		});
	};

	data.onDislike = () => {
		if (liked !== "") return;

		setLiked("easy disliked");
		likes_api.call({ profile_id: data.profile.id, like: -1 }, (result) => {
			if (!result.error) {
				setLiked("");
				setIndex(x => Math.min(x + 1, foryou.length));
			} else {
				setLiked("easy");
				alert("Error al enviar el dislike");
			}
		});
	};


	return <ForyouDOM {...data} />;
};

export default Foryou;