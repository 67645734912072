import ProfileOnly from './../ProfileOnly';
import { useAppContexts } from '../../AppContext';
import { Heart, X, MapPin, Edit, Filter } from 'lucide-react';

const ProfileUser = ({ profile, profile1, liked, showEdit, showFilter, onLike, onDislike }) => {
	const { trans } = useAppContexts();
	if (!profile || !profile.id) return <div className="w-full h-full flex justify-center items-center text-2xl font-bold text-gray-500">No hay más perfiles para mostrar</div>

	return (
		<div className={"h-full bg-white my-8"}>
			{profile1 && <ProfileOnly key={profile1.id} profile={profile1} className={"absolute top-0 left-0 right-0 h-full overflow-y-auto bg-white profile-card "} />}

			{profile &&
				<>
					<ProfileOnly key={profile.id} profile={profile} className={"absolute top-0 left-0 right-0 h-full overflow-y-auto bg-white profile-card " + liked} />
					<div className="fixed top-14 left-0 right-0 flex justify-between items-center px-4 py-2 bg-white border-t border-gray-200" style={{ boxShadow: "0px -10px 20px 0px #000" }}>
						<h1 className="text-3xl font-bold">{profile.name}</h1>
						<p className="flex items-center text-gray-600">
							<MapPin className="mr-2" size={16} />{profile.location}

							{showEdit && <Edit className="text-blue-500 ml-4 mr-2" size={24} />}
							{showFilter && <Filter className="text-blue-500 ml-4 mr-2" size={24} />}
						</p>
					</div>
				</>
			}

			<div className="fixed bottom-14 left-0 right-0 flex justify-around border-gray-200 p-8 pb-4" style={{background: "linear-gradient(0deg, #000B 30%, #0000 100%)"}}>
				<button
					onClick={onDislike}
					className="flex-1 mr-2 py-2 bg-gray-500 text-white rounded-full flex items-center justify-center"
				>
					<X className="mr-2" /> No me interesa
				</button>
				<button
					onClick={onLike}
					className="flex-1 ml-2 py-2 bg-red-500 text-white rounded-full flex items-center justify-center"
				>
					<Heart className="mr-2" /> Me gusta
				</button>
			</div>
		</div>
	);
};

export default ProfileUser;