import React, { useState } from 'react';
import { useAppContexts } from '../../AppContext';
import { useTableContexts } from '../../Context/TableContext';
import Votes1 from './Votos1';
import Votes2 from './Votos2';

const Votes = (props) => {
	const data = {...props};
	const { StyleId } = useAppContexts();
	const EventDOM = [Votes1, Votes2][StyleId - 1] || Votes1;

	const [showFilter, setShowFilter] = useState();
	const [showVote, setShowVote] = useState(0);

	const { useData } = useTableContexts();
	const profiles = useData('votes');
	const profiles2 = useData('profiles');

	// useStates
	data.showFilter = showFilter;
	data.showVote = showVote;
	data.setShowFilter = setShowFilter;
	data.setShowVote = setShowVote;
	data.profiles = profiles;
	data.profiles2 = profiles2;

	// Component Data
	data.data = profiles;
	data.profiles = profiles2;

	return <EventDOM {...data} />;
};

export default Votes;