const useLocalhost = true;
const isProduction = process.env.NODE_ENV === 'production';

const ApiUrl = isProduction ? process.env.REACT_APP_API_URL :
	typeof(useLocalhost)!=='undefined' ?
	"http://localhost:8000" :
	"https://citas.laravel.sclib.dev";
	// process.env.REACT_APP_API_URL;

export default ApiUrl;


