import React, { useEffect, useState } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
import { useAppContexts } from '../AppContext';
import Help from './Help';

const btnStyle = "p-2 rounded ";
const btnEnable = "bg-blue-500 text-white hover:bg-blue-600";
const btnDisable = "bg-gray-200 text-gray-800 hover:bg-gray-300";

// const tutorial_element_example = () => (
// 	<div style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "#fff8", padding: "15px", borderRadius:"15px" }}>
// 		Hassan
// 	</div>
// );

// const tutorial_json_example = [
// 	{
// 		title: "Eventos de Citas",
// 		description: "Aqui muestra el nombre de la actividad, la cantidad de personas que asistiran y la cantidad total de personas que pueden asistir.",
// 		masks: [
// 			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, selector: ".event-list", findItem: true, scrollTo: false, color: null, nodes: [
// 					{ type: "circle", x: 100, y: 100, r: 50, color: "rgba(255, 0, 0, 0.5)" },
// 					{ type: "element", x: 40, y: 140, element: tutorial_element_example}
// 				]
// 			},
// 			{ type: "circle", x: 320, y: 232, r: 50, color: "rgba(255, 0, 0, 0.5)" },
// 			{ type: "element", x: 200, y: 100, element: tutorial_element_example },
// 		],
// 	},
// ]

const TIMEOUT_MS = 300;
const findElement = (selector, findItem, root=document) => {
	const retNull = null;

	if (!selector) return retNull;
	const element = root.querySelector(selector);
	if (!element) return retNull;

	if (findItem) {
		const items = Object.values(element.childNodes)
		const item = items.find(i => elementPos(i).y > 60);
		if (!item) return retNull;
		return item;
	} else {
		return element;
	}
}

const elementPos = e => {
	const retNull = { x:0, y:0, w:0, h:0 };
	if (!e) return retNull;
	if (!(e instanceof Element)) return retNull;
	e = e.getBoundingClientRect();
	return { x:e.x, y:e.y, w:e.width, h:e.height }
};

const TutorialOverlay = ({ isVisible, onClose, steps }) => {
	const limits = { top: 0, bottom: 0 };
	const [index, setIndex] = useState(0);
	const [element, setElement] = useState(null);
	const [step, setStep] = useState(null);
	const [showHelp, setShowHelp] = useState(false);

	// se activa cuando cambia isVisible
	useEffect(() => {
		if (isVisible) setIndex(0);
		if (!steps) return;
		if (steps.length === 0) return;
		if (index >= steps.length) return;

		const n_step = steps[index];
		setStep(n_step);

		const x = findElement(n_step.selector, n_step.findItem);
		setTimeout(()=>setElement(x), n_step.scrollTo ? TIMEOUT_MS : 0);

		return () => {
			if (element) {
				const highlight = element.querySelectorAll(".highlight");
				highlight.forEach(e => e.classList.remove("highlight"));
			}
		}
	}, [isVisible, steps]);

	// se activa cuando cambia index
	useEffect(() => {
		if (!steps) return;
		if (steps.length === 0) return;
		if (index >= steps.length) return;
		
		const n_step = steps[index];
		setStep(n_step);
		const x = findElement(n_step.selector, n_step.findItem);
		setTimeout(()=>setElement(x), n_step.scrollTo ? TIMEOUT_MS : 0);

		if (x && n_step.scrollTo) {
			const y = x.getBoundingClientRect().top + window.scrollY - 100;
			const parest = document.querySelector(".base-scroll");
			if (parest) parest.scrollTo({ top: y, behavior: 'smooth' });
		}
		
		return () => {
			if (element) {
				const highlight = element.querySelectorAll(".highlight");
				highlight.forEach(e => e.classList.remove("highlight"));
			}
		}
	}, [isVisible, steps, index]);

	if (!isVisible) return null;
	if (!steps || steps.length === 0) return null;

	// const element = findElement(step.selector, step.findItem);

	const handleNext = () => {
		if (index < steps.length - 1) setIndex(index + 1);
	};

	const handlePrev = () => {
		if (index > 0) setIndex(index - 1);
	};

	const MaskCircle = ({x=0, y=0, r=100}) => <circle cx={x} cy={y} r={r} fill="black" />
	const MaskRect = ({x=0, y=0, w=100, h=100, p=7}) => <rect x={x-p} y={y-p} width={w+2*p} height={h+2*p} fill="black" rx={p*1.7} ry={p*1.7} />

	const TutorialHeader = () => {
		return (
			<div className="absolute top-4 px-4 flex w-full items-center justify-between">
				<div style={{width: "40px"}}></div>
				<div className="flex space-x-8 items-center">
					<button onClick={handlePrev} className={btnPrev} > <ChevronLeft className="w-6 h-6" /> </button>
					<span className="text-white text-xl font-bold"> {index + 1} / {steps.length} </span>
					<button onClick={handleNext} className={btnNext} > <ChevronRight className="w-6 h-6" /> </button>
				</div>
				<button onClick={onClose} className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600" > <X className="w-6 h-6" /> </button>
			</div>
		);
	}

	const TutorialMask = ({item, element, setLimits}) => {
		const limits = { top: 0, bottom: 0 };

		const masks = item.masks ? item.masks.reduce((arr, mask, index) => {
			const item = element || {};
			const pos = elementPos(item);
			limits.top = Math.max(limits.top, pos.y);
			limits.bottom = Math.max(limits.bottom, pos.y + pos.h);

			if (mask.type === "rect") {
				return [...arr, <MaskRect key={index} x={pos.x} y={pos.y} w={pos.w} h={pos.h} />];
			} else if (mask.type === "circle") {
				return [...arr, <MaskCircle key={index} x={mask.x} y={mask.y} r={mask.r} /> ];
			}
			
			return arr;
		}, []) : [];

		const elements = item.masks ? item.masks.reduce((arr, mask, index) => {
			const item = element || {};
			const pos = elementPos(item);
			if (mask.type === "element") {
				const Element = mask.element
				const style = {
					position: "absolute",
					top: `${pos.y}px`,
					left: `${pos.x}px`
				}

				limits.top = Math.max(limits.top, pos.y);
				limits.bottom = Math.max(limits.bottom, pos.y + pos.h);
				return [...arr, <div key={index} style={style}><Element x={mask.x} y={mask.y} w={mask.w} h={mask.h} /></div>];
			}

			if (mask.type === "highlight") {
				const item = findElement( mask.selector, null, element);
				if (!item) return arr;
				item.classList.add("highlight");
				return arr;

				// const style = {
				// 	position: "absolute",
				// 	top: `${pos.y}px`,
				// 	left: `${pos.x}px`,
				// 	width: `${pos.x}px`,
				// 	height: `${pos.x}px`,
				// 	background: "#ff6363a8",
				// 	borderRadius: "8px"
				// }

				// limits.top = Math.max(limits.top, pos.y);
				// limits.bottom = Math.max(limits.bottom, pos.y + pos.h);
				// return [...arr, <div key={index} style={style} x={mask.x} y={mask.y} w={mask.w} h={mask.h} />];
			}

			return arr;
		}, []) : [];

		if (setLimits) setLimits(limits);
		return (
			<>
				{elements}
				<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
					<mask id="rect-mask">
						<rect width="10000" height="10000" fill="white"></rect>
						{masks}
					</mask>
					<rect width="1000" height="75" fill="rgba(0, 0, 0, 0.5)"></rect>
					<rect width="1000" height="1000" fill="rgba(0, 0, 0, 0.75)" mask="url(#rect-mask)"></rect>
				</svg>
			</>
		);
	}

	const TutorialDescriotion = ({item, limits, pos={x:0,y:0,h:100}}) => {
		const BH = document.scrollingElement.scrollHeight;
		const mTop = limits.top;
		const mBot = BH - limits.bottom;

		if (mTop>mBot) {
			return (
				<div className="absolute mx-6 pointer-events-none" style={{left:0, bottom:`${BH-mTop+30}px`, pointerEvents: "all"}}>
					<div className="bg-white rounded-lg p-4 shadow-lg" >
						<h3 className="text-lg font-bold mb-2">{step.title}</h3>
						<pre className="text-indigo-600 font-bold" style={{maxHeight: "130px", overflow:"auto", textWrap: "balance"}}>{step.description}</pre>
					</div>
				</div>
			);
		} else {
			return (
				<div className="absolute mx-6 pointer-events-none" style={{left:0, top:`${limits.bottom+30}px`, pointerEvents: "all"}}>
					<div className="bg-white rounded-lg p-4 shadow-lg" >
						<h3 className="text-lg font-bold mb-2">{step.title}</h3>
						<pre className="text-indigo-600 font-bold" style={{maxHeight: "130px", overflow:"auto", textWrap: "balance"}}>{step.description}</pre>
					</div>
				</div>
			);
		}
	}

	const setLimits = (x) => {
		limits.top = x.top;
		limits.bottom = x.bottom;
	}

	const handleHelpClick = () => {
		setShowHelp(true);
	};

	if (showHelp) {
		return (
			<div className="fixed top-14 inset-0 z-30 flex items-center justify-center">
				<Help className="bg-white overflow-y-auto w-full h-full" onClose={() => setShowHelp(false)} />;
			</div>
		);
	}

	const btnPrev = btnStyle + ((index > 0) ? btnEnable : btnDisable);
	const btnNext = btnStyle + ((index < steps.length - 1) ? btnEnable : btnDisable);
	return (
		<div className="fixed inset-0 z-50 flex items-center justify-center">
			<TutorialHeader />
			<TutorialMask item={step} element={element} setLimits={setLimits} />
			<TutorialDescriotion item={step} limits={limits} />
			<div className="flex justify-center fixed left-0 bottom-0 w-full p-3">
				<button className="text-white rounded-lg bg-blue-600 px-3 py-2" onClick={handleHelpClick}>Como funciona esta app</button>
			</div>
		</div>
	);
};

const App = ({show}) => {
	const { /* trans, */ tutorial, toggleHelp } = useAppContexts();
	// {trans("settings.helps", "Ayuda")}

	return (<TutorialOverlay isVisible={show} onClose={toggleHelp} steps={tutorial} />);
};

export default App;