import React, { useEffect, useRef, useState } from 'react';
import ApiUrl from '../../Helper/ApiUrl';
import { useAppContexts } from '../../AppContext';
import { useNavigate } from 'react-router-dom';

const USER_ID_1 = '880880630445-v35egq8scju1sc6p0dr6rsibukqa29lc.apps.googleusercontent.com';

const GoogleAuth = ({ className, children }) => {
	// const [user, setUser] = useState(null);
	const [enabled, setEnabled] = useState(true);
	const googleButton = useRef(null);
	const { setLogin } = useAppContexts();
	const navigate = useNavigate();

	useEffect(() => {
		const handleCredentialResponse = (response) => {
			// Decodificar el JWT token
			const token = response.credential;
			const decodedToken = JSON.parse(atob(response.credential.split('.')[1]));
			console.log(decodedToken);
			// setUser({
			// 	id: decodedToken.sub,
			// 	name: decodedToken.name,
			// 	email: decodedToken.email,
			// 	picture: decodedToken.picture
			// });
			setEnabled(true);

			fetch(ApiUrl+"/api/auth/v1/callback", {
				method: "POST",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ token }),  // Envías el token JWT al servidor
			})
			.then((res) => res.json())
			.then((data) => {
				console.log("Autenticación exitosa:", data);
				if (data.error) {
					console.error("Error autenticando:", data.error);
				} else if (data.crearte_user) {
					console.log("Usuario creado:", data.user, "/register");
					navigate(`/register?email=${data.crearte_user.email}&name=${data.crearte_user.name}`);
				} else if (data.token) {
					setLogin(data.token);
					console.log("Usuario logueado:", data.user, "/home");
					navigate("/");
				}
			})
			.catch((err) => {
				console.error("Error autenticando:", err);
			});
		};

		if (window.google) {
			window.google.accounts.id.initialize({
				client_id: USER_ID_1,
				use_fedcm_for_prompt: false,
				callback: handleCredentialResponse,
				error_callback: (err) => {
					console.error("Error durante la autenticación", err);
				},
			});

			if (googleButton.current) {
				window.google.accounts.id.renderButton(
					googleButton.current, {
					// theme: "filled_blue",
					theme: "outline",
					size: "large",
					shape: "pill", // Puedes cambiar el estilo del botón
					text: "signin_with", // Texto del botón
					width: "100%" // Hace que el botón ocupe todo el ancho disponible
				}
				);

			}
		} else {
			console.error("Google API no cargada");
		}

	}, []);

	// const handleSignOut = () => {
	// 	// setUser(null);
	// 	window.google.accounts.id.disableAutoSelect();
	// };

	const handleSignIn = () => {
		if (!enabled) return;

		// setUser(null);
		setEnabled(false);
		window.google.accounts.id.prompt();
	};

	//   console.log(user);
	className = className + " cursor-pointer";
	className = className.split(" ");
	className = className.filter((item) => item !== " active")
	className = className.filter((item) => !item.startsWith("bg-"))
	className.push(enabled ? "bg-blue-500" : "bg-blue-300");
	className = className.join(" ");
	return <div className={className} onClick={handleSignIn}>{children}</div>;
	//   return <div className={className} ref={googleButton}></div>;
};

export default GoogleAuth;