import React, { useState, } from 'react';
import { Users, Check, MapPin, Calendar, Clock, Coins as Money, X } from 'lucide-react';
import { useAppContexts } from '../../AppContext';
import { useTableContexts } from '../../Context/TableContext';
import { usePost } from '../../Helper/useFetch';
import Header from '../../Components/Header';
import ProfileDetailsId from './../ProfileDetailsId';
import OverPage from '../../Components/OverPage';

const IconWrapper = ({ children, color }) => (
	<div className={`w-10 h-10 rounded-full ${color} flex items-center justify-center mr-3`}>
		{children}
	</div>
);

const month_3 = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const EventDetails = ({ item }) => {
	const a = new Date(item?.fecha || "");
	const day = `${a.getDay()}-${month_3[a.getMonth()]}`;
	const time = `${a.getHours() % 12	}:${a.getMinutes()} ${a.getHours() > 12 ? 'PM' : 'AM'}`;

	const [event, setItem] = useState(item);
	const { trans } = useAppContexts();
	const attend_api = usePost('/api/mobile/assist/');
	const [showProfile, setShowProfile] = useState(0);

	const { useData } = useTableContexts();
	const profiles = useData('profiles');

	const handleAttend = () => {
		attend_api.call({ event_id: item.id, assist: true }, (result) => {
			if (result) {
				setItem(x => ({ ...x, assist: true }));
			}
		});
	};

	const handleCancel = () => {
		attend_api.call({ event_id: item.id, assist: false }, (result) => {
			if (result) {
				setItem(x => ({ ...x, assist: false }));
			}
		});
	};

	const handleCloseProfile = () => {
		setShowProfile(null)
	};

	const attendants = event.assistents || [];

	// assist,category_id,city_id,country_id,desc,fecha,id,image,location,name,price,state_id,total,assistents"[]
	return (
		<div className="min-h-screen pt16 bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 text-white p-4">
			<Header back={true} title={event.name} />

			<div className="relative mb-6">
				<img src={event.image} alt={event.name} loading="lazy" className="w-full h-64 object-cover rounded-xl" />
				<div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
					<h1 className="text-3xl font-bold">{event.name}</h1>
				</div>
			</div>

			<div className="bg-white bg-opacity-20 backdrop-blur-lg rounded-xl p-6 mb-6">
				<div className="flex items-center mb-4">
					<IconWrapper color="bg-blue-500">
						<Calendar className="w-5 h-5 text-white" />
					</IconWrapper>
					<div>
						<p className="text-sm text-gray-300">Fecha</p>
						<p className="font-medium">{day}</p>
					</div>
				</div>

				<div className="flex items-center mb-4">
					<IconWrapper color="bg-green-500">
						<Clock className="w-5 h-5 text-white" />
					</IconWrapper>
					<div>
						<p className="text-sm text-gray-300">Hora</p>
						<p className="font-medium">{time}</p>
					</div>
				</div>

				<div className="flex items-center mb-4">
					<IconWrapper color="bg-yellow-500">
						<MapPin className="w-5 h-5 text-white" />
					</IconWrapper>
					<div>
						<p className="text-sm text-gray-300">Ubicación</p>
						<p className="font-medium">{event.location}</p>
					</div>
				</div>

				<div className="flex items-center mb-4">
					<IconWrapper color="bg-purple-500">
						<Users className="w-5 h-5 text-white" />
					</IconWrapper>
					<div>
						<p className="text-sm text-gray-300">Asistentes</p>
						<p className="font-medium">{attendants.length}/{event.total}</p>
					</div>
				</div>

				<div className="flex items-center mb-6">
					<IconWrapper color="bg-pink-500">
						<Money className="w-5 h-5 text-white" />
					</IconWrapper>
					<div>
						<p className="text-sm text-gray-300">Precio</p>
						<p className="font-medium">{event.price}€</p>
					</div>
				</div>

				<div className="mb-6">
					<h2 className="text-xl font-semibold mb-2">{trans("event-details.desc", "Descripción")}</h2>
					<p className="text-gray-200">{event.desc}</p>
				</div>

				{event.assist ? (
					<button onClick={handleCancel} className="w-full bg-red-500 hover:bg-red-600 text-white font-semibold py-3 px-4 rounded-lg flex items-center justify-center">
						<X className="mr-2" />
						Cancelar Asistencia
					</button>
				) : (
					<button onClick={handleAttend} className="w-full bg-green-500 hover:bg-green-600 text-white font-semibold py-3 px-4 rounded-lg flex items-center justify-center">
						<Check className="mr-2" />
						Asistir al Evento
					</button>
				)}
			</div>

			<div className="bg-white bg-opacity-20 backdrop-blur-lg rounded-xl p-6">
				<h2 className="text-xl font-semibold mb-4">{trans("event-details.participants", "Participantes")}</h2>
				<div className="grid grid-cols-2 gap-4">
					{attendants.map(attend => {
						const profile = profiles.find(x => x.id === attend) || {};
						return (
							<div key={profile.id} onClick={() => setShowProfile(profile)} className="flex items-center bg-white bg-opacity-10 rounded-lg p-2">
								<img src={profile.image} alt={profile.name} loading="lazy" className="w-10 h-10 rounded-full mr-3" style={{minWidth: "2.5rem"}} />
								<span className="font-medium overflow-hidden">{profile.name}</span>
							</div>
						)
					})}
				</div>
			</div>

			<OverPage show={showProfile?.id} setClose={handleCloseProfile}> <ProfileDetailsId item={showProfile} /> </OverPage>
		</div>
	);
};

export default EventDetails;