import React, { useEffect } from 'react';
import { useAppContexts } from '../../AppContext';
import { Filter } from 'lucide-react';
import OverPage from '../../Components/OverPage';

import ProfileVote from './ProfileVote';

const tutorialSteps = [
	{
		// findItem: 1,
		selector: ".my-votes",
		title: "Votos recibidos",
		description: "Se calculan dependiendo de la media semanal de votos recibidos.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
		],
	},
	{
		// findItem: 1,
		selector: ".users-votes",
		title: "Usuarios esperando que les votes",
		description: "puedes clickar en el nombre para botar caracteristicas de la persona.\nLos usuarios no veran tu voto directamente, si no, una media de votos que recibien.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
			// { type: "highlight", x: 100, y: 100, w: 50, h: 30, selector: ".name", color: "rgba(255, 0, 0, 0.5)" }
		],
	},
	{
		// findItem: 1,
		selector: ".filter-btn",
		title: "Eventos de Citas",
		description: "Clicka aqui para filtrar usuarios",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: false, scrollTo: false, color: null },
		],
	},
	// {
	// 	findItem: 1,
	// 	selector: ".users-votes",
	// 	title: "Eventos de Citas",
	// 	description: "Aqui muestra nombre de la actividad.",
	// 	masks: [
	// 		{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
	// 		// { type: "highlight", x: 100, y: 100, w: 50, h: 30, selector: ".name", color: "rgba(255, 0, 0, 0.5)" }
	// 	],
	// },
];

const ProfileVotingApp = ({data, profiles, showVote, setShowVote, showFilter, setShowFilter}) => {
	const { trans, setTutorial } = useAppContexts();

	useEffect(() => { setTutorial(tutorialSteps); return ()=> setTutorial(null) }, [setTutorial]);
	// const [currentProfile, setCurrentProfile] = useState(initialProfiles[0]);
	// const [waitingProfiles, setWaitingProfiles] = useState(initialProfiles);

	const traits = [
		{ name: trans("vote.personality.caracter", "Carácter"), color: 'bg-blue-500', value: 75 },
		{ name: trans("vote.personality.simpatia", "Simpatía"), color: 'bg-green-500', value: 60 },
		{ name: trans("vote.personality.extroversion", "Extroversión"), color: 'bg-yellow-500', value: 45 },
		{ name: trans("vote.personality.humor", "Humor"), color: 'bg-purple-500', value: 80 },
		{ name: trans("vote.personality.enojo", "Enojo"), color: 'bg-red-500', value: 30 },
		{ name: trans("vote.personality.agresion", "Agresión"), color: 'bg-orange-500', value: 20 },
	];

	// const handleVote = (vote) => {
	// 	console.log(`Voted ${vote} for ${currentProfile.name}`);
	// 	if (waitingProfiles.length > 0) {
	// 		setCurrentProfile(waitingProfiles[0]);
	// 		setWaitingProfiles(waitingProfiles.slice(1));
	// 	} else {
	// 		alert('No more profiles to vote!');
	// 	}
	// };

	const profile = profiles.find((profile) => profile.id === showVote);
	const closeVote = () => {
		setShowVote(0);
	};

	// if (showVote !== 0) {
	// 	return <ProfileVote profile={profile} closeVote={closeVote} />;
	// }

	return (
		<div className="max-w-sm mx-auto bg-gray-100 p-4 rounded-3xl shadow-lg">
			<div className="bg-white rounded-2xl p-4 mb-4">
				<div className="flex items-center mb-4">
					{/* <img src={currentProfile.image} alt="Your image" loading="lazy" className="w-16 h-16 rounded-full mr-4" /> */}
					{/* <h1 className="text-2xl font-bold">{currentProfile.name}</h1> */}
					<h1 className="text-2xl font-bold">{trans("vote.title", "Your Received Votes")}</h1>
				</div>
				<div className="my-votes">
					{traits.map((trait) => (
						<div key={trait.name} className="mb-3">
							<div className="flex justify-between mb-1">
								<span className="text-sm font-medium">{trait.name}</span>
								<span className="text-sm font-medium">{trait.value}%</span>
							</div>
							<div className="w-full bg-gray-200 rounded-full h-2.5">
								<div className={`h-2.5 rounded-full ${trait.color}`} style={{ width: `${trait.value}%` }}></div>
							</div>
						</div>
					))}
				</div>
				{/* <div className="flex justify-center mt-4"> */}
					{/* <button onClick={() => handleVote('down')} className="mr-4 p-2 bg-red-100 rounded-full"> 👎 </button> */}
					{/* <button onClick={() => handleVote('up')} className="p-2 bg-green-100 rounded-full"> 👍 </button> */}
				{/* </div> */}
			</div>
			<div className="bg-white rounded-2xl p-4">
				<div className="flex justify-between items-center mb-">
					<h2 className="text-xl font-semibold">{trans("vote.vote-list", "Perfiles por Votar")}</h2>
					<button className="filter-btn p-3 rounded-full transition-colors" aria-label="Configuración" onClick={x=>setShowFilter(!showFilter)}>
						<Filter className="w-6 h-6" />
					</button>
				</div>
				{showFilter && <div className="flex filter-name mb-4 border-xl rounded-2xl overflow-hidde">
					<input className="p-2 text-xl w-full"  placeholder="Filter by Name"/>
				</div>}
				<div className="users-votes h-48 overflow-y-auto">
					{data.map((user) => {
						const profile = profiles.find(x=>x.id === user.id) ?? {};
						return (
							<div key={profile.id} className="flex items-center mb-3" onClick={x=>setShowVote(profile.id)}>
								<img src={profile.image} alt={profile.name} loading="lazy" className="w-10 h-10 rounded-full mr-3" />
								<span>{profile.name}</span>
							</div>
							// <NavLink to={"/profile/" + profile.id} key={profile.id} className="flex items-center mb-3" onClick={x=>setShowVote(profile.id)}>
							// 	<img src={profile.image} alt={profile.name} loading="lazy" className="w-10 h-10 rounded-full mr-3" />
							// 	<span>{profile.name}</span>
							// </NavLink>
						)
					})}
				</div>
			</div>

			<OverPage show={showVote} setClose={closeVote} > <ProfileVote profile={profile} closeVote={closeVote} /> </OverPage>
		</div>
	);
};

export default ProfileVotingApp;