import React, { useEffect } from 'react';
import { useAppContexts } from '../../AppContext';
import { Filter, /* ChevronDown,  */Star } from 'lucide-react';
import OverPage from '../../Components/OverPage';

import ProfileVote from './ProfileVote1';

const tutorialSteps = [
	{
		selector: ".my-votes",
		title: "Votos recibidos",
		description: "Se calculan dependiendo de la media semanal de votos recibidos.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
		],
	},
	{
		selector: ".users-votes",
		title: "Usuarios esperando que les votes",
		description: "Puedes hacer clic en el nombre para votar características de la persona. Los usuarios no verán tu voto directamente, sino una media de votos que reciben.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
		],
	},
	{
		selector: ".filter-btn",
		title: "Filtrar usuarios",
		description: "Haz clic aquí para filtrar usuarios",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: false, scrollTo: false, color: null },
		],
	},
];

const TraitBar = ({ trait, value, color }) => (
	<div className="mb-3">
		<div className="flex justify-between mb-1">
			<span className="text-sm font-medium">{trait}</span>
			<span className="text-sm font-medium">{value}%</span>
		</div>
		<div className="w-full bg-gray-200 rounded-full h-2.5">
			<div className={`h-2.5 rounded-full ${color}`} style={{ width: `${value}%` }}></div>
		</div>
	</div>
);

const UserToVote = ({ user, onVote }) => (
	<div onClick={() => onVote(user)} className="flex items-center justify-between mb-3 bg-white bg-opacity-20 backdrop-blur-lg rounded-lg p-3">
		<div className="flex items-center">
			<img src={user.image} alt={user.name} loading="lazy" className="w-10 h-10 rounded-full mr-3 object-cover" />
			<span className="font-medium">{user.name}</span>
		</div>
		<button
			className="bg-purple-500 hover:bg-purple-600 text-white rounded-full p-2"
		>
			<Star className="w-5 h-5" />
		</button>
	</div>
);

const ProfileVotingApp = ({data, profiles, showVote, setShowVote, showFilter, setShowFilter}) => {
	const { trans, setTutorial } = useAppContexts();

	useEffect(() => {
		setTutorial(tutorialSteps);
		return () => setTutorial(null);
	}, [setTutorial]);

	const traits = [
		{ name: trans("vote.personality.caracter", "Carácter"), color: 'bg-blue-500', value: 75 },
		{ name: trans("vote.personality.simpatia", "Simpatía"), color: 'bg-green-500', value: 60 },
		{ name: trans("vote.personality.extroversion", "Extroversión"), color: 'bg-yellow-500', value: 45 },
		{ name: trans("vote.personality.humor", "Humor"), color: 'bg-purple-500', value: 80 },
		{ name: trans("vote.personality.enojo", "Enojo"), color: 'bg-red-500', value: 30 },
		{ name: trans("vote.personality.agresion", "Agresión"), color: 'bg-orange-500', value: 20 },
	];

	const profile = data.find((profile) => profile.id === showVote);
	const closeVote = () => {
		setShowVote(0);
	};

	return (
		<div className="min-h-screen pt-16 bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 text-white p-4">
			<header className="mb-6">
				<h1 className="text-3xl font-bold mb-4">{trans("vote.title", "Tus Votos Recibidos")}</h1>
				<div className="flex justify-between items-center">
					<h2 className="text-xl font-semibold">{trans("vote.vote-list", "Perfiles por Votar")}</h2>
					<button
						className="filter-btn p-3 rounded-full transition-colors bg-white bg-opacity-20"
						aria-label="Configuración"
						onClick={() => setShowFilter(!showFilter)}
					>
						<Filter className="w-6 h-6" />
					</button>
				</div>
			</header>

			<div className="bg-white bg-opacity-20 backdrop-blur-lg rounded-xl p-6 mb-6">
				<h2 className="text-2xl font-semibold mb-4">{trans("vote.your-votes", "Tus Votos Recibidos")}</h2>
				<div className="my-votes">
					{traits.map((trait) => (
						<TraitBar key={trait.name} trait={trait.name} value={trait.value} color={trait.color} />
					))}
				</div>
			</div>

			{showFilter && (
				<div className="mb-6 bg-white bg-opacity-20 backdrop-blur-lg rounded-xl p-4">
					<input
						className="w-full p-2 rounded-lg bg-white bg-opacity-30 text-white placeholder-gray-300"
						placeholder="Filtrar por nombre"
					/>
				</div>
			)}

			<div className="bg-white bg-opacity-20 backdrop-blur-lg rounded-xl p-6">
				<div className="users-votes max-h-96 overflow-y-auto">
					{data.map((user) => {
						const profile = profiles.find(x=>x.id === user.id) ?? {};
						return (
							<UserToVote key={profile.id} user={profile} onVote={() => setShowVote(profile.id)} />
						)
					})}
				</div>
			</div>

			<OverPage show={showVote} setClose={closeVote}>
				<ProfileVote profile={profile} closeVote={closeVote} />
			</OverPage>
		</div>
	);
};

export default ProfileVotingApp;