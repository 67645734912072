import React, { useState, useEffect } from 'react';
// import { type } from '@testing-library/user-event/dist/type';
// import { Heart, User, Calendar, Users, Cigarette, Beer } from 'lucide-react';
import { X, Users, Filter } from 'lucide-react';
// import { NavLink } from 'react-router-dom';
import { useAppContexts } from '../../AppContext';
import { Check } from 'lucide-react';
import EventDetails from './EventDetails';
import OverPage from '../../Components/OverPage';
// import useFetchWithRetry from '../../Helper/useFetch';

const BoxStyle = {
	position: "relative",
	display: "flex",
	borderRadius: "8px",
	backgroundPosition: "center",
	overflow: "hidden",
	height: "240px",
	flexDirection: "column",
	justifyContent: "flex-end",
};

const BoxImgStyle = {
	position: "absolute",
	borderRadius: "8px",
	width: "100%",
	height: "100%",
	inset: "0",
	objectFit: "cover"
};

const gradientOverlayStyle = {
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	background: "linear-gradient(to top, #000C 40px, transparent 80px)",
};

const tutorialSteps = [
	{
		// findItem: 1,
		selector: ".filter-btn",
		title: "Eventos de Citas",
		description: "Clicka aqui para filtrar eventos.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: false, scrollTo: false, color: null },
		],
	},
	{
		findItem: 1,
		selector: ".event-list",
		title: "Eventos de Citas",
		description: "Aqui puedes ver los eventos de citas, puedes seleccionar la que mas te guste.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
		],
	},
	{
		findItem: 1,
		selector: ".event-list",
		title: "Eventos de Citas",
		description: "Aqui muestra nombre de la actividad.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
			{ type: "highlight", x: 100, y: 100, w: 50, h: 30, selector: ".name", color: "rgba(255, 0, 0, 0.5)" }
		],
	},
	{
		findItem: 1,
		selector: ".event-list",
		title: "Eventos de Citas",
		description: "Aqui muestra la cantidad de personas que asistiran y la cantidad total de personas que pueden asistir.",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
			{ type: "highlight", x: 100, y: 100, w: 50, h: 30, selector: ".users", color: "rgba(255, 0, 0, 0.5)" }
		],
	},
	{
		findItem: 1,
		selector: ".event-list",
		title: "Eventos de Citas",
		description: "Aqui muestra fecha y hora del evento",
		masks: [
			{ type: "rect", x: 0, y: 0, w: 1000, h: 1000, findItem: true, scrollTo: false, color: null },
			{ type: "highlight", x: 100, y: 100, w: 50, h: 30, selector: ".date", color: "rgba(255, 0, 0, 0.5)" }
		],
	},
];

const month_3 = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const LikeImage = ({ item }) => {
	const a = new Date(item?.fecha || "");
	const day = `${a.getDay()}-${month_3[a.getMonth()]}`;
	const time = `${a.getHours() % 12	}:${a.getMinutes()} ${a.getHours() > 12 ? 'PM' : 'AM'}`;

	// assist,category_id,city_id,country_id,desc,fecha,id,image,location,name,price,state_id,total,assistents"[]
	return (
		<div style={BoxStyle} className="event-item bg-white shadow-md rounded-lg p-2 flex" to={"/event/" + item.id} >
			<img style={BoxImgStyle} src={item.image} loading="lazy" alt={item.name} className="w-24 h-24 rounded-full mr-4" />
			<div style={gradientOverlayStyle}></div>

			<div className="text-base font-bold" style={{ zIndex: 2, color: "#fff" }}>
				<span className='users flex text-base py-0 px-2 rounded-xl bg-gray-500 absolute top-4 right-3' style={{boxShadow: "0 0 9px 2px #000"}}> {(item.attendants ?? []).length}/{item.total} <Users className='ml-2' /></span>
				
				<h2 className="line-1 text-base font-bold">
					<span className="name">{item.name}</span>
				</h2>
				<h4 className="date text-base flex justify-between items-center">
					<span>{day}</span>
					<span>{time}</span>
				</h4>
				{item.assist && <span className="absolute top-4 left-4 flex items-center justify-center rounded-full p-2 bg-green-500 w-10 h-10">
					<Check className='text-white' style={{ strokeWidth: "7px" }} />
				</span>}
			</div>
		</div>
	)
};

const FilterOverlay = ({ isOpen, onClose }) => {
	const [filterState, setFilterState] = useState({
		timeRange: 'this-week',
		startDate: '',
		endDate: '',
		minPeople: '',
		maxPeople: '',
		eventType: []
	});

	const eventTypes = [
		"Ciclismo", "Karaoke", "Senderismo", "Cenas", "Deportes",
		"Arte", "Música", "Cocina", "Viajes", "Lectura",
		"Cine", "Teatro", "Danza", "Yoga", "Meditación"
	];

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFilterState(prev => ({ ...prev, [name]: value }));
	};

	const handleEventTypeToggle = (type) => {
		setFilterState(prev => ({
			...prev,
			eventType: prev.eventType.includes(type)
				? prev.eventType.filter(t => t !== type)
				: [...prev.eventType, type]
		}));
	};

	const handleApply = () => {
		console.log('Aplicando filtros:', filterState);
		onClose();
	};

	const handleClear = () => {
		setFilterState({
			timeRange: 'this-week',
			startDate: '',
			endDate: '',
			minPeople: '',
			maxPeople: '',
			eventType: []
		});
	};

	if (!isOpen) return null;

	return (
		<div className="fixed top-16 bottom-14 inset-0 bg-white z-50 overflow-y-auto">
			<div className="container mx-auto p-4">
				<div className="flex justify-between items-center mb-6">
					<h2 className="text-2xl font-bold">Filtrar Eventos</h2>
					<button onClick={onClose} className="p-2 rounded-full hover:bg-gray-200">
						<X className="w-6 h-6" />
					</button>
				</div>

				<form className="space-y-6">
					<div>
						<label className="block mb-2 font-semibold">Rango de tiempo</label>
						<select
							name="timeRange"
							value={filterState.timeRange}
							onChange={handleInputChange}
							className="w-full p-2 border rounded"
						>
							<option value="this-week">Esta semana</option>
							<option value="this-month">Este mes</option>
							<option value="custom">Personalizado</option>
						</select>
					</div>

					{filterState.timeRange === 'custom' && (
						<div className="flex space-x-4">
							<div className="flex-1">
								<label className="block mb-2 font-semibold">Fecha inicio</label>
								<input
									type="date"
									name="startDate"
									value={filterState.startDate}
									onChange={handleInputChange}
									className="w-full p-2 border rounded"
								/>
							</div>
							<div className="flex-1">
								<label className="block mb-2 font-semibold">Fecha fin</label>
								<input
									type="date"
									name="endDate"
									value={filterState.endDate}
									onChange={handleInputChange}
									className="w-full p-2 border rounded"
								/>
							</div>
						</div>
					)}

					<div className="flex space-x-4">
						<div className="flex-1">
							<label className="block mb-2 font-semibold">Mínimo de personas</label>
							<input
								type="number"
								name="minPeople"
								value={filterState.minPeople}
								onChange={handleInputChange}
								className="w-full p-2 border rounded"
							/>
						</div>
						<div className="flex-1">
							<label className="block mb-2 font-semibold">Máximo de personas</label>
							<input
								type="number"
								name="maxPeople"
								value={filterState.maxPeople}
								onChange={handleInputChange}
								className="w-full p-2 border rounded"
							/>
						</div>
					</div>

					<div>
						<label className="block mb-2 font-semibold">Tipo de evento</label>
						<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
							{eventTypes.map((type) => (
								<button
									key={type}
									type="button"
									onClick={() => handleEventTypeToggle(type)}
									className={`p-2 border rounded text-sm ${filterState.eventType.includes(type)
										? 'bg-blue-500 text-white'
										: 'bg-white hover:bg-gray-100'
										}`}
								>
									{type}
								</button>
							))}
						</div>
					</div>
				</form>

				<div className="mt-8 flex justify-end space-x-4">
					<button
						onClick={handleClear}
						className="px-6 py-2 bg-gray-200 rounded-lg hover:bg-gray-300 transition-colors"
					>
						Limpiar
					</button>
					<button
						onClick={handleApply}
						className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
					>
						Aplicar
					</button>
				</div>
			</div>
		</div>
	);
};

const EventBuscar = ({ items, trans, setShowFilter, openDetails }) => (
	<>
		<div className="h-12"></div>
		<div className="fixed top-24 left-0 w-full bg-white z-10 px-6 flex justify-between items-center mb-6">
			<h1 className="text-2xl font-bold mb-4">{trans("event.this-week", "Eventos esta semana")}</h1>
			<button className="filter-btn p-3 rounded-full transition-colors" aria-label="Configuración" onClick={x => setShowFilter(1)}>
				<Filter className="w-6 h-6" />
			</button>
		</div>
		<div className="event-list grid grid-cols-1 grid-cols-2 gap-4">
			{items.map((girl, index) => <div key={index} onClick={()=>openDetails(girl)}><LikeImage item={girl} /></div> )}
		</div>
	</>
)

// const EventAttender = ({ items, trans, openDetails }) => (
// 	<>
// 		<div className="h-12"></div>
// 		<div className="fixed top-24 left-0 w-full bg-white z-10 px-6 flex justify-between items-center mb-6">
// 			<h1 className="text-2xl font-bold mb-4">{trans("event.my-hobbies", "Mis Hobbies")}</h1>
// 		</div>
// 		<div className="event-list grid grid-cols-1 grid-cols-2 gap-4">
// 			{items.map((girl, index) => <div key={index} onClick={()=>openDetails(girl)}><LikeImage item={girl} /></div>)}
// 		</div>
// 	</>
// )

const Events = ({data, showFilter, setShowFilter, activeTab, setActiveTab, showDetails, setShowDetails, showHobbies, setShowHobbies}) => {
	const { trans, setTutorial } = useAppContexts();
	useEffect(() => { setTutorial(tutorialSteps); return () => setTutorial(null) }, [setTutorial]);

	const tabs = [
		{ id: 'attend', label: `Mis Hobbies` },
		{ id: 'buscar', label: `Todos los Hobbies` },
	];

	return (
		<div className="container mx-auto p-4">
			<div className="h-12"></div>
			<div className="fixed top-14 left-0 w-full bg-white z-10 tabs-filter flex justify-around mb-4">
				{
					tabs.map(
						(tab) => {
							const active = (activeTab === tab.id) ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500 hover:text-gray-700';
							return (
								<button key={tab.id} onClick={() => setActiveTab(tab.id)} className={`flex items-center relative px-4 py-2 ${active} focus:outline-none transition-colors duration-300`} >
									{tab.label}
									{tab.hasNotification && (
										<div className="flex justify-center items-center w-6 h-6 ml-2 bg-red-500 text-white rounded-full">2</div>
									)}
								</button>
							)
						}
					)
				}
			</div>

			<EventBuscar items={data} openDetails={setShowHobbies} trans={trans} setShowFilter={setShowFilter} showFilter={showFilter} />
			<FilterOverlay isOpen={showFilter} onClose={() => setShowFilter(false)} />
			
			<OverPage show={showDetails?.id} setClose={setShowDetails}> <EventDetails item={showDetails} /> </OverPage>
			<OverPage show={showHobbies?.id} setClose={setShowHobbies}> <EventDetails item={showHobbies} /> </OverPage>
		</div>
	);
};

export default Events;
