import React, { useEffect } from 'react';
import { Users, /* Calendar, Heart, ChevronDown,  */Star, Music, Utensils, Mountain, Mic, Filter, Check } from 'lucide-react';
import { useAppContexts } from '../../AppContext';
import OverPage from '../../Components/OverPage';
import EventDetails from './EventDetails2';

const IconWrapper = ({ children, color }) => (
	<div className={`min-w-20 w-20 h-20 rounded-full ${color} flex items-center justify-center`}>
		{children}
	</div>
);

const month_3 = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

const EventCard = ({ event, openDetails }) => {
	const a = new Date(event.fecha);
	const day = `${a.getDay()}-${month_3[a.getMonth()]}`;
	const time = `${a.getHours() % 12	}:${a.getMinutes()} ${a.getHours() > 12 ? 'PM' : 'AM'}`;

	const eventIcons = {
		'Noche de Cine': <Star className="w-5 h-5 text-white" />,
		'Taller de Cocina': <Utensils className="w-5 h-5 text-white" />,
		'Senderismo': <Mountain className="w-5 h-5 text-white" />,
		'Concierto': <Music className="w-5 h-5 text-white" />,
		'Karaoke': <Mic className="w-5 h-5 text-white" />
	};

	const eventColors = {
		'Noche de Cine': 'bg-yellow-500',
		'Taller de Cocina': 'bg-green-500',
		'Senderismo': 'bg-blue-500',
		'Concierto': 'bg-purple-500',
		'Karaoke': 'bg-pink-500'
	};

	// assist,category_id,city_id,country_id,desc,fecha,id,image,location,name,price,state_id,total,assistents"[]
	return (
		<div className="bg-white bg-opacity-20 backdrop-blur-lg p-4 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300" onClick={() => openDetails(event)}>
			<div className="flex items-center mb-3">
				<IconWrapper color={eventColors[event.type]}>
					<img src={event.image} alt={event.name} loading="lazy" className='w-20 h-20 bg-blue-500 rounded overflow-hidden'/>
					{/* {eventIcons[event.type]} */}
				</IconWrapper>
				<div className="px-4" style={{flex: 1}}>
					<h3 className="text-xl font-semibold">{event.name}</h3>
					<p className="text-gray-200 mb-2">{day} - {time}</p>
					<div className="flex justify-between items-center">
						<span className="flex items-center text-gray-200">
							<Users className="w-4 h-4 mr-1" />
							{(event.attendants ?? []).length}/{event.total}
						</span>
						{event.assist && (
							<span className="flex items-center text-green-400">
								<Check className="w-4 h-4 mr-1" />
								Asistiendo
							</span>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const EventsPage = ({data, showFilter, setShowFilter, activeTab, setActiveTab, showDetails, setShowDetails, showHobbies, setShowHobbies}) => {
	const { trans, setTutorial } = useAppContexts();

	// useEffect(() => {
	// 	// Simulación de carga de eventos
	// 	const mockEvents = [
	// 		{ id: 1, name: "Noche de Cine al Aire Libre", type: "Noche de Cine", date: "15 Oct, 20:00", attendees: 20, capacity: 50, isAttending: true },
	// 		{ id: 2, name: "Taller de Cocina Internacional", type: "Taller de Cocina", date: "22 Oct, 18:00", attendees: 15, capacity: 30, isAttending: false },
	// 		{ id: 3, name: "Senderismo y Picnic", type: "Senderismo", date: "29 Oct, 10:00", attendees: 25, capacity: 40, isAttending: true },
	// 		{ id: 4, name: "Concierto Acústico", type: "Concierto", date: "5 Nov, 21:00", attendees: 40, capacity: 100, isAttending: false },
	// 		{ id: 5, name: "Noche de Karaoke", type: "Karaoke", date: "12 Nov, 19:00", attendees: 30, capacity: 60, isAttending: false },
			
	// 	];
	// }, []);

	useEffect(() => {
		// Configurar el tutorial (si es necesario)
		setTutorial([]);
		return () => setTutorial(null);
	}, [setTutorial]);

	const openDetails = (event) => {
		setShowDetails(event);
	};

	const closeDetails = () => {
		setShowDetails(null);
	};

	const filteredEvents = activeTab === 'attend'
		? data.filter(event => event.assist)
		: data;

	return (
		<div className="min-h-screen pt-16 bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 text-white p-4">
			<header className="mb-6">
				<h1 className="text-3xl font-bold mb-4">{trans("events.title", "Eventos")}</h1>
				<div className="flex justify-between items-center">
					<div className="flex space-x-4">
						<button
							className={`px-4 py-2 rounded-full ${activeTab === 'attend' ? 'bg-white text-purple-600' : 'bg-purple-600 text-white'}`}
							onClick={() => setActiveTab('attend')}
						>
							Asistiré
						</button>
						<button
							className={`px-4 py-2 rounded-full ${activeTab === 'buscar' ? 'bg-white text-purple-600' : 'bg-purple-600 text-white'}`}
							onClick={() => setActiveTab('buscar')}
						>
							Todos los eventos
						</button>
					</div>
					<button
						className="p-2 bg-white bg-opacity-20 rounded-full"
						onClick={() => setShowFilter(!showFilter)}
					>
						<Filter className="w-6 h-6" />
					</button>
				</div>
			</header>

			{showFilter && (
				<div className="mb-6 bg-white bg-opacity-20 backdrop-blur-lg p-4 rounded-xl">
					<h2 className="text-xl font-semibold mb-3">Filtros</h2>
					{/* Aquí puedes añadir los filtros que necesites */}
					<p className="text-gray-200">Filtros en desarrollo...</p>
				</div>
			)}

			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
				{filteredEvents.map(event => (
					<EventCard key={event.id} event={event} openDetails={openDetails} />
				))}
			</div>

			<OverPage show={showDetails?.id} setClose={closeDetails}> <EventDetails item={showDetails} /> </OverPage>
		</div>
	);
};

export default EventsPage;