import React, { useState } from 'react';
import { ChevronDown, Users, Calendar, Star, Download, Menu, X } from 'lucide-react';

const LandingPage = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 text-white">
      <header className="bg-white bg-opacity-10 backdrop-blur-lg fixed w-full z-10">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <h1 className="text-3xl font-bold">CouplesConnect</h1>
          <nav className="hidden md:block">
            <ul className="flex space-x-6">
              <li><a href="#caracteristicas" className="hover:text-pink-300 transition duration-300">Características</a></li>
              <li><a href="#como-funciona" className="hover:text-pink-300 transition duration-300">Cómo Funciona</a></li>
              <li><a href="#eventos" className="hover:text-pink-300 transition duration-300">Eventos</a></li>
              <li><a href="#descargar" className="hover:text-pink-300 transition duration-300">Descargar</a></li>
            </ul>
          </nav>
          <button className="md:hidden" onClick={toggleMenu}>
            {isMenuOpen ? <X /> : <Menu />}
          </button>
        </div>
      </header>

      {isMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-20">
          <div className="bg-white text-black p-4 h-full w-64 flex flex-col">
            <button className="self-end mb-4" onClick={toggleMenu}>
              <X />
            </button>
            <nav>
              <ul className="space-y-4">
                <li><a href="#caracteristicas" className="block py-2 hover:bg-gray-100" onClick={toggleMenu}>Características</a></li>
                <li><a href="#como-funciona" className="block py-2 hover:bg-gray-100" onClick={toggleMenu}>Cómo Funciona</a></li>
                <li><a href="#eventos" className="block py-2 hover:bg-gray-100" onClick={toggleMenu}>Eventos</a></li>
                <li><a href="#descargar" className="block py-2 hover:bg-gray-100" onClick={toggleMenu}>Descargar</a></li>
              </ul>
            </nav>
          </div>
        </div>
      )}

      <main className="container mx-auto px-4 pt-24 pb-12">
        <section className="text-center mb-24">
          <h2 className="text-5xl font-bold mb-6">Encuentra tu conexión especial</h2>
          <p className="text-xl mb-12 max-w-2xl mx-auto">CouplesConnect utiliza tecnología avanzada para crear conexiones significativas basadas en compatibilidad real.</p>
          <a href="#descargar" className="bg-white text-purple-600 px-10 py-4 rounded-full text-lg font-semibold hover:bg-opacity-90 transition duration-300 shadow-lg">Descarga Ahora</a>
        </section>

        <section id="caracteristicas" className="mb-24">
          <h2 className="text-4xl font-bold mb-12 text-center">Características Principales</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              { icon: <Users className="w-12 h-12 mb-4" />, title: "Matches Inteligentes", description: "Nuestro algoritmo encuentra las mejores coincidencias basadas en tus intereses y preferencias." },
              { icon: <Calendar className="w-12 h-12 mb-4" />, title: "Eventos Exclusivos", description: "Participa en eventos semanales para conocer a tus matches en persona." },
              { icon: <Star className="w-12 h-12 mb-4" />, title: "Sistema de Votación", description: "Recibe feedback constructivo para mejorar tus relaciones y encuentros." }
            ].map((feature, index) => (
              <div key={index} className="bg-white bg-opacity-20 backdrop-blur-lg p-8 rounded-2xl shadow-lg hover:transform hover:scale-105 transition duration-300">
                {feature.icon}
                <h3 className="text-2xl font-semibold mb-4">{feature.title}</h3>
                <p className="text-gray-200">{feature.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section id="como-funciona" className="mb-24">
          <h2 className="text-4xl font-bold mb-12 text-center">Cómo Funciona</h2>
          <div className="space-y-6 max-w-3xl mx-auto">
            {['Completa tu Perfil', 'Explora y Da Likes', 'Obtén Matches', 'Asiste a Eventos', 'Vota y Mejora'].map((step, index) => (
              <div key={index} className="bg-white bg-opacity-20 backdrop-blur-lg rounded-2xl overflow-hidden shadow-lg">
                <button
                  className="flex justify-between items-center w-full p-6 text-left font-semibold text-xl"
                  onClick={() => toggleSection(`step-${index}`)}
                >
                  <span>{`${index + 1}. ${step}`}</span>
                  <ChevronDown className={`w-6 h-6 transform transition-transform ${activeSection === `step-${index}` ? 'rotate-180' : ''}`} />
                </button>
                {activeSection === `step-${index}` && (
                  <div className="px-6 pb-6">
                    <p className="text-gray-200">
                      {index === 0 && "Rellena tu perfil con información precisa para encontrar los mejores matches."}
                      {index === 1 && "Explora perfiles y da like a las personas que te interesan."}
                      {index === 2 && "Cuando alguien te da like de vuelta, ¡tienes un match! Comienza a chatear."}
                      {index === 3 && "Participa en eventos semanales para conocer a tus matches en persona."}
                      {index === 4 && "Después de los eventos, vota y recibe feedback para mejorar tus experiencias."}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </section>

        <section id="eventos" className="mb-24">
          <h2 className="text-4xl font-bold mb-12 text-center">Próximos Eventos</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {['Noche de Cine al Aire Libre', 'Taller de Cocina Internacional', 'Senderismo y Picnic', 'Concierto Acústico'].map((event, index) => (
              <div key={index} className="bg-white bg-opacity-20 backdrop-blur-lg p-8 rounded-2xl shadow-lg hover:transform hover:scale-105 transition duration-300">
                <h3 className="text-2xl font-semibold mb-4">{event}</h3>
                <p className="text-gray-200 mb-4">Fecha: {new Date(Date.now() + (index + 1) * 7 * 24 * 60 * 60 * 1000).toLocaleDateString()}</p>
                <p className="text-gray-200">Una oportunidad perfecta para conocer a tus matches en un ambiente relajado y divertido.</p>
              </div>
            ))}
          </div>
        </section>

        <section id="descargar" className="text-center">
          <h2 className="text-4xl font-bold mb-8">Descarga CouplesConnect</h2>
          <p className="text-xl mb-12">Disponible ahora en Google Play Store</p>
          <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer" className="inline-flex items-center bg-white text-purple-600 px-10 py-4 rounded-full text-lg font-semibold hover:bg-opacity-90 transition duration-300 shadow-lg">
            <Download className="w-6 h-6 mr-3" />
            Descargar en Google Play
          </a>
        </section>
      </main>

      <footer className="bg-white bg-opacity-10 backdrop-blur-lg py-12">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-1/3 mb-8 md:mb-0">
              <h3 className="text-2xl font-bold mb-4">CouplesConnect</h3>
              <p className="text-gray-300">Conectando corazones, un match a la vez.</p>
            </div>
            <div className="w-full md:w-1/3 mb-8 md:mb-0">
              <h4 className="text-xl font-semibold mb-4">Enlaces Rápidos</h4>
              <ul className="space-y-2">
                <li><a href="#caracteristicas" className="hover:text-pink-300 transition duration-300">Características</a></li>
                <li><a href="#como-funciona" className="hover:text-pink-300 transition duration-300">Cómo Funciona</a></li>
                <li><a href="#eventos" className="hover:text-pink-300 transition duration-300">Eventos</a></li>
                <li><a href="#descargar" className="hover:text-pink-300 transition duration-300">Descargar</a></li>
              </ul>
            </div>
            <div className="w-full md:w-1/3">
              <h4 className="text-xl font-semibold mb-4">Contáctanos</h4>
              <p className="text-gray-300">Email: info@couplesconnect.com</p>
              <p className="text-gray-300">Teléfono: +34 123 456 789</p>
            </div>
          </div>
          <div className="mt-12 pt-8 border-t border-white border-opacity-20 text-center">
            <p className="text-gray-300">&copy; 2024 CouplesConnect. Todos los derechos reservados.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;