import React, { useState } from 'react';
import { Shield, Flag } from 'lucide-react';
import Header from '../../Components/Header';
import { usePost } from '../../Helper/useFetch';
import { useTableContexts } from '../../Context/TableContext';

const VotacionUsuario = ({ profile = {}, closeVote }) => {
	const [opinion, setOpinion] = useState('');
	const vote_api = usePost('/api/mobile/send_vote');

	const { useData } = useTableContexts();
	const profiles = useData('votes');

	const handleVotar = () => {
		vote_api.call({ cmd: 'vote', profile_id: profile.id, comment: opinion }, (result) => {
			if (!result.error) {
				alert("Voto enviado correctamente");
				closeVote();
			} else {
				alert("Error al enviar el voto");
			}
		});
	};

	const handleBlock = () => {
		vote_api.call({ cmd: 'block', profile_id: profile.id }, (result) => {
			if (!result.error) {
				alert("Usuario bloqueado correctamente");
				closeVote();
			} else {
				alert("Error al bloquear el usuario");
			}
		});
	};

	const handleReport = () => {
		vote_api.call({ cmd: 'report', profile_id: profile.id, comment: opinion }, (result) => {
			if (!result.error) {
				alert("Usuario reportado correctamente");
				closeVote();
			} else {
				alert("Error al reportar el usuario");
			}
		});
	};

	return (
		<div className="container mx-auto p-4 max-w-lg">
			<Header back={true} title={"Vota a " + profile.name} />
			{/* <Header back={closeVote} title={"Vota a " + profile.name} /> */}

			<div className="bg-white shadow-md rounded-lg p-6 mb-6">
				<div className="flex items-center mb-4">
					<img
						src={profile.image}
						alt={profile.name}
						loading="lazy" 
						className="w-16 h-16 rounded-full mr-4 object-cover"
						onError={(e) => {
							e.target.onerror = null;
							e.target.src = 'https://via.placeholder.com/150';
						}}
					/>
					<div>
						<h2 className="text-xl font-semibold">{profile.name}</h2>
						<p className="text-gray-600">{profile.age} años</p>
					</div>
				</div>

				<div className="mb-6">
					<p className="text-sm text-gray-700 mb-2">
						Describe a {profile.name} con tres palabras que encontraste (Positivas o Negativas) separado por comas. Por ejemplo:
					</p>
					<p className="text-sm font-semibold text-blue-600">
						"Muy maja, amable, habla mucho"
					</p>
					<span><b>Nota: </b>Nadie podra ver lo que escribes, se suele savar la media de caracteristicar del usuario para haverle saber sus fortalezas y debilidades</span>
				</div>

				<textarea
					value={opinion}
					onChange={(e) => setOpinion(e.target.value)}
					placeholder="Escribe tu opinión aquí..."
					className="w-full p-2 border rounded-md mb-4"
					rows="3"
				/>

				<button
					onClick={handleVotar}
					className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors"
				>
					Votar
				</button>
			</div>

			<div className="bg-gray-100 rounded-lg p-4 mb-6">
				<p className="text-sm text-gray-700 mb-2">
					Si este usuario te hace sentir incómodo/a, puedes bloquearlo o reportarlo.
				</p>
				<span><b>Nota: </b>Si clickas al boton de denuncia se leera este texto para ver que tipo de problemas comete</span>
			</div>

			<div className="flex justify-between">
				<button
					onClick={handleBlock}
					className="flex items-center px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors"
				>
					<Shield className="w-4 h-4 mr-2" />
					Bloquear
				</button>
				<button
					onClick={handleReport}
					className="flex items-center px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
				>
					<Flag className="w-4 h-4 mr-2" />
					Reportar
				</button>
			</div>
		</div>
	);
};

export default VotacionUsuario;