import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'lucide-react';

const GuideSection = ({ title, children }) => {
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<div className="border-b border-gray-200 py-4">
			<button
				className="flex justify-between items-center w-full text-left font-semibold text-lg"
				onClick={() => setIsOpen(!isOpen)}
			>
				{title}
				{isOpen ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
			</button>
			{isOpen && <div className="mt-2 text-gray-600">{children}</div>}
		</div>
	);
};

const HelpPage = ({onClose, className}) => {
	const navigate = useNavigate();

	const close = () => {
		if (onClose) return onClose();
		navigate(-1);
	}

	return (
		<div className={className + " container mx-auto px-4 py-8 max-w-3xl"}>
			<h1 className="text-3xl font-bold mb-6 text-center">Guía de Uso de la App</h1>

			<div className="space-y-4">
				<GuideSection title="1. Completa tu Perfil">
					<p>Es esencial que rellenes tu perfil con información precisa. La app utiliza estos datos para buscar el mejor "match" basado en tus intereses y características.</p>
				</GuideSection>

				<GuideSection title="2. Likes y Matches">
					<ul className="list-disc pl-5 space-y-2">
						<li><strong>Dar Likes:</strong> Si alguien te interesa, da un like a su perfil.</li>
						<li><strong>Importante:</strong> No des likes a usuarios que no te interesen. Esto puede penalizar tu credibilidad y clasificarte como indeciso, bajándote a la categoría de principiantes.</li>
						<li><strong>Match:</strong> Cuando alguien con gustos similares te da un like, se crea un "match".</li>
					</ul>
				</GuideSection>

				<GuideSection title="3. Gestión de Likes y Matches">
					<ul className="list-disc pl-5 space-y-2">
						<li>La app tiene una lista de tus "matches", "likes" y "liked". Desde allí puedes modificar, cancelar, reportar o bloquear usuarios si lo consideras necesario.</li>
						<li>Además de dar like a un perfil completo, puedes dar like a características específicas de una persona, lo que ayuda al sistema a entender mejor tus preferencias y mejorar los "matches".</li>
					</ul>
				</GuideSection>

				<GuideSection title="4. Eventos y Actividades">
					<ul className="list-disc pl-5 space-y-2">
						<li>La app te mostrará eventos a los que las personas con las que tienes "match" se han apuntado. Puedes inscribirte en esos eventos.</li>
						<li>Cada semana organizamos 1 o 2 eventos a los que puedes asistir para conocer a tus "matches".</li>
						<li>Cada evento tiene un precio, límite de asistentes, fecha y hora, y una descripción detallada. Explora los eventos que te gusten y disfruta junto a otros usuarios.</li>
					</ul>
				</GuideSection>

				<GuideSection title="5. Votaciones después de los Eventos">
					<ul className="list-disc pl-5 space-y-2">
						<li>Después de cada evento, puedes votar aspectos positivos y negativos de las personas que conociste. Esta información es confidencial y no visible para el otro usuario, pero ayuda al sistema a mejorar tus futuros "matches".</li>
						<li>También puedes ver el promedio mensual de los votos sobre tu personalidad (nunca votos individuales). Esto te ayudará a identificar áreas de mejora y recibirás consejos sobre cómo avanzar en tus relaciones.</li>
					</ul>
				</GuideSection>

				<GuideSection title="6. Filtros para Buscar">
					<p>En las páginas de eventos, usuarios, "matches" y votaciones, hay filtros para que encuentres fácilmente lo que buscas.</p>
				</GuideSection>

				<GuideSection title="7. Ayuda en Cada Página">
					<p>Cada página tiene un botón de ayuda (ícono "?") que explica cómo utilizar las funciones disponibles en esa sección.</p>
				</GuideSection>

				<GuideSection title="8. Feedback y Soporte">
					<p>En la página de configuración hay un botón de feedback donde puedes dejar comentarios o pedir ayuda. Justo encima, encontrarás la página de soporte para más asistencia.</p>
				</GuideSection>

				<GuideSection title="9. Normas de Comportamiento">
					<p>Apreciamos la responsabilidad y el respeto hacia los demás usuarios. Aquí estamos para disfrutar y pasarlo bien, ¡te deseamos que encuentres lo que buscas!</p>
				</GuideSection>

				<GuideSection title="10. Denuncias">
					<p>Si experimentas discriminación, abuso u otra conducta inadecuada, puedes denunciar al usuario y tomaremos las medidas necesarias.</p>
				</GuideSection>
			</div>

			<div className="flex justify-center w-full p-3">
				<button className="text-white rounded-lg w-full bg-blue-600 px-3 py-2" onClick={close}>Entiendo</button>
			</div>
		</div>
	);
};

export default HelpPage;