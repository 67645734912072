import { useState, useEffect } from 'react';
import { useAppContexts } from '../AppContext';
import { useTableContexts } from '../Context/TableContext';
import ApiUrl from '../Helper/ApiUrl';

const useFetch = (name, setState, url, base = undefined, options = {}, fetchNow=true) => {
	base = (base === undefined) ? ApiUrl : base;
	const { isLogin } = useAppContexts();
	const { setTables } = useTableContexts();

	const updateData = (data) => {
		setTables(oldTable => {
			const xTables = { ...oldTable };

			Object.keys(data).forEach(name => {

				if (xTables[name]) {
					const table = xTables[name];
					const new_rows = (Array.isArray(data[name])) ? data[name] : [data[name]];

					new_rows.forEach(row => {
						table.update(table.rows, row);
					});
				}
			});

			return xTables;
		});
	}

	const fetchData = () => {
		const mySetState = (loading, error, data = undefined) => setState(prevState => {
			const newState = { ...prevState, [name]: { ...prevState[name], loading, error, refresh: fetchData } };
			if (data !== undefined) newState[name].data = data;
			return newState;
		});

		mySetState(true, null);

		try {
			const controller = new AbortController();
			const signal = controller.signal;

			fetch(base + url, {
				...options,
				signal,
				headers: {
					...options.headers,
					// 'Connection': 'keep-alive',
					// 'Cache-Control': 'no-cache',
					// 'Pragma': 'no-cache',
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${localStorage.getItem('token')}`,
				},
			}).then(r => {
				if (r.status === 401) {
					localStorage.removeItem('token');
					localStorage.removeItem('isLogin');
					window.location.reload();
					return null;
				} else if (!r.ok) {
					throw new Error(`HTTP error! status: ${r.status}`);
				}
				return r.json();
			}).then(result => {
				const data = result.result ?? {};
				mySetState(false, null, data);
				if (data) updateData(data);

			}).catch(e => {
				console.error(`Attempt failed for ${name}: ${e.message}`);
				mySetState(false, e.message);
			});
		} catch (e) {
			console.error(`Attempt failed for ${name}: ${e.message}`);
			mySetState(false, e.message);
		}
	};

	useEffect(() => {
		if (!fetchNow) return;
		if (isLogin) fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLogin, url]);

	return fetchData;
};

const usePost = (url, base = undefined, options = {}) => {
	const { isLogin } = useAppContexts();
	const { setTables } = useTableContexts();
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	base = (base === undefined) ? ApiUrl : base;

	const updateData = (data) => {
		setTables(oldTable => {
			const xTables = { ...oldTable };

			Object.keys(data).forEach(name => {

				if (xTables[name]) {
					const table = xTables[name];
					const new_rows = (Array.isArray(data[name])) ? data[name] : [data[name]];

					new_rows.forEach(row => {
						table.update(table.rows, row);
					});
				}
			});

			return xTables;
		});
	}

	const call = (data, callback, mustLogin = true) => {
		if (mustLogin && !isLogin) return;

		try {
			setLoading(true);
			fetch(base + url, {
				...options,
				method: 'POST',
				body: JSON.stringify(data),
				headers: {
					...options.headers,
					'Pragma': 'no-cache',
					'Cache-Control': 'no-cache',
					"Accept": "application/json",
					'content-type': 'application/json',
					'Authorization': `Bearer ${localStorage.getItem('token')}`,
				},
			}).then(r => {
				if (r.status === 401) {
					localStorage.removeItem('token');
					localStorage.removeItem('isLogin');
					window.location.reload();
					return null;
				} else if (!r.ok) {
					throw new Error(`HTTP error! status: ${r.status}`);
				}
				return r.json();
			}).then(result => {
				setData(result);
				setLoading(false);
				callback && callback(result);

				const data = result.result ?? {};
				if (data) updateData(data);

			}).catch(e => {
				console.error(`Attempt failed: ${e.message}`);
				setLoading(false);
				setError(e.message);
				callback && callback({ error: e.message });
			});
		} catch (e) {
			setLoading(false);
			setError(e.message);
			callback && callback({ error: e.message });
		}
	}

	return { data, error, loading, call };
};

export default useFetch;
export { useFetch, usePost };