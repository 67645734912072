import React, { useState, useEffect } from 'react';
import { useAppContexts } from '../AppContext';
import { ChevronRight, Plus, /* Globe */ } from 'lucide-react';
import Help from './Help';
import { Link } from 'react-router-dom';

// Componente de checkbox estilo iPhone
const ToggleSwitch = ({ isChecked, onChange }) => (
	<div className={`w-14 h-8 flex items-center rounded-full p-1 cursor-pointer ${isChecked ? 'bg-green-400' : 'bg-gray-300'}`} onClick={onChange} >
		<div className={`bg-white w-6 h-6 rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${isChecked ? 'translate-x-6' : ''}`} />
	</div>
);

// Componente de bandera (simulado con las iniciales del país)
const FlagIcon = ({ country }) => (
	<div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center font-bold text-sm">
		{country.substring(0, 2).toUpperCase()}
	</div>
);

const NotifyStyle = {
	position: "absolute",
	height: "calc(100% - 60px)",
	background: "#fff",
	inset: 0,
	top: "60px",
	overflow: "hidden",
	startingStyle: {
		transform: "ScaleY(0)",
	},
}

const SettingsPage = ({ show, helpBtn }) => {
	const { trans, lang, changeLang, setLogout, StyleId, changeStyleId, sendNotify, changeSendNotify } = useAppContexts();
	const [showHelp, setShowHelp] = useState(false);

	const languages = { es: 'Español', en: 'Inglés', fr: 'Francés', it: 'Italiano', ar: 'Árabe' }

	const handleHelpClick = () => {
		setShowHelp(true);
	};

	const handleLogout = () => {
		setLogout();
	};

	useEffect(() => {
		setShowHelp(false);
	}, [show]);

	if (showHelp) {
		return (
			<div style={NotifyStyle} className={"max-w-2xl mx-auto p-8 scale-y z-30" + (show ? " show" : "")}>
				<Help className="overflow-y-auto h-full" onClose={() => setShowHelp(false)} />;
			</div>
		);
	}

	const styles = {
		1: "Style 1",
		2: "Style 2",
	}
	return (
		<div style={NotifyStyle} className={"max-w-2xl mx-auto p-8 scale-y z-30" + (show ? " show" : "")}>
			<h1 className="text-3xl font-bold mb-6">{trans("settings.title", "Configuraciones")}</h1>

			{/* Notificaciones */}
			<div className="flex justify-between items-center mb-6">
				<span className="text-lg">{trans("settings.notifications", "Notificaciones")}</span>
				<ToggleSwitch isChecked={sendNotify} onChange={() => changeSendNotify(!sendNotify)} />
			</div>

			{/* Styles  */}
			<div className="mb-6">
				<div className="flex justify-between items-center mb-2">
					<span className="text-lg">{trans("settings.style", "Style")}</span>
				</div>
				<div className="flex space-x-2 mt-2">
					{Object.entries(styles).map(([id, style]) => (
						<button key={style} onClick={() => { changeStyleId(parseInt(id)); }} className={`p-2 rounded ${StyleId === parseInt(id) ? 'bg-blue-500 text-white' : 'bg-gray-200'}`} >
							{style}
						</button>
					))}
				</div>
			</div>

			{/* Idioma */}
			<div className="mb-6">
				<div className="flex justify-between items-center mb-2">
					<span className="text-lg">{trans("settings.language", "Idioma")}</span>
					<div className="flex items-center">
						{/* <FlagIcon country={lang} /> */}
						<span className="ml-2">{languages[lang]}</span>
						<ChevronRight className="ml-2" />
					</div>
				</div>
				<div className="flex space-x-2 mt-2">
					{Object.entries(languages).map(([id, ln]) => (
						<button key={ln} onClick={() => {changeLang(id);}} className={`p-2 rounded ${id === lang ? 'bg-blue-500 text-white' : 'bg-gray-200'}`} >
							<FlagIcon country={ln} />
						</button>
					))}
					<button className="p-2 rounded bg-gray-200">
						<Plus />
					</button>
				</div>
			</div>

			{/* Enlaces */}
			{[
				trans("settings.privacy", "Privacidad y Política"),
				trans("settings.terms", "Términos y Condiciones"),
				trans("settings.support", "Soporte")
			].map((item) => (
				<div key={item} className="flex justify-between items-center py-4 border-b">
					<span className="text-lg">{item}</span>
					<ChevronRight />
				</div>
			))}

			<button onClick={handleHelpClick} className="mt-6 w-full bg-blue-500 text-white py-3 rounded-lg font-semibold hover:bg-blue-600 transition-colors" >
				Ayuda
			</button>
			<Link to="/" onClick={handleLogout}>
				<div className="text-center mt-6 w-full bg-blue-500 text-white py-3 rounded-lg font-semibold hover:bg-blue-600 transition-colors" >
					Logout
				</div>
			</Link>
		</div>
	);
};

export default SettingsPage;
export { ToggleSwitch, FlagIcon };