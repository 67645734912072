import React from 'react';
import ReactDOM from 'react-dom/client';
// import {HashRouter as RouterProvider, createBrowserRouter} from 'react-dom/client';
import { HashRouter as Router, RouterProvider, createBrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppProvider from './AppContext';
import TableProvider from './Context/TableContext';

// const router = createBrowserRouter(createRoutesFromElements(<Route path='*' element={<App />} />));
const router = createBrowserRouter([{ path: "*", element: <App />, },]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <React.StrictMode>
	<TableProvider>
		<AppProvider>
			{/* <App /> */}
			<RouterProvider router={router} />
		</AppProvider>
	</TableProvider>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
