import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, useBlocker } from 'react-router-dom';
import { useAppContexts } from '../AppContext';

/**
 * 
 * @param show use to show or hide the OverPage component
 * @param children children components
 * @returns 
 */
const OverPage = ({ show, children, setClose }) => {
	const navigate = useNavigate();
	const { overPageCount, setOverPageCount } = useAppContexts();
	const [PageHash, setPageHash] = useState(false);
	const [showOverPage, setShowOverPage] = useState(show);

	useEffect(() => {
		setShowOverPage(show);
		if (!show) return;

		const x = overPageCount + 1;
		setPageHash(x);
		setOverPageCount(x);
		navigate("#", { state: { overPage: x }, replace: false });
	}, [show]);

	if (PageHash > overPageCount) {
		if (setClose) setClose(false); else setShowOverPage(false);
		setPageHash(false);
	}

	if (!showOverPage) return null;

	return (
		<div className="overpage overflow-y-auto fixed bg-white w-full left-0 top-14 bottom-14 z-30">
			{children}
		</div>
	)
}

export default OverPage;