import React from 'react';
import { ChevronDown, Users, Calendar, Star, Download } from 'lucide-react';

const LandingPage = () => {
  const [activeSection, setActiveSection] = React.useState(null);

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-100 to-pink-100">
      <header className="bg-white shadow-md">
        <div className="container mx-auto px-4 py-6 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-purple-600">CouplesConnect</h1>
          <nav>
            <ul className="flex space-x-6">
              <li><a href="#caracteristicas" className="text-gray-600 hover:text-purple-600">Características</a></li>
              <li><a href="#como-funciona" className="text-gray-600 hover:text-purple-600">Cómo Funciona</a></li>
              <li><a href="#eventos" className="text-gray-600 hover:text-purple-600">Eventos</a></li>
              <li><a href="#descargar" className="text-gray-600 hover:text-purple-600">Descargar</a></li>
            </ul>
          </nav>
        </div>
      </header>

      <main className="container mx-auto px-4 py-12">
        <section className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4">Encuentra tu conexión especial</h2>
          <p className="text-xl mb-8">CouplesConnect te ayuda a encontrar el amor basado en intereses compartidos y compatibilidad real.</p>
          <a href="#descargar" className="bg-purple-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-purple-700 transition duration-300">Descarga Ahora</a>
        </section>

        <section id="caracteristicas" className="mb-16">
          <h2 className="text-3xl font-bold mb-8 text-center">Características Principales</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <Users className="w-12 h-12 text-purple-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Matches Inteligentes</h3>
              <p>Nuestro algoritmo encuentra las mejores coincidencias basadas en tus intereses y preferencias.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <Calendar className="w-12 h-12 text-purple-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Eventos Exclusivos</h3>
              <p>Participa en eventos semanales para conocer a tus matches en persona.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <Star className="w-12 h-12 text-purple-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Sistema de Votación</h3>
              <p>Recibe feedback constructivo para mejorar tus relaciones y encuentros.</p>
            </div>
          </div>
        </section>

        <section id="como-funciona" className="mb-16">
          <h2 className="text-3xl font-bold mb-8 text-center">Cómo Funciona</h2>
          <div className="space-y-4">
            {['Completa tu Perfil', 'Explora y Da Likes', 'Obtén Matches', 'Asiste a Eventos', 'Vota y Mejora'].map((step, index) => (
              <div key={index} className="bg-white p-4 rounded-lg shadow-md">
                <button
                  className="flex justify-between items-center w-full text-left font-semibold"
                  onClick={() => toggleSection(`step-${index}`)}
                >
                  <span>{`${index + 1}. ${step}`}</span>
                  <ChevronDown className={`w-5 h-5 transform transition-transform ${activeSection === `step-${index}` ? 'rotate-180' : ''}`} />
                </button>
                {activeSection === `step-${index}` && (
                  <p className="mt-2 text-gray-600">
                    {index === 0 && "Rellena tu perfil con información precisa para encontrar los mejores matches."}
                    {index === 1 && "Explora perfiles y da like a las personas que te interesan."}
                    {index === 2 && "Cuando alguien te da like de vuelta, ¡tienes un match! Comienza a chatear."}
                    {index === 3 && "Participa en eventos semanales para conocer a tus matches en persona."}
                    {index === 4 && "Después de los eventos, vota y recibe feedback para mejorar tus experiencias."}
                  </p>
                )}
              </div>
            ))}
          </div>
        </section>

        <section id="eventos" className="mb-16">
          <h2 className="text-3xl font-bold mb-8 text-center">Próximos Eventos</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {['Noche de Cine al Aire Libre', 'Taller de Cocina Internacional', 'Senderismo y Picnic', 'Concierto Acústico'].map((event, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2">{event}</h3>
                <p className="text-gray-600 mb-4">Fecha: {new Date(Date.now() + (index + 1) * 7 * 24 * 60 * 60 * 1000).toLocaleDateString()}</p>
                <p>Una oportunidad perfecta para conocer a tus matches en un ambiente relajado y divertido.</p>
              </div>
            ))}
          </div>
        </section>

        <section id="descargar" className="text-center">
          <h2 className="text-3xl font-bold mb-8">Descarga CouplesConnect</h2>
          <p className="text-xl mb-8">Disponible ahora en Google Play Store</p>
          <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer" className="inline-flex items-center bg-black text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-800 transition duration-300">
            <Download className="w-6 h-6 mr-2" />
            Descargar en Google Play
          </a>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-2xl font-bold mb-4">CouplesConnect</h3>
              <p>Conectando corazones, un match a la vez.</p>
            </div>
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h4 className="text-xl font-semibold mb-4">Enlaces Rápidos</h4>
              <ul className="space-y-2">
                <li><a href="#caracteristicas" className="hover:text-purple-300">Características</a></li>
                <li><a href="#como-funciona" className="hover:text-purple-300">Cómo Funciona</a></li>
                <li><a href="#eventos" className="hover:text-purple-300">Eventos</a></li>
                <li><a href="#descargar" className="hover:text-purple-300">Descargar</a></li>
              </ul>
            </div>
            <div className="w-full md:w-1/3">
              <h4 className="text-xl font-semibold mb-4">Contáctanos</h4>
              <p>Email: info@couplesconnect.com</p>
              <p>Teléfono: +34 123 456 789</p>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center">
            <p>&copy; 2024 CouplesConnect. Todos los derechos reservados.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;