import React, { useState, /* useEffect */ } from 'react';
import { Profile, TotalProfile } from './ProfileAPI';
import ProfileDetails from './Pages/ProfileDetails';
// import { useAppContexts } from './AppContext';

import {
	Box,
	// Card,
	// CardContent,
	// CardMedia,
	// Typography,
	// Button,
	// Chip,
	// List,
	// ListItem,
	// ListItemIcon,
	// ListItemText,
	IconButton,
	styled
} from '@mui/material';
import {
	// Person,
	// LocationOn,
	// Work,
	// School,
	// HeightOutlined,
	// Scale,
	Favorite,
	Close,
	// Pets,
	// LocalBar,
	// SmokingRooms,
	// WcOutlined,
	// Star,
	// Psychology
} from '@mui/icons-material';

// const profilesData = [
// 	{
// 		id: 1,
// 		name: 'Ana',
// 		age: 28,
// 		gender: 'Mujer',
// 		location: 'Madrid, España',
// 		distance: '5 km',
// 		profession: 'Fotógrafa freelance',
// 		education: 'Grado en Bellas Artes, Universidad Complutense',
// 		height: '165 cm',
// 		weight: '58 kg',
// 		socialStatus: 'Soltera',
// 		children: 'No tiene',
// 		alcohol: 'Socialmente',
// 		smoker: 'No',
// 		orientation: 'Heterosexual',
// 		zodiacSign: 'Leo',
// 		pets: 'Un gato',
// 		religion: 'Agnóstica',
// 		personality: 'ENFP',
// 		lookingFor: 'Una conexión auténtica con alguien que comparta mi pasión por el arte y la aventura.',
// 		bio: 'Apasionada por capturar momentos únicos a través de mi lente. Viajera incansable en busca de nuevas perspectivas y experiencias. Cuando no estoy detrás de la cámara, me encontrarás practicando yoga o explorando cafeterías locales.',
// 		interests: ['Fotografía', 'Viajes', 'Yoga', 'Café', 'Arte moderno', 'Naturaleza', 'Lectura'],
// 		questions: [
// 			{ question: '¿Cuál es tu lugar favorito para viajar?', answer: 'Islandia, por sus paisajes surrealistas y la luz perfecta para la fotografía.' },
// 			{ question: '¿Qué libro te ha influenciado más?', answer: '"El aleph" de Jorge Luis Borges, me abrió la mente a nuevas formas de ver el mundo.' },
// 			{ question: '¿Cuál es tu comida favorita?', answer: 'La cocina mediterránea, especialmente la griega. Amo el tzatziki y la musaca.' }
// 		],
// 		imageUrl: "/server/img (161).jpg"
// 	},
// ];

// const StyledCard = styled(Card)(({ theme }) => ({
// 	maxWidth: 500,
// 	margin: 'auto',
// 	marginTop: theme.spacing(4),
// 	marginBottom: theme.spacing(4),
// }));

const ActionsContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-around',
	padding: theme.spacing(2),
}));

const bottonFixed = {
	position: "fixed",
	bottom: "65px",
	width: "calc(100% - 65px)",
	display: "flex",
	justifyContent: "space-between",
}

// const instructionStyle = {
// 	position: "fixed",
// 	width: "calc(100vw - 20px)",
// 	left: "10px",
// 	top: "66px",
// 	borderRadius: "0.5rem",
// }

const ProfilePage = () => {
	// const { trans, setTutorial } = useAppContexts();
	// useEffect(() => { setTutorial(tutorialSteps); return ()=> setTutorial(null) }, [setTutorial]);
	// {trans("settings.helps", "Ayuda")}
	
	const [currentProfileIndex, setCurrentProfileIndex] = useState(2);

	const handleSwipe = (direction) => {
		if (direction === 'right') {
			console.log('Like!');
		} else {
			console.log('Nope!');
		}
		setCurrentProfileIndex((prevIndex) => (prevIndex + 1) % TotalProfile);
	};

	const currentProfile = Profile(currentProfileIndex);

	return (
		<Box sx={{ maxWidth: 600, margin: 'auto', padding: 2, zIndex: 1 }}>
			<ProfileDetails ProfileData={currentProfile}/>
			<ActionsContainer style={bottonFixed}>
				<IconButton onClick={() => handleSwipe('left')} sx={{ backgroundColor: 'error.light', '&:hover': { backgroundColor: 'error.main' } }} >
					<Close fontSize="large" />
				</IconButton>
				<IconButton onClick={() => handleSwipe('right')} sx={{ backgroundColor: 'success.light', '&:hover': { backgroundColor: 'success.main' } }} >
					<Favorite fontSize="large" />
				</IconButton>
			</ActionsContainer>
			{/* <div style={instructionStyle} className="bg-blue-200 w-full py-4 px-8 rounded-t-lg">
				<h1 className="text-2xl font-bold text-center">Liked Some profiles to mactch in hobbies Events (1/20) <span className='text-blue-500 text-size-1'>read more</span></h1>
			</div> */}
		</Box>
	);
};

export default ProfilePage;


// const ProfileInfoItem = ({ Icon, value, prefix = '' }) => {
// 	return (
// 		<ListItem>
// 			<ListItemIcon>
// 				<Icon />
// 			</ListItemIcon>
// 			<ListItemText primary={prefix ? `${prefix}: ${value}` : value} />
// 		</ListItem>
// 	);
// };
