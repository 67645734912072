import React, { useEffect } from 'react';
// import { Profile, /* TotalProfile */ } from '../ProfileAPI';
import { useParams  } from 'react-router-dom';
import { useAppContexts } from '../AppContext';

import Header from '../Components/Header';
import ProfileOnly from './ProfileOnly';
import useFetch from '../Helper/useFetch';


import {
	// Box,
	Card,
	// CardContent,
	// CardMedia,
	// Typography,
	// Button,
	// Chip,
	// List,
	// ListItem,
	// ListItemIcon,
	// ListItemText,
	// IconButton,
	styled
} from '@mui/material';
// import {
// 	// Person,
// 	// LocationOn,
// 	// Work,
// 	// School,
// 	// HeightOutlined,
// 	// Scale,
// 	// Favorite,
// 	// Close,
// 	// Pets,
// 	// LocalBar,
// 	// SmokingRooms,
// 	// WcOutlined,
// 	// Star,
// 	// Psychology
// } from '@mui/icons-material';

// const profilesData = [
// 	{
// 		id: 1,
// 		name: 'Ana',
// 		age: 28,
// 		gender: 'Mujer',
// 		location: 'Madrid, España',
// 		distance: '5 km',
// 		profession: 'Fotógrafa freelance',
// 		education: 'Grado en Bellas Artes, Universidad Complutense',
// 		height: '165 cm',
// 		weight: '58 kg',
// 		socialStatus: 'Soltera',
// 		children: 'No tiene',
// 		alcohol: 'Socialmente',
// 		smoker: 'No',
// 		orientation: 'Heterosexual',
// 		zodiacSign: 'Leo',
// 		pets: 'Un gato',
// 		religion: 'Agnóstica',
// 		personality: 'ENFP',
// 		lookingFor: 'Una conexión auténtica con alguien que comparta mi pasión por el arte y la aventura.',
// 		bio: 'Apasionada por capturar momentos únicos a través de mi lente. Viajera incansable en busca de nuevas perspectivas y experiencias. Cuando no estoy detrás de la cámara, me encontrarás practicando yoga o explorando cafeterías locales.',
// 		interests: ['Fotografía', 'Viajes', 'Yoga', 'Café', 'Arte moderno', 'Naturaleza', 'Lectura'],
// 		questions: [
// 			{ question: '¿Cuál es tu lugar favorito para viajar?', answer: 'Islandia, por sus paisajes surrealistas y la luz perfecta para la fotografía.' },
// 			{ question: '¿Qué libro te ha influenciado más?', answer: '"El aleph" de Jorge Luis Borges, me abrió la mente a nuevas formas de ver el mundo.' },
// 			{ question: '¿Cuál es tu comida favorita?', answer: 'La cocina mediterránea, especialmente la griega. Amo el tzatziki y la musaca.' }
// 		],
// 		imageUrl: "/server/img (161).jpg"
// 	},
// ];

const tutorialSteps = [
	{
		title: "Bienvenido a la App",
		description: "Esta es la página principal. Aquí puedes ver un resumen de tus actividades.",
		position: { x: 500, y: 500 },
		size: 100
	},
	{
		title: "Menú de Navegación",
		description: "Utiliza este menú para moverte entre las diferentes secciones de la app.",
		position: { x: 100, y: 200 },
		size: 75
	},
	{
		title: "Perfil de Usuario",
		description: "Haz clic aquí para ver y editar tu perfil.",
		position: { x: 900, y: 100 },
		size: 50
	}
];

const StyledCard = styled(Card)(({ theme }) => ({
	maxWidth: 500,
	margin: 'auto',
	marginTop: theme.spacing(4),
	marginBottom: theme.spacing(4),
}));

// const ActionsContainer = styled(Box)(({ theme }) => ({
// 	display: 'flex',
// 	justifyContent: 'space-around',
// 	padding: theme.spacing(2),
// }));

// const bottonFixed = {
// 	position: "fixed",
// 	bottom: "65px",
// 	width: "calc(100% - 65px)",
// 	display: "flex",
// 	justifyContent: "space-between",
// }

// const instructionStyle = {
// 	position: "fixed",
// 	width: "calc(100vw - 20px)",
// 	left: "10px",
// 	top: "66px",
// 	borderRadius: "0.5rem",
// }


const ProfileDetailsId = ({item={}}) => {
	// const [profile, setProfile] = useState({});
	const args = useParams();

	const id = item?.id || parseInt(args.id ?? 0) || null;
	const [fetchData, setFetchData] = React.useState({profile: { loading: false, error: null, data: item }});
	const profile = fetchData.profile.data;

	useFetch('profile', setFetchData, '/api/mobile/profile/' + id);

	const { /* trans, */ setTutorial } = useAppContexts();
	useEffect(() => { setTutorial(tutorialSteps); return ()=> setTutorial(null) }, [setTutorial]);
	// {trans("settings.helps", "Ayuda")}
	
	// const imageUrl = profile.imageUrl ? profile.imageUrl[0] : '';
	// const interests = profile.interests || [];
	// const questions = profile.questions || [];

	return (
		<div className="container mx-auto p-4" >
			<StyledCard>
				<Header back={true} title={profile.name} />
				
				<ProfileOnly profile={profile} />
				{/* <CardMedia component="img" height="400" image={imageUrl} alt={profile.name} />
				<CardContent>
					<Typography variant="h4" component="div" gutterBottom> {profile.name}, {profile.age} [id:{id}] </Typography>
					<List>
						<ProfileInfoItem Icon={Work} value={profile.profession} />
						<ProfileInfoItem Icon={LocationOn} value={`${profile.location} • ${profile.distance}`} />
						<ProfileInfoItem Icon={School} value={profile.education} />
						<ProfileInfoItem Icon={HeightOutlined} value={profile.height} />
						<ProfileInfoItem Icon={Scale} value={profile.weight} />
						<ProfileInfoItem Icon={Pets} value={profile.pets} />
						<ProfileInfoItem Icon={LocalBar} value={profile.alcohol} prefix="Alcohol" />
						<ProfileInfoItem Icon={SmokingRooms} value={profile.smoker} prefix="Fumador" />
						<ProfileInfoItem Icon={WcOutlined} value={profile.orientation} />
						<ProfileInfoItem Icon={Star} value={profile.zodiacSign} />
						<ProfileInfoItem Icon={Psychology} value={profile.personality} prefix="Personalidad" />
					</List>

					<Typography variant="body1" color="text.secondary" paragraph> {profile.bio} </Typography>
					<Typography variant="h6" gutterBottom> Intereses </Typography>
					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}> {interests.map((interest, index) => (<Chip key={index} label={interest} />))} </Box>
					<Typography variant="h6" gutterBottom> Preguntas y Respuestas </Typography>
					{questions.map((q, index) => (<Box key={index} sx={{ mb: 2 }}> <Typography variant="subtitle1">{q.question}</Typography> <Typography variant="body2" color="text.secondary">{q.answer}</Typography> </Box>))}
				</CardContent> */}
			</StyledCard>
		</div>
	);
		
};

export default ProfileDetailsId;


// const ProfileInfoItem = ({ Icon, value, prefix = '' }) => {
// 	return (
// 		<ListItem>
// 			<ListItemIcon>
// 				<Icon />
// 			</ListItemIcon>
// 			<ListItemText primary={prefix ? `${prefix}: ${value}` : value} />
// 			<div className="flex justify-center mt-4">
// 				<button className="mr-4 p-2 rounded-full"> 🌹 </button>
// 			</div>	
// 		</ListItem>
// 	);
// };
