import React, { createContext, useState, useContext, useEffect } from 'react';
import LangEn from './Langs/en.json';
import LangEs from './Langs/es.json';

// Creamos el contexto
const AppContexts = createContext({});

export const useAppContexts = () => useContext(AppContexts);

export const AppProvider = ({ children }) => {
	const [lang, setLang] = useState(localStorage.getItem("app_lang") || 'es'); // Idioma por defecto: español
	const [sendNotify, setSendNotify] = useState(localStorage.getItem("app_notify")==='true' || false);
	const [StyleId, setStyleId] = useState(parseInt(localStorage.getItem("app_style")) || 1);
	const [isLogin, setIsLogin] = useState(localStorage.getItem('isLogin') === 'true');

	const [help, setHelp] = useState(false); // Estado de ayuda, por defecto falso
	const [notify, setNotify] = useState(false); // Estado de ayuda, por defecto falso
	const [settings, setSettings] = useState(false); // Estado de ayuda, por defecto falso
	const [lang_arr, setLangArr] = useState(LangEs);
	const [tutorial, setTutorial] = useState(null); // Estado de ayuda, por defecto falso
	const [overPageCount, setOverPageCount] = useState(0);

	useEffect(() => {
		switch(lang) {
			case 'es': setLangArr(LangEs); break;
			case 'en': setLangArr(LangEn); break;
			default: setLangArr(LangEs);
		}
	}, [lang]);

	const changeLang = (newLang) => {
		setLang(newLang);
		localStorage.setItem("app_lang", newLang);
	};

	const changeSendNotify = (newNotify) => {
		setSendNotify(newNotify);
		localStorage.setItem("app_notify", newNotify);
	};

	const changeStyleId = (newStyleId) => {
		setStyleId(newStyleId);
		localStorage.setItem("app_style", newStyleId);
	};


	const toggleHelp = (json) => {
		setHelp(prevHelp => !prevHelp);
		setNotify(false);
		setSettings(false);
	};

	const toggleNotify = () => {
		setNotify(prevHelp => !prevHelp);
		setHelp(false);
		setSettings(false);
	};

	const toggleSetting = () => {
		setSettings(prevHelp => !prevHelp);
		setHelp(false);
		setNotify(false);
	};

	const getValueByPath = (obj, path) => {
		const parts = path.split('.');
		return parts.reduce((acc, part) => acc && acc[part] !== undefined ? acc[part] : undefined, obj);
	}

	const trans = (key, other="") => {
		let worn_lang = getValueByPath(lang_arr, key);
		if (worn_lang) return worn_lang;

		(lang !== "es") && (worn_lang = getValueByPath(LangEs, key));
		if (worn_lang) return worn_lang;

		(lang !== "en") && (worn_lang = getValueByPath(LangEn, key));
		if (worn_lang) return worn_lang;

		return "*" + other;
	}

	const setLogin = (token) => {
		localStorage.setItem('isLogin', true);
		localStorage.setItem('token', token);
		setIsLogin(true);
	}

	const setLogout = () => {
		localStorage.setItem('isLogin', false);
		setIsLogin(false);
	}

	const contextValue = {
		lang, changeLang,
		help, toggleHelp,
		notify, toggleNotify,
		StyleId, changeStyleId,
		settings, toggleSetting,
		tutorial, setTutorial,
		isLogin, setLogin, setLogout,
		sendNotify, changeSendNotify,
		overPageCount, setOverPageCount,
		trans
	};

	return (
		<AppContexts.Provider value={contextValue}>
			{children}
		</AppContexts.Provider>
	);
};

// // Ejemplo de uso en un componente
// const AppContext = () => {
// 	const { lang, changeLang, help, toggleHelp } = useAppContexts();

// 	return (
// 		<div>
// 			<p>Idioma actual: {lang}</p>
// 			<button onClick={() => changeLang('en')}>Cambiar a inglés</button>
// 			<p>Ayuda: {help ? 'Activada' : 'Desactivada'}</p>
// 			<button onClick={toggleHelp}>Toggle Ayuda</button>
// 		</div>
// 	);
// };

export default AppProvider;